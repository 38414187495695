import React, {useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { Link } from 'react-router-dom'

const Employee_salary_template_list = () => {

    const alert = useAlert();
    let history = useHistory();

    useEffect(() => {getTemplateList();},[]);
    const [List,setTemplateList]=useState([]);

    const getTemplateList = ()=> {
      Apiconnect.getData(`employee_salary_template/getAllTemplate/${Apiconnect.get_company_id()}`).then((response)=>{
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setTemplateList(_xtract.reverse());
      });
    }

    console.log(List);


    const deleteItem = (id) => {
      Apiconnect.deleteData(`employee_salary_template/delete/${id}`).then((response)=>{
        getTemplateList();
        alert.success(response.data.message); 
      });
 };


    return(
<>
<div id="content-page" className="content-page">
         <div className="container-fluid">
               <div className="row">
                  <div className="col-sm-12 col-lg-12 col-md-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List Of Template</h4>
                           </div>
                           <Link style={{float:'right'}} className="btn btn-success"  to="/salary_template" >Add New</Link>
                        </div>
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                                 <thead className="">
                                    <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Name</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody className="">
                                    {
                                        List.map((val,key)=>{
                                            return(
                                                <tr key={key}>
                                                    <td style={{width:'100px'}}>{key+1}</td>
                                                    <td>{val.name}</td>
                                                    <td style={{width:'200px'}}>
                                                    <button onClick={() => {deleteItem(val.id);}} className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                              <Link   to={`/salary_template_edit/${val.id}`} 
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                 </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
 
         </div>
         </div>
</>
    )
}

export default Employee_salary_template_list