
import React, { useState, useEffect, Component } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import '../pages/pages.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const Earning_head = () => {

   const alert = useAlert();

   let history = useHistory();

   const [info, setInfo] = useState({
      name: "",
      abbreviation: "",
      accounting_code: "",
      typ: "fixed",
      tds_head: "",
      cut_if_lwp: "0",
      formula_id: "",
      id: null,
      company_id:Apiconnect.get_company_id()
   });
   const { name, abbreviation, accounting_code, typ, tds_head, cut_if_lwp, formula_id, id ,company_id} = info;
   const onInputChange = e => {
      setInfo({ ...info, [e.target.name]: e.target.value });
   };

   const onSubmit = async e => {
      e.preventDefault();
      if (id > 0) {
         console.log('in edit function' + id);
         let info_enc = Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
         Apiconnect.postData(`earning_head/update/`, inf_a).then((response) => {
            history.push("/Earning_head");
            setInfo({ name: "", abbreviation: "", accounting_code: "", typ: "", tds_head: "", cut_if_lwp: "", formula_id: "", id: '',company_id:Apiconnect.get_company_id() });
            getDList();
            alert.success('Earning Head updated successfully.');
         });
      } else {
         console.log('in add function');
         let info_enc = Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
         await Apiconnect.postData("earning_head/create", inf_a);
         history.push("/Earning_head");
         setInfo({ name: "", abbreviation: "", accounting_code: "", typ: "", tds_head: "", cut_if_lwp: "", formula_id: "", id: '',company_id:Apiconnect.get_company_id() });
         getDList();
         alert.success('Earning Head added successfully.');


      }

   };

   const ondlt = (id) => {
      confirmAlert({
        title: 'Are you sure to Delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteItem(id), 
          },
          {
            label: 'No',
          }
        ]
      });
    };

   const deleteItem = (id) => {
      Apiconnect.deleteData(`earning_head/delete/${id}`).then((response) => {
         history.push("/Earning_head");
         alert.success('Earning Head Delete successfully.');
         getDList();
      });
   };

   const getItem = (id) => {
      Apiconnect.getData(`earning_head/get/${id}`).then((response) => {
         console.log(response.data.data[0]);
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setInfo({
            name: _xtract[0].name, abbreviation: _xtract[0].abbreviation, typ: _xtract[0].typ, tds_head: _xtract[0].tds_head,
            cut_if_lwp: _xtract[0].cut_if_lwp, formula_id: _xtract[0].formula_id, id: _xtract[0].id
         });

      });
   };




   function refreshPage() {
      window.location.reload();
   }


   useEffect(() => { getDList(); }, []);
   const [earningList, setddList] = useState([]);
   const getDList = () => {
      Apiconnect.getData(`earning_head/getall/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setddList(_xtract.reverse());
      });
   };

   const [viewList,setViewList] = useState({
      name1: "",
      abbreviation1: "",
      accounting_code1: "",
      typ1: "",
      tds_head1: "",
      cut_if_lwp1: "",
   });

   const {  name1, abbreviation1, accounting_code1, typ1, tds_head1, cut_if_lwp1} = viewList;

   const viewItem = (id)=>{
      setModalIsOpen(true);

      Apiconnect.getData(`earning_head/get/${id}`).then((response) => {
         console.log(response.data.data[0]);
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setViewList({
            name1: _xtract[0].name, abbreviation1: _xtract[0].abbreviation, typ1: _xtract[0].typ, tds_head1: _xtract[0].tds_head,
            cut_if_lwp1: _xtract[0].cut_if_lwp1 });
      });
   }

   const customStyles = {
      content: {
         top: '50%', width: '40%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
        
      },
   };


   let subtitle;
   const [modalIsOpen, setModalIsOpen] = useState(false);

   let type1 = 'Variable'
   if(typ1 == 'fixed'){
      let type1 = 'Fixed'
   }
   let cut_lwp1 = 'YES';
   if(cut_if_lwp1 == '0'){
      let cut_lwp1 = 'NO';
   }

   return (
      <>



         <div id="content-page" className="content-page">
            <div className="container-fluid">

               <div >
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Earning View</h4>
                           </div>
                        </div>

                        <div className="iq-card-body">
                           <div className="row">
                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>Earning Head Name : </b></span>
                                    <span> {name1}</span>
                              </div>
                              </div>

                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>Abbreviation : </b></span>
                                    <span>{abbreviation1}</span>
                                 </div>
                              </div>
                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>Accounting Code : </b></span>
                                    <span>{accounting_code1}</span>
                              </div>
                              </div>


                              <div className="col-md-6">
                                 <div className="form-group">
                                    <span><b>Type : </b></span>
                                    <span>{type1}</span>
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="form-group">
                                 <span><b>TDS Head : </b></span>
                                    <span>{tds_head1}</span>
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="form-group">

                                 <span><b>Cut if LWP : </b></span>
                                    <span>{cut_lwp1}</span>
                                 </div>
                              </div>
                             
                           </div>
                        </div>
                        <div className="text_center">
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>


               <div className="row">
                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Earning Add</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <form onSubmit={e => onSubmit(e)}>
                              <div className="row">


                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label>Earning Head Name <strong className="required"> *</strong></label>
                                       <input type="text" className="form-control validate[required]" name="name"
                                          value={name}
                                          onChange={e => onInputChange(e)}
                                          placeholder="Enter Earning Head Name" required />
                                    </div>
                                 </div>


                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label>Abbreviation</label>
                                       <input className="form-control" placeholder="Enter Abbreviation" id="" name="abbreviation"
                                          value={abbreviation}
                                          onChange={e => onInputChange(e)} />
                                    </div>
                                 </div>
                                 {/* <div className="col-md-6">
                                    <div className="form-group">
                                       <label>Accounting Code</label>
                                       <input className="form-control" placeholder="Enter Abbrevition Code" id="" name="accounting_code"
                                          value={accounting_code}
                                          onChange={e => onInputChange(e)} />
                                    </div>
                                 </div> */}


                                 {/* <div className="col-md-6">
                                    <div className="form-group">
                                       <label>Type</label>
                                       <select className="form-control" name="typ" value={typ}
                                          onChange={e => onInputChange(e)}>
                                          <option value="fixed">Fixed</option>
                                          <option value="variable">Variable</option>
                                       </select>
                                    </div>
                                 </div> */}
                                 {/* <div className="col-md-6">
                                    <div className="form-group">
                                       <label>TDS Head</label>
                                       <select className="form-control" name="tds_head" value={tds_head}
                                          onChange={e => onInputChange(e)}>
                                          <option value="">Salary</option>
                                       </select>
                                    </div>
                                 </div> */}

                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label>Cut if LWP</label>
                                       <select className="form-control" name="cut_if_lwp" value={cut_if_lwp}
                                          onChange={e => onInputChange(e)}>
                                          <option value="0">NO</option>
                                          <option value="1">YES</option>

                                       </select>
                                    </div>
                                 </div>
                                 {/* <div className="col-md-6">
                                    <div className="form-group">
                                       <label>Formula</label>
                                       <select className="form-control" name="formula_id" value={formula_id}
                                          onChange={e => onInputChange(e)}>
                                          <option value="">Select Formula</option>
                                       </select>
                                    </div>
                                 </div> */}

                                 <div className="col-md-12 mt-4">
                                    <button type="submit" name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    {(() => {
                                       if (id > 0) {
                                          return (
                                             <button type="button" className="btn" onClick={refreshPage}> Return </button>
                                          )
                                       }
                                    })()}
                                 </div>
                              </div>

                           </form>

                         
                        </div>
                     </div>
                  </div>

                  {/* <div className="row mt-4" > */}
                           <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List Of Earning</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                              <thead className="">
                                 <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col" style={{width:'200px'}}>Action</th>
                                 </tr>
                              </thead>
                              <tbody className="">
                                 {earningList.map((val, key) => {
                                    return (
                                       <tr key={key}>
                                          <th scope="row">{key + 1}</th>
                                          <td>{val.name}</td>
                                          
                                          <td>
                                          <button onClick={() => {ondlt(val.id);}}
 className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                             {/* <button onClick={() => { deleteItem(val.id); }}
                                                className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button> */}
                                             <button onClick={() => { getItem(val.id); }}
                                                className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>

                                             <button onClick={() => viewItem(val.id)}
                                                className="btn btn-info mr-2"><i className="fa fa-eye" aria-hidden="true"></i></button>

                                          </td>
                                       </tr>
                                    );
                                 })}

                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                              {/* </div> */}

             
               </div>
            </div>
         </div>

      </>
   )
}

export default Earning_head