import React from 'react';


function Profile() {
    return (
<>

<div id="content-page" className="content-page">
             <div className="container-fluid">

                 <div className="row">
                     <div className="col-sm-12">
                        <div className="iq-card">

                     <div className="hd5" >
                       <ul className="nav nav-tabs p_t_20">
                        <li className="nav-item">
                            <a href="#personnel" className="nav-link active" data-toggle="tab"> PERSONNEL</a>
                        </li>
                        <li className="nav-item">
                            <a href="#add_on_info" className="nav-link" data-toggle="tab">MONTHLY CTC</a>
                        </li>
                    </ul>
                     </div>
                     <div className="iq-card-body">
                        <div className="tab-content">
                        <div className="tab-pane fade show active" id="personnel">
                             <div className="iq-card">
                                <div className="iq-card-body">
                                  <div className="tab-content">
                                      <div className="row pb">
                                          <div className="col-sm-4 p4">
                                            <div className="form-group">
                                              <label>Code</label>
                                              <input type="text" className="form-control validate[required] border_0" name="code" value="" placeholder="" readonly="readonly" />
                                          </div>
                                          </div>
                                          <div className="col-sm-4 p4">
                                              <div className="form-group">
                                              <label>Name</label>
                                              <input type="text" className="form-control validate[required] border_0" name="first_name" value="" placeholder="" readonly="readonly"/>
                                          </div>
                                          </div>
                                          <div className="col-sm-4 p4">
                                              <div className="form-group">
                                              <label>Gender</label>
                                                 <input type="text" className="form-control validate[required] border_0" name="mob_no" value="" placeholder="" readonly="readonly"/>
                                          </div>
                                          </div>
                                      </div>

                                       <div className="row pb">
                                          <div className="col-sm-5 p4">
                                            <div className="form-group">
                                              <label>Mobile No</label>
                                              <input type="text" className="form-control validate[required] border_0" name="mob_no" value="" placeholder="" readonly="readonly"/>
                                          </div>
                                          </div>
                                           <div className="col-sm-2
                                          "> </div>
                                          <div className="col-sm-5 p4
                                          ">
                                              <div className="form-group">
                                              <label>Email Id</label>
                                              <input type="text" className="form-control validate[required] border_0" name="email_id" value="" placeholder="" readonly="readonly"/>
                                          </div>
                                          </div>
                                      </div>

                                      <div className="row pb">
                                          <div className="col-sm-5 p4">
                                            <div className="form-group">
                                              <label>Father Name</label>
                                              <input type="text" className="form-control validate[required] border_0" name="fn" value="" placeholder="" readonly="readonly"/>
                                          </div>
                                          </div>
                                           <div className="col-sm-2
                                          "> </div>
                                          <div className="col-sm-5 p4
                                          ">
                                              <div className="form-group">
                                              <label>Spouse Name</label>
                                              <input type="text" className="form-control validate[required] border_0" name="sn" value="" placeholder="" readonly="readonly"/>
                                          </div>
                                          </div>
                                      </div>

                                      <div className="row pb">
                                          <div className="col-sm-7 p4">
                                            <div className="form-group">
                                              <label>Present Address</label>
                                              <input type="text" className="form-control validate[required] border_0" name="fn" value="" placeholder="" readonly="readonly"/>
                                          </div>
                                          </div>
                                           <div className="col-sm-2
                                          "> </div>
                                          <div className="col-sm-3 p4
                                          ">
                                          </div>
                                      </div>

                                      <div className="row pb">
                                          <div className="col-sm-7 p4">
                                            <div className="form-group">
                                              <label>Permanent Address</label>
                                              <input type="text" className="form-control validate[required] border_0" name="fn" value="" placeholder="" readonly="readonly"/>
                                          </div>
                                          </div>
                                           <div className="col-sm-2
                                          "> </div>
                                          <div className="col-sm-3 p4
                                          ">
                                          </div>
                                      </div>

                                       <div className="row pb">
                                          <div className="col-sm-5 p4">
                                            <div className="form-group">
                                              <label>Date Of Birth</label>
                                              <input type="text" className="form-control validate[required] border_0" name="fn" value="" placeholder="" readonly="readonly"/>
                                          </div>
                                          </div>
                                          <div className="col-sm-5 p4
                                          ">
                                              <div className="form-group">
                                              <label>Blood Group</label>
                                              <input type="text" className="form-control validate[required] border_0" name="sn" value="" placeholder="" readonly="readonly"/>
                                          </div>
                                          </div>
                                            <div className="col-sm-2
                                          "> </div>
                                      </div>

                                      <div className="row pb">
                                          <div className="col-sm-5 p4">
                                            <div className="form-group">
                                              <label>Emergency Contact No</label>
                                              <input type="text" className="form-control validate[required] border_0" name="fn" value="" placeholder="" readonly="readonly"/>
                                          </div>
                                          </div>
                                          <div className="col-sm-5 p4
                                          ">
                                          </div>
                                            <div className="col-sm-2
                                          "> </div>
                                      </div>

                                  </div>
                                </div>
                             </div>
                        </div>
                        <div className="tab-pane fade" id="add_on_info">

                             <div className="row">

                    <div className="col-sm-6 p_r">
                       <div className="iq-card">
                          <div className="iq-card-body p_r_0">
                             <table id="user-list-table" className="table table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                             <thead>
                                 <tr className="bk">
                                    <th colspan="2" className="text_center" >Salary</th>
                                 </tr>
                                 <tr  className="bk">
                                    <th>Description</th>
                                    <th>Amount</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr className="bk1">
                                    <td className="">Basic Salary</td>
                                    <td className="text_end">5000</td>
                                 </tr>
                                 <tr  className="bk1">
                                    <td className="">Basic Salary</td>
                                    <td className="text_end">5000</td>
                                 </tr>
                                 <tr  className="bk1">
                                    <td className="">Basic Salary</td>
                                    <td className="text_end">5000</td>
                                 </tr>
                                 <tr className="bk2">
                                    <th className="">Gross pay(A)</th>
                                    <th className="text_end">5000</th>
                                 </tr>
                                 <tr className="bk3">
                                    <th className="">CTC(A+B)</th>
                                    <th className="text_end">5000</th>
                                 </tr>

                             </tbody>
                           </table>
                          </div>
                       </div>
                    </div>
                    <div className="col-sm-6 p_l_1">
                         <div className="iq-card">
                            <div className="iq-card-body p_l_0">
                              <table id="user-list-table" className="table table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                             <thead>
                                 <tr className="bk">
                                    <th colspan="2" className="text_center">Reimbursement</th>
                                 </tr>
                                 <tr className="bk">
                                    <th>Description</th>
                                    <th>Amount</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr  className="bk1">
                                    <td className="">Conveyance</td>
                                    <td className="text_end">2000</td>
                                 </tr> 
                                 <tr  className="bk1">
                                    <td className="">Conveyance</td>
                                    <td className="text_end">2000</td>
                                 </tr> 
                                 <tr  className="bk1">
                                    <td className="">Conveyance</td>
                                    <td className="text_end">2000</td>
                                 </tr> 
                                 <tr className="bk2" >
                                    <th className="">Gross Reimbursement(B)</th>
                                    <th className="text_end">2000</th>
                                 </tr>
                                 <tr className="bk3" >
                                    <th className="">(In Word)</th>
                                    <th></th>
                                 </tr>
                                                               
                             </tbody>
                           </table>
                          </div>
                       </div>
                    </div>

                </div>

                        </div>
                       
                     </div>
                  </div>
                  </div>
                </div>
             
          </div>
        </div>
    </div>
          
</>
    )
}

export default Profile
