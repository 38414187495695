import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';

function Team_member_leaves() {

   const alert = useAlert();
   const localuser = JSON.parse(localStorage.getItem('user'));
   let emp = Apiconnect.decrypt(localuser.id);



   const leaveApprove = e => {

      let info = { id: e, status: 1, approved_by: emp };
      let info_enc = Apiconnect.encrypt_obj(info);
      const inf_a = { enc: info_enc };
     // Axios.post(`http://localhost:3050/api/employee_leave/updatestatus`,inf_a).then((response) => {
      Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
         alert.success(response.data.message);
         leavedata();
      });

   }

   const leaveCancle = e => {
      let info = { id: e, status: 2, approved_by: emp };
      let info_enc = Apiconnect.encrypt_obj(info);
      const inf_a = { enc: info_enc };
      Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
         alert.success(response.data.message);
         leavedata();
      });
   }

   useEffect(() => { leavedata();empdata(); }, []);
   const [leaveList, setleaveList] = useState([]);
   const [empList, setEmpList] = useState([]);

   const leavedata = () => {
       Apiconnect.getData(`employee_leave/leavereportingdata/${emp}`).then((response) => {
     // Axios.get(`http://localhost:3050/api/employee_leave/leavereportingdata/${emp}`,).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setleaveList(_xtract.reverse());
         // console.log(_xtract);
      });
   }
   const empdata = () => {
      Apiconnect.getData(`employee_dailydata/employeereportingdata/${emp}`).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setEmpList(_xtract.reverse());
      //   console.log(_xtract);
      });
    }

    var date = new Date();
    const [from_date,setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [to_date,setToDate] = useState(new Date());

   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">


               <div className="row">
                  <div className="col-sm-12">
                     <div className="iq-card">
                        <div>
                           <h4 className="card-title hd">Team Member Leaves</h4>
                        </div>
                        <div className="iq-card-body">

                           <div className="row p_t_15">

                              <div className="col-md-3">
                                 From :  <br /><DatePicker
                                    selected={from_date}
                                    onChange={e => setFromDate(e)}
                                    className="form-control"
                                    dateFormat='yyyy-MM-dd'
                                    placeholderText="From Date"
                                    name="from_date"
                                    required=""
                                 />
                              </div>
                              <div className="col-md-3">
                                 TO :  <br /> <DatePicker
                                    selected={to_date}
                                    onChange={e => setToDate(e)}
                                    className="form-control"
                                    dateFormat='yyyy-MM-dd'
                                    placeholderText="To Date"
                                    name="to_date"
                                    required=""
                                 />
                              </div>
                              <div className="col-md-3">
                                 User : <br />
                                 <select className="form-control" name="emp_id">
                                    <option value="">Select User</option>
                                    {empList.map((val, key) => {
                                       return (
                                          <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                       );
                                    })}
                                 </select>
                              </div>
                              <div className="col-md-2">
                                 <input type="button" className="btn btn-success" value="Submit" style={{ marginTop: "23px" }} />
                              </div>

                           </div>

                           <div className="row">

                              <div className="table-responsive">

                                 <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                    <thead>
                                       <tr className="clr" >
                                          <th>#</th>
                                          <th>Emp. Name</th>
                                          <th>Applied On</th>
                                          <th>Leave Type</th>
                                          <th>Reason</th>
                                          <th>Days</th>
                                          <th>Date</th>
                                          <th>Opening</th>
                                          <th>Available</th>
                                          <th>Status</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {leaveList.map((val, key) => {

                                          var action1 = '';
                                          if (val.status === 0) {
                                             var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                                             var action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(val.id)}>Approve</div>;
                                             var action1 = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(val.id)}>Cancel</div>;
                                          }
                                          if (val.status === 1) {
                                             var status = <div className=" badge badge-pill badge-success">Approved</div>;
                                             var action = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(val.id)}>Cancel</div>;
                                          }
                                          if (val.status === 2) {
                                             var status = <div className=" badge badge-pill badge-danger">Cancelled</div>;
                                             var action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(val.id)}>Approve</div>;
                                          }

                                          return (
                                             <tr key={key}>
                                                <th scope="row">{key + 1}</th>
                                                <td>{val.first_name} {val.last_name}</td>
                                                <td>{val.applied_on}</td>
                                                <td>{val.name}</td>
                                                <td>{val.reason}</td>
                                                <td>{val.leave_count}</td>
                                                <td>{val.leave_start_date1} to {val.leave_end_date1}</td>
                                                <td></td>
                                                <td></td>
                                                <td>{status}</td>
                                                <td>{action} {action1}</td>
                                             </tr>
                                          );
                                       })}
                                    </tbody>
                                 </table>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>


      </>
   )
}

export default Team_member_leaves
