import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";

const ResetPassword = () => {

    const alert = useAlert();

    let history = useHistory();
    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id);

    const [info2, setInfo2] = useState({
        id: emp, password: '', new_password: '', cpassword: ''
    });
    const { id, password, new_password, cpassword } = info2;

    const onInputChange = e => {
        setInfo2({ ...info2, [e.target.name]: e.target.value });
    }

    const onSubmit = async e => {

        e.preventDefault();
        let np = info2.new_password;
        let cp = info2.cpassword;

        let data = {id: emp, password: info2.password, new_password: info2.new_password};
        if(np !== cp){
            alert.success('Comfirm Password Not Match');
            return false;
        }
        // console.log(data);
        // return false;
        let info_enc = Apiconnect.encrypt_obj(data);
        const inf_a = { enc: info_enc };

        Apiconnect.postData(`employee_info/resetpassword/`, inf_a).then((response) => {
           
            if(response.data.status === 1){
                history.push("/ems/dashboard");
                setInfo2({ id: emp,password: '', new_password: '', cpassword: ''});
            }
            alert.success(response.data.message);
           
        });
    }



    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-2">
                            <div className="iq-card">
                                <div className="iq-card-header">
                                    <div className="iq-card-header d-flex justify-content-between">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">Change password</h4>
                                        </div>
                                    </div>
                                </div>
                                  <form onSubmit={e => onSubmit(e)}>
                                <div className="iq-card-body">
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-6 col-md-6 separator social-login-box"> <br />
                                            <img alt="" className="img-thumbnail" src="https://bootdey.com/img/Content/avatar/avatar1.png" />
                                        </div>
                                        <div style={{ marginTop: "80px" }} className="col-xs-6 col-sm-6 col-md-6 login-box">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-addon"><span className="glyphicon glyphicon-lock"></span></div>
                                                    <input className="form-control" type="text" name="password" placeholder="Current Password" value={password} onChange={e => onInputChange(e)} required />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-addon"><span className="glyphicon glyphicon-log-in"></span></div>
                                                    <input className="form-control" type="password" name="new_password" placeholder="New Password" value={new_password} onChange={e => onInputChange(e)} required/>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-addon"><span className="glyphicon glyphicon-log-in"></span></div>
                                                    <input className="form-control" type="password" name="cpassword" placeholder="Confirm Password" value={cpassword} onChange={e => onInputChange(e)} required/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-6 col-md-6"></div>
                                        <div className="col-xs-6 col-sm-6 col-md-6" style={{ padding: '10px' }}>
                                            <button className="btn icon-btn-save btn-success" type="submit">
                                                <span className="btn-save-label"><i className="glyphicon glyphicon-floppy-disk"></i></span>save</button>
                                        </div>
                                    </div>
                                </div>
                               </form>   
                            </div>
                           
                        </div>

                    </div>

                </div>
            </div>
        </>
    )

}
export default ResetPassword