import React, { useState, useEffect } from 'react';
import Apiconnect from "../services/Apiconnect.js";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from "react-alert";
import './public.css';
import Loadder from "./webview_lodder";



function Webview_team_member_leaves(props) {

    let emp = props.match.params.id;
    const alert = useAlert();
    useEffect(() => { leavedata(); empdata(); }, []);
    const [leaveList, setleaveList] = useState([]);
    const [empList, setEmpList] = useState([]);

    const leaveApprove = e => {

        let info = { id: e, status: 1, approved_by: emp };
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };
        Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
           alert.success(response.data.message);
           leavedata();
        });

     }
     const leaveCancle = e => {
        let info = { id: e, status: 2, approved_by: emp };
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };
        Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
           alert.success(response.data.message);
           leavedata();
        });
     }



    const leavedata = () => {
        Apiconnect.getData(`employee_leave/leavereportingdata/${emp}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setleaveList(_xtract.reverse());
        });
    }
    const empdata = () => {
        Apiconnect.getData(`employee_dailydata/employeereportingdata/${emp}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setEmpList(_xtract.reverse());
        });
    }

    var date = new Date();
    const [from_date, setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [to_date, setToDate] = useState(new Date());

    const onDatepickerRef = el => { 
        if (el && el.input) { el.input.readOnly = true; }
      }

    return (
        <>
            <div id="content-page" className="" style={{ marginLeft: 'unset !important', overflow: 'hidden', background: '#e2e2e2', padding: '20px 0px', minHeight: '100vh' }}>
                <div className="container-fluid leave_div">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                               
                                <div className="iq-card-body">

                                    <div className="row">
                                        <div className="form-group col-sm-12">
                                        <label>From </label>
                                            <DatePicker
                                                selected={from_date}
                                                ref={el => onDatepickerRef(el)}
                                                onChange={e => setFromDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="From Date"
                                                name="from_date"
                                                required=""
                                            />
                                        </div>
                                        <div className="form-group col-sm-12">
                                        <label>TO </label> 
                                        <DatePicker
                                                selected={to_date}
                                                ref={el => onDatepickerRef(el)}
                                                onChange={e => setToDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="To Date"
                                                name="to_date"
                                                required=""
                                            />
                                        </div>
                                        <div className="form-group col-sm-12">
                                        <label>User</label>
                                            <select className="form-control" name="emp_id">
                                                <option value="">Select User</option>
                                                {empList.map((val, key) => {
                                                    return (
                                                        <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-sm-12">
                                            <input type="button" className="btn btn-success btn-block" value="Submit" style={{background: '#4f4967',border: '#4f4958',marginTop: "23px"}} />
                                        </div>

                                    </div>
                                    <div className="row">             
                                    <div className="col-sm-12" style={{padding:'0px !important'}}>
                                        <div className="table-responsive">
                                            <table id="user-list-table" className="table table-striped table-bordered mt-4 tbl" role="grid" aria-describedby="user-list-page-info">
                                                <thead>
                                                    <tr className="clr" >
                                                     
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {leaveList.map((val, key) => {

                                                        var action1 = '';
                                                        if (val.status === 0) {
                                                            var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                                                            var action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(val.id)}>Approve</div>;
                                                            var action1 = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(val.id)}>Cancel</div>;
                                                        }
                                                        if (val.status === 1) {
                                                            var status = <div className=" badge badge-pill badge-success">Approved</div>;
                                                            var action = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(val.id)}>Cancel</div>;
                                                        }
                                                        if (val.status === 2) {
                                                            var status = <div className=" badge badge-pill badge-danger">Cancelled</div>;
                                                            var action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(val.id)}>Approve</div>;
                                                        }

                                                        return (
                                                            <tr key={key}>
                                                                
                                                                <td colSpan="10" style={{position:'relative',}} >
                                                                    <strong>{val.first_name} {val.last_name}</strong><br />
                                                                    {val.name} <br />
                                                                    Applied on:  {val.applied_on}<br />                                                                    
                                                                    From: {val.leave_start_date1} to {val.leave_end_date1}  <br />
                                                                    ({val.leave_count} Day{val.leave_count>1?'s':''}) <br />
                                                                    Reason: {val.reason} <br />

                                                                    <span style={{position:'absolute', right:0, top:15,}}>{status}</span>

                                                                    {action} {action1}
                                                                    
                                                                </td>
                                                                
                                                                
                                                                
                                                                 
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Webview_team_member_leaves