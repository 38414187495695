import React, {useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import '../pages/pages.css';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";


const Company = () => {

   const alert = useAlert();

   let history = useHistory();
   const local_user = JSON.parse(localStorage.getItem('user'));

   const [num_msg,setNumMsg]= useState({msg:''});
   const {msg} = num_msg;

   const [num_msg1,setNumMsg1]= useState({msg1:''});
   const {msg1} = num_msg1;

   const [num_msg2,setNumMsg2]= useState({msg2:''});
   const {msg2} = num_msg2;

   const [info, setInfo] = useState({

      company_name: "",org_type: "",country_id: "",state_id: "",city_id: "",area_id: "",landmark: "",address_one: "",address_two: "",zip: "" ,
      phone: "",phone_secondary: "",mobile: "",fax: "",website: "",email: "",email_secondary: "",is_pf: "",is_grade: "",is_esi: "",is_leave: "",
      is_loan: "",is_attendance_device: "",
      id: null,
    });
    const { company_name,org_type,country_id,state_id,city_id,area_id,landmark,address_one,address_two,zip ,phone,phone_secondary,
      mobile,fax,website,email,email_secondary,id } = info;
    const onInputChange = e => {
       if(e.target.name ==='mobile'){
          if (e.target.value.length > 10) {
              setNumMsg({msg:''});
            return false;
          }else{
              setNumMsg({msg:'Number Should 10 Digits'});
          }
       }
      
       if( e.target.name ==='phone'){
          if (e.target.value.length > 10) {
              setNumMsg1({msg1:''});
            return false;
          }else{
              setNumMsg1({msg1:'Number Should 10 Digits'});
          }
       }

       if(e.target.name ==='phone_secondary'){
          if (e.target.value.length > 10) {
              setNumMsg2({msg2:''});
            return false;
          }else{
              setNumMsg2({msg2:'Number Should 10 Digits'});
          }
       }



      setInfo({ ...info, [e.target.name]: e.target.value });
    };

    const onSubmit = async e => {
      e.preventDefault();  

      if(id>0){
         console.log('in edit function' +id);
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
         Apiconnect.postData(`company/update/`, inf_a).then((response) => {
            history.push("/company_list"); 
            setInfo({ company_name: "",org_type: "",country_id: "",state_id: "",city_id: "",area_id: "",landmark: "",address_one: "",address_two: "",zip: "" ,
            phone: "",phone_secondary: "",mobile: "",fax: "",website: "",email: "",email_secondary: "", id: ''});
            // getCats();
            alert.success(response.data.message);  
         });
      }else{
         console.log('in add function' );
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
         await Apiconnect.postData("company/create", inf_a).then((response) => {
            console.log(response);
            history.push("/company_list"); 
            setInfo({ company_name: "",org_type: "",country_id: "",state_id: "",city_id: "",area_id: "",landmark: "",address_one: "",address_two: "",zip: "" ,
            phone: "",phone_secondary: "",mobile: "",fax: "",website: "",email: "",email_secondary: "", id: ''});
            // getCats();
            alert.success(response.data.message);  
         });
        
      }
     
    };
   
   useEffect(() => {getArea();getCity();getState();getCountry();getOrg();}, []);
   const [areaList, setArea] = useState([]);
   const [cityList, setCity] = useState([]);
   const [stateList, setState] = useState([]);
   const [countryList, setCountry] = useState([]);
   const [orgList,setOrg]= useState([]);

   const getOrg = ()=>{
      Apiconnect.getData("orgtype/getall").then((response) => {
         setOrg(response.data.data.reverse());
      });
   }

   const getArea = () => {
     Apiconnect.getData("area/getall").then((response) => {
         setArea(response.data.data.reverse());
      });
    };

    const getCity = () => {
     Apiconnect.getData("city/getall").then((response) => {
         setCity(response.data.data.reverse());
      });
    };

    const getState = () => {
     Apiconnect.getData("state/getall").then((response) => {
         setState(response.data.data.reverse());
      });
    };

    const getCountry = () => {
     Apiconnect.getData("country/getall").then((response) => {
         setCountry(response.data.data.reverse());
      });
    };


   function refreshPage(){ 
      window.location.reload(); 
   }

   function returnPage(){
      history.push("/company_list"); 
   }

   return (
      <>


<div id="content-page" className="content-page">
         <div className="container-fluid">

               <div className="row">
                  <div className="col-sm-12 col-lg-12 col-md-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">{id ? 'Company Edit' : 'Company Add'}</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                              <form onSubmit={e => onSubmit(e)}>
                              <div className="row">

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Company Name <span className="required">*</span></label>
                                    <input type="text" className="form-control" name="company_name" 
                                     placeholder="Company Name" 
                                     value={company_name}
                                    onChange={e => onInputChange(e)} required  />
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Organasation Type <span className="required">*</span></label>
                                    <select className="form-control" name="org_type" required
                                     value={org_type}
                                    onChange={e => onInputChange(e)}>
                                       <option value="">Select Type</option>
                                       {
                                          orgList.map((val,key) => {
                                             return(
                                                <option key={key} value={val.id}>{val.name}</option>
                                             )
                                          })

                                       }
                                    </select>
                                 </div>
                              </div>

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Address 1</label>
                                    <input type="text" className="form-control" name="address_one"
                                     placeholder="Address 1" 
                                     value={address_one}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Address 2</label>
                                    <input type="text" className="form-control" name="address_two" placeholder="Address 2"
                                     value={address_two}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Landmark</label>
                                    <input type="text" className="form-control" name="landmark" placeholder="Landmark" 
                                     value={landmark}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>

                             
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Country <span className="required">*</span></label>
                                    <select className="form-control" name="country_id" required
                                     value={country_id}
                                     onChange={e => onInputChange(e)}>
                                       <option value="">Select Country</option>
                                       {
                                          countryList.map((val,key)=>{
                                             return(
                                                <option key={key} value={val.id}>{val.name}</option>
                                             )
                                          })
                                       }
                                    </select>
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>State <span className="required">*</span></label>
                                    <select className="form-control" name="state_id" required
                                     value={state_id}
                                     onChange={e => onInputChange(e)}>
                                       <option value="">Select State</option>
                                       {
                                          stateList.map((val, key)=>{
                                             return(
                                                <option  key={key} value={val.id}>{val.name}</option>
                                             )
                                          })
                                       }
                                    </select>
                                 </div>
                              </div>

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>City <span className="required">*</span></label>
                                    <select className="form-control" name="city_id" required
                                     value={city_id}
                                     onChange={e => onInputChange(e)}>
                                        <option value="">Select City</option>
                                        {cityList.map((val, key)=>{
                                           return(
                                           <option  key={key} value={val.id}>{val.name}</option>
                                           )
                                        })}
                                       
                                    </select>
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Area <span className="required">*</span></label>
                                    <select className="form-control" name="area_id" required  
                                    value={area_id}
                                    onChange={e => onInputChange(e)}>
                                        <option value="">Select Area</option>
                                      {areaList.map((val, key) => {       
                                       return (
                                          <option key={key} value={val.id}>{val.name}</option>
                                       );
                                    })}                           
                                       
                                    </select>
                                 </div>
                              </div>

                             
                            
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Zip <span className="required">*</span></label>
                                    <input type="text" className="form-control" name="zip"  placeholder="Zip" required
                                     value={zip}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Phone <span className="required">*</span></label>
                                    <input type="number" className="form-control" name="phone"placeholder="Phone" 
                                     value={phone}
                                     onChange={e => onInputChange(e)}/>
                                     <span className="required">{msg1}</span>
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Phone ( Secondary )</label>
                                    <input type="number" className="form-control" name="phone_secondary" placeholder="Phone Secondary" 
                                     value={phone_secondary}
                                     onChange={e => onInputChange(e)} />
                                     <span className="required">{msg2}</span>
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Mobile</label>
                                    <input type="number" className="form-control" name="mobile" placeholder="Mobile" value={mobile}  onChange={e => onInputChange(e) } />
                                     <span className="required">{msg}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Email <span className="required">*</span></label>
                                    <input type="email" className="form-control" name="email" placeholder="Email" 
                                     value={email} 
                                     onChange={e => onInputChange(e)
                                    } required />
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Email ( Secondary )</label>
                                    <input type="text" className="form-control" name="email_secondary" placeholder="Email Secondary"
                                     value={email_secondary}
                                     onChange={e => onInputChange(e)}/>
                                 </div>
                              </div>

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Fax</label>
                                    <input type="text" className="form-control" name="fax"  placeholder="Fax" 
                                     value={fax}
                                     onChange={e => onInputChange(e)}/>
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Website</label>
                                    <input type="text" className="form-control" name="website"  placeholder="Website"
                                     value={website}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>


                                 <div className="col-md-12 m-t-20">
                                    <button type="submit"  name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    <button type="button" className="btn" onClick={ returnPage }> Return to campany </button> 
                                    {(() => {
                                       if (id>0) {
                                          return (
                                             <button type="button" className="btn" onClick={ refreshPage }> Return</button> 
                                          )
                                       } 
                                    })()}     
                                 </div>
                              </div>



                              </form>
                        </div>
                     </div>
                  </div>
                  
               </div>
         </div>
         </div>
      </>
   )
}

export default Company
