import React, {useState, useEffect } from "react";
import Loader from "react-loader-spinner";

const Loadder = () => {



        return (
            // bar
           // <Loader type="Bars" color="#00BFFF" height={80} width={80} />

             // Three docs
             <loaddercontainer style={{textAlign: 'center'}}>
                  <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
             </loaddercontainer>
            


        //   <Loader
        //     type="Puff"
        //     color="#00BFFF"
        //     height={100}
        //     width={100}
        //     timeout={3000} //3 secs
        //   />
        );
}

export default Loadder