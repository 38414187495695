import React, { useState, useEffect, useCallback } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import '../pages/uploadfile.css';
import Axios from 'axios';
import Cropper from 'react-easy-crop'


const Company_upload_logo = (props) => {

    const alert = useAlert();
    var company_id = props.match.params.id;
    
    // const localuser = JSON.parse(localStorage.getItem('user'));
    // var company_id = Apiconnect.decrypt(localuser.id); 

    // if(emp_id != undefined){
    //  var employee_id = emp_id; 
    // }else{
    // var employee_id = Apiconnect.decrypt(localuser.id); 
    // }
    //  console.log(employee_id);
  
  
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0)
    const [image, setImage] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState('http://100dayscss.com/codepen/upload.svg');
    const [saveCroppedImage, setsaveCroppedImage] = useState(null);

    // onCropChange = crop => {
    //     this.setState({ crop })
    //   }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }, [])

    
      const showCroppedImage = useCallback(async () => {
        try {
          const croppedImage = await getCroppedImg(
            image,
            croppedAreaPixels,
            rotation
          )
        //   console.log('donee', { croppedImage })
           setCroppedImage(croppedImage)
        //   setImage(croppedImage);
      
        } catch (e) {
          console.error(e)
        }
      }, [croppedAreaPixels, rotation])
    

      const createImage = url =>
      new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
      })
    
    function getRadianAngle(degreeValue) {
      return (degreeValue * Math.PI) / 180
    }
    

    async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
      const image = await createImage(imageSrc)
     
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
    
      const maxSize = Math.max(image.width, image.height)
      const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
    
      // set each dimensions to double largest dimension to allow for a safe area for the
      // image to rotate in without being clipped by canvas context
      canvas.width = safeArea
      canvas.height = safeArea
    
      // translate canvas context to a central location on image to allow rotating around the center.
      ctx.translate(safeArea / 2, safeArea / 2)
      ctx.rotate(getRadianAngle(rotation))
      ctx.translate(-safeArea / 2, -safeArea / 2)
    
      // draw rotated image and store data.
      ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
      )
      const data = ctx.getImageData(0, 0, safeArea, safeArea)
    
      // set canvas width to final desired crop size - this will clear existing context
      canvas.width = pixelCrop.width
      canvas.height = pixelCrop.height
    
      // paste generated rotate image with correct offsets for x,y crop values.
      ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
      )
    

      const blob = DataURLtoBlob(canvas.toDataURL('image/jpeg'))
      let file = new File([blob], company_id+'.png', {
        lastModified: new Date(),
        type: "image/png",
      })


      // As Base64 string
      // return canvas.toDataURL('image/jpeg');
      setsaveCroppedImage(file);
    //   const reader = new FileReader();
    //     console.log(canvas.toDataURL('image/jpeg'))
      // As a blob
      return new Promise(resolve => {
        canvas.toBlob(file => {
           
          resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
      })
    }

    function DataURLtoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
      
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
      
        // create a view into the buffer
        var ia = new Uint8Array(ab);
      
        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
      
        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;
      
      }

    const handleInputChange = e => {

        const file = e.target.files[0]
        const reader = new FileReader()

        reader.addEventListener('load', () => {
            setImage(reader.result);
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }



    }

    const submit = e => {

       

        console.log(saveCroppedImage);
        const data = new FormData() ;
        data.append('logo', saveCroppedImage);
           data.append('company_id', company_id);

         console.log(data);
         Apiconnect.postData(`company/uploadlogo`, data).then((response) => {           
               alert.success(response.data.message)
         });
    }

   
    return (



        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">

                    <div className="col-sm-6">
                        <div class="btn-container">
                            <div class="dropzone">
                               
                                
                                {/* <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" /> */}
                                <div className="row" style={{ margin: ' 0 10px 25px 10px' }}>
                                    <input type="file" accept="image/*" id="file" className="form-control" name="csvfile" onChange={e => handleInputChange(e)} />
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-sm-6">
                            <Cropper style={{borderRadius: '10px', marginBottom: '10px',width: '98%'}}
                            image={image}
                            crop={crop}
                            zoom={zoom}
                            aspect={1/1}
                            cropSize=	{{ width: 300, height: 300 }}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                              </div>
                            <div className="col-sm-6">
                            <img src={croppedImage} alt="Logo" style={{width:'98%',borderRadius: '10px',marginBottom: '3px'}} />
                            </div>
                            </div>

                            <div className="row" style={{marginTop:'15px'}}>
                            <div className="col-sm-6" style={{textAlign:'center'}}>
                            <button type="button" id="btnup" class="btn btn-info " onClick={showCroppedImage}>Crop Image</button>
                              </div>
                            <div className="col-sm-6" style={{textAlign:'center'}}>
                            <button type="button" id="btnup" class="btn btn-primary " onClick={(e) => submit(e)}>Submit</button>
                            </div>
                            </div>

                            
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Company_upload_logo