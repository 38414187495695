import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { Link } from "react-router-dom";
import "./admin.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Modal from "react-modal";
import "react-datepicker/dist/react-datepicker.css";
import Loadder from "./Loadder";
// const { createProxyMiddleware } = require('http-proxy-middleware');

const Employee_requested_thumb = () => {
  const alert = useAlert();
  let history = useHistory();
  const localuser = JSON.parse(localStorage.getItem("user"));
  let user_id = Apiconnect.decrypt(localuser.id);

  useEffect(() => {
    getEmployeeList();
  }, []);
  const [List, setEmployeeList] = useState([]);
  const [loader, setLoader] = useState(false);

  const getEmployeeList = () => {
    setLoader(true);
    Apiconnect.getData(
      `employee/requested_thumb_list/${Apiconnect.get_company_id()}`
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmployeeList(_xtract.reverse());
    
      setLoader(false);
    });
  };
  console.log(List);
  const customStyles = {
    content: {
       top: '55%', width: '60%',
       left: '50%',
       right: 'auto',
       bottom: 'auto',
       marginRight: '-50%',
       transform: 'translate(-50%, -50%)',

    },
 };
  const customStyles1 = {
    content: {
       top: '55%', width: '60%',
       left: '50%',
       right: 'auto',
       bottom: 'auto',
       marginRight: '-50%',
       transform: 'translate(-50%, -50%)',
       border:'unset ',
       backgroundColor:'unset ',
    },
 };

 const [img_old, setimg_old] = useState("/images/user/1.jpg");
 const [img_new, setimg_new] = useState(null);
  const [modalEmpIsOpen, setEmpModalIsOpen] = useState(false);
  const [modalEmpDetailsIsOpen, setEmpDetailsModalIsOpen] = useState(false);
  const [emp_id, setEmpId] = useState(null);

  const viewPic = (id)=>{

    setEmpId(id);
    setEmpModalIsOpen(true);
    const min = 1;
    const max = 1000;
    var rand = min + Math.random() * (max - min);
    var empdata = List.find(item => id === item.id);

    if(empdata.thumb != '' || empdata.thumb != null){
        var img_old = process.env.React_App_BASE_URL_API +"/uploads/thumb/" +empdata.thumb +"?" +rand;
        setimg_old(img_old);
    }
     var img_new = process.env.React_App_BASE_URL_API +"/uploads/thumbrequest/" +id+".png?" +rand;
     setimg_new(img_new);

  }

  const viewDetails = (id) =>{
    setEmpId(id);
    setEmpDetailsModalIsOpen(true);
  }

    const approve_pic = ()=>{

        Apiconnect.getData(`employee_info/approvedthumb/${user_id}/${emp_id}`).then((response) => {
            alert.success(response.data.message)
          });
          getEmployeeList();
          setEmpModalIsOpen(false);

    }
    const reject_pic = ()=>{
        Apiconnect.getData(`employee_info/rejectedthumb/${emp_id}`).then((response) => {
            alert.success(response.data.message)
          });
        getEmployeeList();
          setEmpModalIsOpen(false);
    }



  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">

             {/* ///////---------->Start Employee View<-----------///////// */}
             <div>
                  <Modal isOpen={modalEmpIsOpen} style={customStyles} onRequestClose={() => setEmpModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Employee Profile</h4>
                           </div>
                        </div>
                        <div className="iq-card-body" style={{padding:'0px'}}>
                           <div className="row">
                              {/* <div className="col-md-12"> */}
                                    <div className="col-sm-6 col-md-6">
                                    <div className="card" style={{width: '18rem'}}>
                                       <img src={img_old} className="card-img-top" alt="Old Image"/>
                                        <div className="card-body" style={{textAlign:'center'}}>
                                            <p className="card-text"><b>Old Image</b></p>
                                        </div>
                                       </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                    <div className="card" style={{width: '18rem'}}>
                                       <img src={img_new} className="card-img-top" alt="Old Image"/>
                                        <div className="card-body" style={{textAlign:'center'}}>
                                            <p className="card-text"><b>New Image</b></p>
                                        </div>
                                       </div>
                                    </div>
                              {/* </div> */}
                           </div>
                        </div>
                        <div className="col-md-12 mt-2" style={{textAlign:'right'}}>
                           <button style={{marginRight:'3px'}} className="btn btn-success" onClick={() => approve_pic()}>Approve</button>
                           <button style={{marginRight:'3px'}} className="btn btn-danger" onClick={() => reject_pic()}>Reject</button>
                           <button style={{marginRight:'3px'}} className="btn btn-info" onClick={() => setEmpModalIsOpen(false)}>Cancel</button>
                        </div>
                     </div>
                  </Modal>
               </div>
               {/* ///////---------->End Employee View<-----------///////// */}


                  {/* ///////---------->Start Employee View<-----------///////// */}
             <div>
                  <Modal isOpen={modalEmpDetailsIsOpen} style={customStyles} onRequestClose={() => setEmpDetailsModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Employee Details</h4>
                           </div>
                        </div>
                        <div className="iq-card-body" style={{padding:'0px'}}>
                          
                        </div>
                     
                     </div>
                  </Modal>
               </div>
               {/* ///////---------->End Employee View<-----------///////// */}


          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div style={{ float: "left" }} className="iq-header-title">
                    <h4 className="card-title">List Of Employee</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <table className="table table-striped table-hover">
                    <thead className="">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Profile
                        </th>
                        <th scope="col">Emp Code</th>
                        <th scope="col">Name</th>
                        <th scope="col">Requests for Picture</th>
                        <th scope="col">Requests for Details</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {loader ? (
                        <Loadder />
                      ) : (
                        List.map((val, key) => {
                          if (val.thumb == null) {
                            var img = "/images/user/1.jpg";
                          } else {
                            const min = 1;
                            const max = 1000;
                            var rand = min + Math.random() * (max - min);
                            var img =
                              process.env.React_App_BASE_URL_API +
                              "/uploads/thumb/" +
                              val.thumb +
                              "?" +
                              rand;
                          }


                          return (
                            <tr key={key}>
                              <th scope="row">{key + 1}</th>
                              <td
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                <div className="" style={{ display: "grid" }}>
                                  <img
                                    src={img}
                                    alt="profile-img"
                                    className="prof"
                                  />
                                </div>
                              </td>
                              <td>{val.emp_code}</td>
                              <td>
                                {val.first_name} {val.last_name}
                              </td>
                              <td>
                                {(val.thumb_request_status != 0)?
                                <button onClick={() => {viewPic(val.id);}} className="btn btn-info mr-2 m-1">
                                  <i className="fa fa-eye" aria-hidden="true"></i>
                                </button>:'-'}
                              </td>
                              <td>
                              {(val.details_request_status != 0)?
                               <Link className="btn btn-info mr-2 m-1" data-toggle="tab" to={`/employee_requested_details/${val.id}`} ><i className="fa fa-eye" aria-hidden="true"></i></Link>
                                :'-'}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employee_requested_thumb;
