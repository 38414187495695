import React, {Component} from 'react';
import Apiconnect from "../../services/Apiconnect.js"; 
import { useAlert } from "react-alert";



class test extends Component {
    constructor(props) {
      super(props); 

      this.state = { sal_temp: [] };

       // this.get_earning_head();
        //this.getdeductList();
         
        this.getTemplateList(2);
       
      
    } 

    getTemplateList = (id) => {        
        Apiconnect.getData(`employee_salary_template/getAllheadByTemplateId/${id}`).then((response) => {
          //let _xtract = response.data.data;
          //console.log(_xtract);
          console.log(response.data.data);
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          console.log(_xtract);

          this.setState({ sal_temp: _xtract });

          
          
        });
    }
     
   
    

    calculateForm = async e => {

      var BASIC;
      var DA = (BASIC*45)/100;
      var HRA = ((BASIC+DA)*45)/100;

      var ern = BASIC+DA+HRA;

      var pf = (ern*12)/100;
      var pf_o = pf;

      var esi = (ern*0.75)/100;
      var esi_o = (ern*3.25)/100;

      var ggg = this.state.sal_temp;
      console.log(ggg);
      console.log('kkkkkk');
    };

   
    
     




  
    render() {
      const alert = this.props.alert;
  
      return (

        <div id="content-page" className="content-page">
        <div className="container-fluid">
           <div className="row">
 
                <button className="btn btn-success" onClick={e => this.calculateForm(e)} >GO</button> 

            </div>
        </div>
        </div>

 


      );
    }
  }

  export default (test);