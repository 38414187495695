import React, { useState, useEffect } from 'react';
import Apiconnect from "../services/Apiconnect.js";
import './public.css';
import Loadder from "./webview_lodder";


function Webview_holiday(props) {

    let emp = props.match.params.id;
    let company_id = props.match.params.company_id;

    const [crnt_year, set_crnt_year] = useState(new Date().getFullYear());
    const yr = [new Date().getFullYear()-1,new Date().getFullYear(),new Date().getFullYear()+1];

    useEffect(() => { team(crnt_year); }, []);
    const [List, setList] = useState([]);

    const team =(crnt_year) => {
        Apiconnect.getData(`company_holiday/getAllYearWise/${company_id}/${crnt_year}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            console.log(_xtract);
            setList(_xtract.reverse());
        });

    }
    const currentYear = (year) =>{
        set_crnt_year(year);
        team(year)
    }


    return (
        <>
            <div id="content-page" className="" style={{ marginLeft: 'unset !important', overflow: 'hidden', background: '#e2e2e2', padding: '20px 15px', minHeight: '100vh' }}>
                <div className="">
                    <div className="holiday" style={{display:'unset !important'}}>
                        <div className="iq-card">
                            <div className="iq-card-header justify-content-between">
                                <div className="iq-header-title" style={{paddingTop:'5px'}}>
                                    <h3 className="card-title" style={{paddingTop: '5px'}}>Holiday List</h3>
                                </div>
                                  <div style={{ fontSize: '20px',float: 'right' }}>
                                        <ul className="list-group list-group-horizontal">
                                           
                                          {
                                            yr.map((val)=>{
                                                var ul = 'list-group-item';
                                                if(crnt_year == val){
                                                    var ul = 'list-group-item ul ulbg';
                                                }
                                                return (
                                                    <li onClick={() => currentYear(val)} className={ul} style={{padding: '8px',border:'unset !important'}}><a href="#">{val}</a></li>
                                                )
                                            })
                                          }
                                        </ul>
                                    </div>


                            </div>
                            <div className="row"></div>
                            <div style={{padding:'8px !important'}} className="iq-card-body" >
                                <ul className="media-story m-0 p-0">
                                    {List.map((val, key) => {
                                        var xximg =  process.env.React_App_BASE_URL_API+'/uploads/images/holidays.jpg';
                                        return (
                                            <li key={key} className="d-flex mb-4 align-items-center active" style={{border: '1px solid', borderRadius: '10px', padding: '8px'}}>
                                                 <img  src={val.thumb!=null?val.thumb:xximg}  style={{ width: '70px', height: '70px' }} alt="story-img" className="rounded-circle img-fluid" />
                                                <div className="stories-data ml-3">
                                                    <h5>{val.name} {val.national_holiday==1?'(National Holiday)':''}</h5>
                                                    <p className="mb-0">From: {val.start_date}</p>
                                                    <p className="mb-0">To: {val.start_date}</p> 
                                                </div>
                                            </li>
                                        );
                                    })}
                                    
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Webview_holiday