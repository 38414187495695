import React from 'react';
import { useHistory } from "react-router-dom";
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom'; 
import routes from '../emsroutes';
  

 
const local_user = JSON.parse(localStorage.getItem('user'));

let emp_typ = '';
if(local_user){
  emp_typ = local_user.emp_typ;
}

 
var str = window.location.href;
str = str.split("/");

//console.log(str[3]);


const TheContent = () => {
  let history = useHistory();


  /*
  if(emp_typ==2 && str[3]=='ems'){                
  }else{
    history.push("/dashboard/"); 
  }
*/



  return (
    <>
       
         
          <Switch>
            {routes.map((route, idx) => {
              return route.component && (
                <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (                        
                        <route.component {...props} />                        
                    )} />
              )
            })}


<Redirect from="/ems/" to="/ems/dashboard" />
            
            




          </Switch>
        
       
    </>
  )
}

export default React.memo(TheContent)
