import React, {useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import '../pages/pages.css';
import '../pages/admin.css';
import Axios from 'axios';




import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const Area = () => {

   const alert = useAlert();
   let history = useHistory();
   const localuser = JSON.parse(localStorage.getItem('user'));
    let employee_id = Apiconnect.decrypt(localuser.id); 
   
   const [info, setInfo] = useState({
      name: "",
      city_id: "",
      id: null,
    });
    const { name, city_id,id } = info;
    const onInputChange = e => {
      setInfo({ ...info, [e.target.name]: e.target.value });
    };

    const onSubmit = async e => {
      e.preventDefault();

      if(id>0){
         console.log('in edit function' +id);
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc,employee_id:employee_id };
        // Axios.post(`http://localhost:3050/api/tush/update`, inf_a).then((response) => {
         await Apiconnect.postData("area/update/",inf_a).then((response) => {
            console.log(response);
            setInfo({ name: '', city_id: '', id: ''});
            getCats();
            alert.success(response.data.message);   
         });
         
      }else{
         console.log('in add function.....' );
         let info_enc =  Apiconnect.encrypt_obj(info);
         // console.log(info_enc);
         const inf_a = { enc: info_enc,employee_id:employee_id };
       // Axios.post(`http://localhost:3050/api/tush/create`, inf_a).then((response) => {
          await Apiconnect.postData("area/create",inf_a).then((response) => {
            setInfo({ name: '', city_id: '', id: ''});
            getCats();
            alert.success(response.data.message);   
         });
      }     
    };


    const ondlt = (id) => {
      confirmAlert({
        title: 'Are you sure to Delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteItem(id), 
          },
          {
            label: 'No',
          }
        ]
      });
    };

    const deleteItem = (id) => {
    //  Axios.delete(`http://localhost:3050/api/tush/delete/${id}/${employee_id}`).then((response) => {
             Apiconnect.deleteData(`area/delete/${id}/${employee_id}`).then((response) => {
                  getCats();
                  alert.success(response.data.message);      
               });
     
    };


    const getItem = (id) => {    
      Apiconnect.getData(`area/get/${id}`).then((response) => {        
         let _xtract = response.data.data;      
         setInfo({ name: _xtract[0].name, city_id: _xtract[0].city_id, id: _xtract[0].id });      
      });
       
    };

   
   useEffect(() => {getCats();getCity();}, []);
   const [catList, setCatList] = useState([]);
   const [cityList, setCity] = useState([]);
   const getCats = () => {
      Apiconnect.getData(`area/getall`).then((response) => {
         let _xtract = response.data.data;
         setCatList(_xtract.reverse());      
      });
   };

   const getCity = () => {
      Apiconnect.getData("city/getall").then((response) => {
          setCity(response.data.data.reverse());
       });
     };


   function refreshPage(){ 
      window.location.reload(); 
   }


   const [viewList,setViewList] = useState({
      name1: "",
      city_id1: "",
   });

   const {  name1, city_id1} = viewList;

   const viewItem = (id)=>{
      setModalIsOpen(true);

      Apiconnect.getData(`area/get/${id}`).then((response) => {
         console.log(response.data.data[0]);
         let _xtract = response.data.data;
         setViewList({
            name1: _xtract[0].name, city_id1: _xtract[0].city_name});
      });
   }

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
        
      },
   };
   const [modalIsOpen, setModalIsOpen] = useState(false);

   return (
      <>
         <div id="content-page" className="content-page">
         <div className="container-fluid">

         <div >
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Area View</h4>
                           </div>
                        </div>

                        <div className="iq-card-body">
                           <div className="row">
                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>Area Name : </b></span>
                                    <span> {name1}</span>
                              </div>
                              </div>
                              
                              
                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>City : </b></span>
                                    <span>{city_id1}</span>
                                 </div>
                              </div>
                             
                           </div>
                        </div>
                        <div className="text_center">
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>


               <div className="row">
                  <div className="col-sm-12 col-lg-6">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">{id ? 'Area edit' : 'Area add'}</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                              <form onSubmit={e => onSubmit(e)}>
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="form-group">
                                    <label>Area name <span className="required">*</span></label>
                                    <input type="text" className="form-control " required 
                                    name="name"
                                    value={name}
                                    onChange={e => onInputChange(e)} 
                                    placeholder="Area Name"  />
                                    </div>
                                 </div>
                                 <div className="col-md-12">
                                 <div className="form-group">
                                    <label>City <span className="required">*</span></label>
                                    <select className="form-control" name="city_id" required
                                     value={city_id}
                                     onChange={e => onInputChange(e)}>
                                        <option value="">Select City</option>
                                        {cityList.map((val, key)=>{
                                           return(
                                           <option  key={key} value={val.id}>{val.name}</option>
                                           )
                                        })}
                                       
                                    </select>
                                 </div>
                              </div>

                                 <div className="col-md-12 mt-2">
                                    <button type="submit"  name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    {(() => {
                                       if (id>0) {
                                          return (
                                             <button type="button" className="btn" onClick={ refreshPage }> Return </button> 
                                          )
                                       } 
                                    })()}     
                                 </div>
                              </div>



                              </form>
                             

                        </div>
                     </div>
                  </div>

                   <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List of Area</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                                 <thead className="">
                                    <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Name</th>
                                       <th scope="col">City</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody className="">
                                    {catList.map((val, key) => {       
                                       return (
                                          <tr key={key}>  
                                             <th scope="row">{key+1}</th>
                                             <td>{val.name}</td>
                                             <td>{val.city_name}</td>
                                             <td>
                                             <button onClick={() => {ondlt(val.id);}}
                                             className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>

                                             <button onClick={() => {getItem(val.id);}}
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                              
                                              <button onClick={() => viewItem(val.id)}
                                                className="btn btn-info mr-2"><i className="fa fa-eye" aria-hidden="true"></i></button>
                                            
                                             </td>
                                          </tr> 
                                       );
                                    })}                               
                                 </tbody>
                           </table>
                        </div>
                     </div>
                  </div>    

                 
               </div>




 
         </div>
         </div>
      </>
   )
}

export default Area