import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';


const Employee_manage_employement_info = (props) => {

    const localuser = JSON.parse(localStorage.getItem('user'));
    let employee_id = Apiconnect.decrypt(localuser.id); 

    // let emp_id = Apiconnect.decrypt(props.match.params.id);
    let emp_id = props.match.params.id;

    const alert = useAlert();
    let history = useHistory();
    const [btn_data, setBtnId] = useState([]);
    const { btn_id } = btn_data;


    const [info2, setInfo2] = useState({
        employee_mst_id: emp_id, notice_period: '',id:''
    });
    const { notice_period,id } = info2;


    const onInputChange2 = e => {
        setInfo2({ ...info2, [e.target.name]: e.target.value });
    };

    // console.log(info2);

    const onSubmit = async e => {
        e.preventDefault();


        if (btn_id > 0) {

            console.log('in edit function.....');
            info2.employee_mst_id = emp_id;
            info2.id = id;


            if (confirmationDate === null) {
                info2.confirmation_date = confirmationDate;
            } else {
                info2.confirmation_date = format(confirmationDate, 'yyyy-MM-dd');
            }

            if (joiningDate === null) {
                info2.joining_date = joiningDate;
            } else {
                info2.joining_date = format(joiningDate, 'yyyy-MM-dd');
            }

            if (resignDate === null) {
                info2.resign_date = resignDate;
            } else {
                info2.resign_date = format(resignDate, 'yyyy-MM-dd');
            }

            if (leavingDate === null) {
                info2.leaving_date = leavingDate;
            } else {
                info2.leaving_date = format(leavingDate, 'yyyy-MM-dd');
            }

            if (promotionDate === null) {
                info2.promotion_date = promotionDate;
            } else {
                info2.promotion_date = format(promotionDate, 'yyyy-MM-dd');
            }

            if (transferDate === null) {
                info2.transfer_date = transferDate;
            } else {
                info2.transfer_date = format(transferDate, 'yyyy-MM-dd');
            }

            let info_enc2 = Apiconnect.encrypt_obj(info2);
            const inf_a2 = { enc: info_enc2,employee_id:employee_id };

            Apiconnect.postData("employee_info/update", inf_a2).then((response) => {
                  console.log(response);
                setInfo2({
                    employee_mst_id: '', notice_period: '',id:''
                });

                alert.success(response.data.message);
                history.push(`/employee_manage_employement/${emp_id}`);
            });
        } else {

            console.log('in crate function.....');
            info2.employee_mst_id = emp_id;

            if (confirmationDate === null) {
                info2.confirmation_date = confirmationDate;
            } else {
                info2.confirmation_date = format(confirmationDate, 'yyyy-MM-dd');
            }

            if (joiningDate === null) {
                info2.joining_date = joiningDate;
            } else {
                info2.joining_date = format(joiningDate, 'yyyy-MM-dd');
            }

            if (resignDate === null) {
                info2.resign_date = resignDate;
            } else {
                info2.resign_date = format(resignDate, 'yyyy-MM-dd');
            }

            if (leavingDate === null) {
                info2.leaving_date = leavingDate;
            } else {
                info2.leaving_date = format(leavingDate, 'yyyy-MM-dd');
            }

            if (promotionDate === null) {
                info2.promotion_date = promotionDate;
            } else {
                info2.promotion_date = format(promotionDate, 'yyyy-MM-dd');
            }

            if (transferDate === null) {
                info2.transfer_date = transferDate;
            } else {
                info2.transfer_date = format(transferDate, 'yyyy-MM-dd');
            }

            let info_enc2 = Apiconnect.encrypt_obj(info2);
            const inf_a2 = { enc: info_enc2,employee_id:employee_id };

            Apiconnect.postData("employee_info/create", inf_a2).then((response) => {
                setInfo2({
                    employee_mst_id: '', notice_period: '',id:''
                });

                alert.success(response.data.message);
                history.push(`/employee_manage_sal/${emp_id}`);
            });

        }



    };

    const [confirmationDate, setConfirmationDate] = useState(null);
    const [joiningDate, setJoiningDate] = useState(null);
    const [resignDate, setResignDate] = useState(null);
    const [leavingDate, setLeavingDate] = useState(null);
    const [promotionDate, setPromotionDate] = useState(null);
    const [transferDate, setTransferDate] = useState(null);


    useEffect(() => { getItem(); }, []);
    const getItem = () => {
        if (emp_id > 0) {
            Apiconnect.getData(`employee_info/get/${emp_id}`).then((response) => {
                let _xtract = Apiconnect.decrypt_obj(response.data.data);

               
                if (_xtract.length > 0) {

                    setInfo2({ notice_period: _xtract[0].notice_period,id: _xtract[0].id });

                    setBtnId({ btn_id: 1 });

                    setConfirmationDate(null);
                    if (_xtract[0].confirmation_date !== null && _xtract[0].confirmation_date !== '0000-00-00') {
                        setConfirmationDate(new Date(_xtract[0].confirmation_date));
                    }
                    setJoiningDate(null);
                    if (_xtract[0].joining_date !== null && _xtract[0].joining_date !== '0000-00-00') {
                        setJoiningDate(new Date(_xtract[0].joining_date));
                    }
                    setResignDate(null);
                    if (_xtract[0].resign_date !== null && _xtract[0].resign_date !== '0000-00-00') {
                        setResignDate(new Date(_xtract[0].resign_date));
                    }
                    setLeavingDate(null);
                    if (_xtract[0].leaving_date !== null && _xtract[0].leaving_date !== '0000-00-00') {
                        setLeavingDate(new Date(_xtract[0].leaving_date));
                    }
                    setPromotionDate(null);
                    if (_xtract[0].promotion_date !== null && _xtract[0].promotion_date !== '0000-00-00') {
                        setPromotionDate(new Date(_xtract[0].promotion_date));
                    }
                    setTransferDate(null);
                    if (_xtract[0].transfer_date !== null && _xtract[0].transfer_date !== '0000-00-00') {
                        setTransferDate(new Date(_xtract[0].transfer_date));
                    }
                } else {
                    setBtnId({ btn_id: null });
                }
            });
        }
    };

    //  if(confirmationDate !== null){
    //      var promo = confirmationDate;
    //      promo = new Date(promo.setMonth(promo.getMonth() + 3));

    //  }


    //   function refreshPage(){ 
    //     window.location.reload(); 
    //  }

    function nextPage() {
        history.push(`/employee_manage_sal/${emp_id}`);
    }
    function previousPage() {
        history.push(`/employee_manage_bank/${emp_id}`);
    }

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">Emplyement Info <span style={{ fontSize: '15px', color: 'blue' }}>Stage 4 to 6</span></h4>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <div className="bs-example">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage/${emp_id}`} >Personnel</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_info/${emp_id}`} >Other Details</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_bank/${emp_id}`} >Financial</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link active" data-toggle="tab" to={`/employee_manage_employement/${emp_id}`} >Employement Details</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" data-toggle="tab" to={`/employee_manage_reimbursement/${emp_id}`} >Reimbursement Info</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" data-toggle="tab" to={`/employee_manage_sal/${emp_id}`} >Salary (Monthly Wages)</Link>
                                    </li>
                                </ul>
                            </div>
                            <form onSubmit={e => onSubmit(e)}>

                                <div className="border">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Joining Date <span className="required">*</span></label>
                                                <DatePicker
                                                    selected={joiningDate}
                                                    onChange={e => setJoiningDate(e)}
                                                    className="form-control"
                                                    dateFormat='yyyy-MM-dd'
                                                    placeholderText="Joining Date"
                                                    name="joining_date"
                                                    required="required" />


                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Confirmation Date  </label>
                                                <DatePicker
                                                    selected={confirmationDate}
                                                    onChange={e => setConfirmationDate(e)}
                                                    className="form-control"
                                                    dateFormat='yyyy-MM-dd'
                                                    placeholderText="Confirmation Date"
                                                    name="confirmation_date"
                                                    minDate={joiningDate}
                                                />

                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Promotion Date</label>
                                                <DatePicker
                                                    selected={promotionDate}
                                                    onChange={e => setPromotionDate(e)}
                                                    className="form-control"
                                                    dateFormat='yyyy-MM-dd'
                                                    placeholderText="Promotion Date"
                                                    name="promotion_date"
                                                    minDate={confirmationDate} />
                                            </div>
                                        </div>


                                    </div>

                                    <div className="row">


                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Transfer Date</label>
                                                <DatePicker
                                                    selected={transferDate}
                                                    onChange={e => setTransferDate(e)}
                                                    className="form-control"
                                                    dateFormat='yyyy-MM-dd'
                                                    placeholderText="Transfer Date"
                                                    name="transfer_date"
                                                    minDate={confirmationDate} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Resignation Date</label>
                                                <DatePicker
                                                    selected={resignDate}
                                                    onChange={e => setResignDate(e)}
                                                    className="form-control"
                                                    dateFormat='yyyy-MM-dd'
                                                    placeholderText="Resignation Date"
                                                    name="resign_date"
                                                    minDate={confirmationDate} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Leaving Date</label>
                                                <DatePicker
                                                    selected={leavingDate}
                                                    onChange={e => setLeavingDate(e)}
                                                    className="form-control"
                                                    dateFormat='yyyy-MM-dd'
                                                    placeholderText="Leaving Date"
                                                    name="leaving_date"
                                                    minDate={resignDate} />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Notice Period <span className="required">*</span></label>
                                                <input type="text" required className="
                                 form-control validate[required]" name="notice_period" placeholder="Notice period" value={notice_period} onChange={e => onInputChange2(e)} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <button type="submit" name="id" value={btn_id} className="btn btn-primary mr-2"> {btn_id ? 'Update' : 'Submit'} </button>
                                        {/* <button type="button" className="btn iq-bg-danger" onClick={ refreshPage }> cancle </button>  */}
                                        <button type="button" className="btn btn-info mr-2" onClick={previousPage}> Previous </button>
                                        <button type="button" className="btn btn-success mr-2" onClick={nextPage}> Next </button>
                                        <Link style={{ float: 'right' }} className="btn btn-secondary" to="/employee_manage_list" >Back to List</Link>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )



}
export default Employee_manage_employement_info