import React, {useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import '../pages/pages.css';



const Shift_assign = (props) => {
   const currentUserId = props.match.params.id;
   let history = useHistory();
   const alert = useAlert();

   const [info, setInfo] = useState({company_id:Apiconnect.get_company_id(),department_id:currentUserId,shift_id:''});
   const {shift_id} = info;
   const [shiftList, setShift] = useState([]);

   useEffect(() => { getShift();}, []);

   const getShift = () => {
       Apiconnect.getData(`shift/getall/${Apiconnect.get_company_id()}`).then((response) => {        
           let _xtract = Apiconnect.decrypt_obj(response.data.data);      
           setShift(_xtract.reverse());
        });
   }

   const onInputChange = e =>{
    setInfo({...info,[e.currentTarget.name]: e.currentTarget.value});  
  };

  console.log(info);
 
      const onSubmit = async e => {
         e.preventDefault();
           
            console.log('in add function.....' );
            // info.cat_id = currentUserId;
            // console.log(info);
            let info_enc =  Apiconnect.encrypt_obj(info);
            const inf_a = { enc: info_enc };
            await Apiconnect.postData("company_category/shift_assign_create",inf_a).then((response) => { });
            alert.success('Data Insert Successfully');
       };

   return (
      <>
         <div id="content-page" className="content-page">
         <div className="container-fluid">

               <div className="row">


               <div className="col-sm-12 col-lg-6">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              {/* <h4 className="card-title">{id ? 'Category edit' : 'Category add'}</h4> */}
                              <h4 className="card-title">Shift Assign</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                              <form onSubmit={e => onSubmit(e)} >
                              <div className="row">

                              <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Employee Shift <span className="required">*</span></label>
                                            <select className="form-control" required name="shift_id" value={shift_id} onChange={e => onInputChange(e)}>
                                                <option value="">Select Shift</option>
                                                {
                                                    shiftList.map((val,key)=>{
                                                        return(
                                                            <option key={key} value={val.id}>{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                 <div className="col-md-12 mt-2">
                                 <button type="submit"  name="id" className="btn btn-primary mr-2">Submit</button>
                                 </div>
                              </div>
                              </form>
                        </div>
                     </div>
                  </div>
               </div>
         </div>
         </div>
      </>
   )
   }

export default Shift_assign