import React from 'react';
import "./ems_pages.css";


function Previous_attendance() {
    return (
        <>

            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Previous Attendance</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-1"></div>
                                            <div className="col-md-3">
                                                <div className="col-md-12">
                                                    <div className="form-group p_t_5">
                                                        <label>Offical Timing</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group p_t_5">
                                                        <label>Date</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group p_t_5">
                                                        <label>In Time</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group p_t_5">
                                                        <label>Out Time</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group p_t_5">
                                                        <label>Remark</label>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-4">
                                                <div class="col-md-12 p_t1_5">
                                                    <div class="form-group">
                                                        <input class="datepicker form-control" placeholder="To Date" id="to_date" name="to_date" data-date-format="mm/dd/yyyy" />
                                                    </div>
                                                </div>

                                                <div class="col-md-12 p_t1_5">
                                                    <div class="form-group">
                                                        <input class="datepicker form-control" placeholder="To Date" id="to_date" name="to_date" data-date-format="mm/dd/yyyy" />
                                                    </div>
                                                </div>

                                                <div class="col-md-12 p_t1_5">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" placeholder="To Date" id="to_date" name="to_date" />
                                                    </div>
                                                </div>

                                                <div class="col-md-12 p_t1_5">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" placeholder="To Date" id="to_date" name="to_date" />
                                                    </div>
                                                </div>

                                                <div class="col-md-12 p_t1_5">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control validate[required]" name="remark" value="" placeholder="Remark" autocomplete="off" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p_t_45_l_35">
                                            <div className="col-md-12 m-t-20">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                <button type="submit" className="btn iq-bg-danger">cancle</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default Previous_attendance
