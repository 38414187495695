import React, { useState, useEffect } from 'react';
import Apiconnect from "../services/Apiconnect.js";
import './public.css';
import Loadder from "./webview_lodder";


function Webview_salary_breakup(props) {
    let empid = props.match.params.id;
    let company_id = props.match.params.company_id;

    //////////USE EFFECT
    useEffect(() => {
        getDList();
        getdeductList();
        getreimbursementList();
    }, []
    );

    //const [earningList, setearningList] = useState([]);
    const [info, setInfo] = useState([]);
    const [deduct_head, setDeduct_head] = useState([]);
    const [reimbursement_head, setReimbursement_head] = useState([]);

    const getDList = () => {
        Apiconnect.getData(`employee_salary/getAllhead/${empid}/${company_id}`).then((response) => {
            console.log(response.data);
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setInfo(_xtract);
        });
    };

    const getdeductList = () => {
        Apiconnect.getData(`employee_salary/getDeducthead/${empid}`).then((response) => {
            console.log(response.data);
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setDeduct_head(_xtract);
        });
    };

    const getreimbursementList = () => {
        Apiconnect.getData(`employee_salary/getreimbursementhead/${empid}`).then((response) => {
            console.log(response.data);
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setReimbursement_head(_xtract);
        });
    };

    var tot = info.reduce((a, v) => a = a + v.amount, 0);
    var tot2 = deduct_head.reduce((a, v) => a = a + v.amount, 0);
    var tot3 = tot - tot2;
  

    return (
        <>
            <div id="content-page" className="" style={{ marginLeft: 'unset !important', overflow: 'hidden', background: '#e2e2e2', padding: '20px 8px', minHeight: '100vh' }}>
                <div className="container-fluid leave_div" style={{padding:'0px'}}>
                <div className="iq-card">
                  {/* <div className="iq-card-header d-flex justify-content-between">
                     <div className="iq-header-title">
                        <h4 className="card-title">Employee Salary Info</h4>
                     </div>
                  </div> */}
                  <div className="iq-card-body">
                     {/* <form onSubmit={e => submitForm(e)}> */}

                     <div className="" style={{padding: '18px'}}>
                        <div className="row">


                           <table className="table table-hover tbl" style={{border: '1px solid #a3a3a3'}}>
                              <thead>
                                 <tr>
                                    {/* <th width="120" scope="col">#</th> */}
                                    <th scope="col">Head</th>
                                    <th scope="col">Amount</th>
                                 </tr>
                              </thead>
                              <tbody>


                                 <tr>
                                    <td colSpan="2"style={{background: '#dee2e6'}}><h5>Earning Head</h5></td>
                                 </tr>
                                 {info.map((val, key) => {
                                    //  var earning = Number(earning)+Number(val.amount);
                                    //  console.log(earning);
                                    return (
                                       <tr key={key}>
                                          {/* <td>{key + 1}</td> */}
                                          <td>{val.name}  </td>
                                          <td>{'₹'+val.amount} </td>
                                       </tr>
                                    );
                                 })

                                 }
                                  <tr>
                                    <td colSpan=""><h6><b>Total Earning</b></h6></td>
                                    <td><b>{'₹'+tot}</b></td>
                                 </tr>


                                 <tr>
                                    <th colSpan="2" style={{background: '#dee2e6'}}><h5>Deduction Head</h5></th>
                                 </tr>

                                 {deduct_head.map((valx, keyx) => {
                                    return (
                                       <tr key={keyx}>
                                          {/* <td>{keyx + 1}</td> */}
                                          <td>{valx.name}  </td>
                                          <td>{'₹'+valx.amount} </td>
                                       </tr>
                                    );
                                 })}
                                  <tr>
                                    <td colSpan=""><h6><b>Total Deduction</b></h6></td>
                                    <td><b>{'₹'+tot2}</b></td>
                                 </tr>
                                 <tr>
                                    <td colSpan="1"><h6><b>Total Salary</b></h6></td>
                                    <td><h6><b>{'₹'+tot3}</b></h6></td>
                                 </tr>




                                 {/* <tr>
                                        <td colSpan="3"><h6>Reimbursement Head</h6></td>  
                                    </tr>

                                    {reimbursement_head.map((valy, keyy) => {
                                    return ( 
                                            <tr  key={keyy}>
                                                <td>{keyy+1}</td>
                                                <td>{valy.name}  </td>
                                                <td>{valy.amount} </td>
                                            </tr> 
                                        );
                                    })}                                                                    */}




                              </tbody>
                           </table>





                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-12">
                           <button type="submit" name="id" className="btn btn-primary btn-block" style={{background: '#4f4967',border: '#4f4958'}}>  Proceed </button>


                        </div>
                     </div>

                     {/* </form> */}
                  </div>
               </div>
                </div>
            </div>
        </>
    )
}

export default Webview_salary_breakup