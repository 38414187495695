import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import Header from './Header';
import TheContent from './TheContent';


export default class Profile extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        redirect: null,
        userReady: false,
        currentUser: { username: "" }
      };
    }  
  
    componentDidMount() {
      const currentUser = AuthService.getCurrentUser();
  
      if (!currentUser) this.setState({ redirect: "/login" });
        this.setState({ currentUser: currentUser, userReady: true })
      }
  
    render() {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />
      }
  
      const { currentUser } = this.state;

      
  
      return (
        <>
            <Header />
            <TheContent/>
        </> 
      );
    }
  }
  
