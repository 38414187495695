import React, {useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import '../pages/pages.css';



const Leaves_assign = (props) => {
   const currentUserId = props.match.params.id;
   let history = useHistory();
   const alert = useAlert();

   const [info, setInfo] = useState([]);
   const [leaveassign, setLeaveAssign] = useState([]);

   const onInputChange = e =>{

      if(e.target.getAttribute("data-id") === '1'){  
         console.log('t');
         var info1 = {cat_id:currentUserId,leave_id:e.currentTarget.value};
         let info_enc =  Apiconnect.encrypt_obj(info1);
         const inf_a = { enc: info_enc };
          Apiconnect.postData("company_category/delete_by_categoty",inf_a).then((response) => {});
      }else{
         setInfo({...info,[e.currentTarget.name]: e.currentTarget.value});  
      }
  };

   
    const [leavePolicyList, setLeavePolicy] = useState([]);

    useEffect(() => {
       const userId = currentUserId;
       getLeaveAssign(userId);geLeavesPolicy();
      }, []);

      const getLeaveAssign= (id) => { 
         // console.log(id);   
         Apiconnect.getData(`category_leave_policy/get_by_categoty/${id}`).then((response) => {        
         //   console.log(response.data.data);
           setLeaveAssign(response.data.data.reverse());
         });
       };
   
      const geLeavesPolicy = () => {    
         Apiconnect.getData(`company_leave_policy/getall/${Apiconnect.get_company_id()}`).then((response) => {        
            let _xtract = Apiconnect.decrypt_obj(response.data.data);    
            setLeavePolicy(_xtract.reverse());  
         });
          
       };
       
 
      const onSubmit = async e => {
         e.preventDefault();
           
            console.log('in add function.....' );
            info.cat_id = currentUserId;
            console.log(info);
            let info_enc =  Apiconnect.encrypt_obj(info);
            const inf_a = { enc: info_enc };
            await Apiconnect.postData("company_category/leave_policy_create",inf_a).then((response) => {
                  
            });
            alert.success('Data Update Successfully');
       };

   return (
      <>
         <div id="content-page" className="content-page">
         <div className="container-fluid">

               <div className="row">


               <div className="col-sm-12 col-lg-6">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              {/* <h4 className="card-title">{id ? 'Category edit' : 'Category add'}</h4> */}
                              <h4 className="card-title">Leaves Assign</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                              <form onSubmit={e => onSubmit(e)} >
                              <div className="row">
                                 {
                                      leavePolicyList.map((val,key)=>{

                                       var ck = '';
                                       var ck1 = 0;
                                       leaveassign.map((val1,key1)=>{
                                          if(val1.leave_policy_id === val.id){
                                              ck = 'true';
                                              ck1 = 1;
                                           }
                                       })

                                       return(
                                          <div key={key} className="col-md-3">
                                          <div className="checkbox">
                                             <label><input type="checkbox" name={val.id} data-id={ck1} defaultChecked={ck} value={val.id} onChange={e => onInputChange(e)}  /> {val.name}</label>
                                          </div>
                                          </div>
                                           
                                           )
                                      })


                                 }
                                 <div className="col-md-12 mt-2">
                                 <button type="submit"  name="id" className="btn btn-primary mr-2">Submit</button>
                                 </div>
                              </div>
                              </form>
                        </div>
                     </div>
                  </div>
               </div>
         </div>
         </div>
      </>
   )
   }

export default Leaves_assign