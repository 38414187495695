import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { Link } from 'react-router-dom';
import Apiconnect from "../../services/Apiconnect.js";
import moment from 'moment'
import { format } from 'date-fns';

const Employee_salary_list = () => {

   const alert = useAlert();
   let history = useHistory();

   useEffect(() => { getEmployeeList(); getWeekList(); getHolidayList(); getDList();
       getdeductList(); getSalaryHead();}, []);

   const [List, setEmployeeList] = useState([]);
   const [weekList, setWeekList] = useState([]);
   const [holidayList, setHolidayList] = useState([]);
   const [info, setInfo] = useState([]);
   const [deduct_head, setDeduct_head] = useState([]);
   const [salary_head, setSalary_head] = useState([]);
   const [formula, setFormula] = useState([]);

   const getFormulaList = (id) => {
      Apiconnect.getData(`employee_monthly_salary/getformulalogiclist/${id}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setFormula(_xtract);
      });

   }
   const getSalaryHead = () => {
      Apiconnect.getData(`employee_salary/getall`).then((response) => {
         // console.log(response.data);
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setSalary_head(_xtract);
      });
   };

   // console.log(List);
   //  console.log(salary_head);
   //  console.log(info);

   const getDList = () => {
      Apiconnect.getData(`earning_head/getall`).then((response) => {
         // console.log(response.data);
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setInfo(_xtract);
      });
   };

   const getdeductList = () => {
      Apiconnect.getData(`deduction_head/getall`).then((response) => {
         // console.log(response.data);
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setDeduct_head(_xtract);
      });
   };
   const getEmployeeList = () => {
      Apiconnect.getData("employee_monthly_salary/getallemployeesalarylist").then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setEmployeeList(_xtract.reverse());
         //  console.log(_xtract);
      });
   }

   ///// Holiday Count
   const getHolidayList = () => {
      Apiconnect.getData("employee_monthly_salary/holidaydatamonthwise").then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setHolidayList(_xtract.reverse());
      });
   };

   let holiday_count = 0;
   let holiday_cnt = 0;
   holidayList.map((val, key) => {
      var date1 = new Date(val.start_date1);
      var date2 = new Date(val.end_date1);
      var Difference_In_Time = date1.getTime() - date2.getTime();
      var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
      var Final_Result = Difference_In_Days.toFixed(0);
      holiday_count = Number(Final_Result) + 1;
      holiday_cnt = holiday_cnt + holiday_count;
   });

   ///////--------------->>>>Holiday Count END -----


   ///// Count Weekly Of 
   const getWeekList = () => {
      Apiconnect.getData("company_weekly_off/getall").then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setWeekList(_xtract.reverse());
      });
   };

   const week = [
      { id: "0", name: 'Sunday' },
      { id: "1", name: 'Monday' },
      { id: "2", name: 'Tuesday' },
      { id: "3", name: 'Wednesday' },
      { id: "4", name: 'Tuersday' },
      { id: "5", name: 'Friday' },
      { id: "6", name: 'Saterday' },
   ];

   let weekly_holiday_cnt = 0;
   weekList.map((val, key) => {
      var week_id = '';
      if (week[val.day_of_week].id == val.day_of_week) {
         var week_id = week[val.day_of_week].id;
      }
      var makeDate = new Date();
      var year = format(new Date(makeDate.getFullYear()), 'yyyy');
      var month = format(new Date(makeDate.getMonth() - 1), 'MM');
      var holiday = 0;

      if (val.holiday_typ === 'All') {
         var days = new Date(year, month, 0).getDate();
         var days_cnt = new Array();
         var d = new Date();
         for (var i = 1; i <= days; i++) {
            var newDate = new Date(d.getFullYear(), d.getMonth() - 1, i)
            if (newDate.getDay() == week_id) {
               days_cnt.push(i);
            }
         }
         holiday = days_cnt.length;
      } else {
         holiday = 1;
      }
      weekly_holiday_cnt = weekly_holiday_cnt + holiday;

   });

   ///////--------------->>>>Count Weekly Of END -----



   //// Change Paid Days
   const [salaryinfo, setSalaryInfo] = useState([]);
     const {present_days} = 0;
   const onInputChangePaidDays = (e,i) => {
      let ki = i.key;
      console.log(salaryinfo);
      const list = [...salaryinfo];
      list[ki] = e.currentTarget.value;
      setSalaryInfo(list);
    };
    ///////--------------->>>>Paid Days END -----
   

      ////EARNING
      const [erinfo, setErInfo] = useState([]);
  ///////--------------->>>>EARNING END -----

  //console.log(erinfo);
 

   const submitForm =  e => {
      e.preventDefault();  
      //console.log(e.currentTarget.name);                                      
   }


   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Employee Salary List</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                        <form onSubmit={e => submitForm(e)}>
                           <div className="table-responsive">
                              <table className="table mb-0 table-borderless">
                                 <thead>
                                    <tr>
                                       <th></th>
                                       <th></th>
                                       <th></th>
                                       <th></th>
                                       <th style={{ textAlign: 'center', borderLeft: '1px solid #12171d', borderRight: '1px solid #12171d' }} colSpan={info.length}>Credit</th>
                                       <th style={{ textAlign: 'center' }} colSpan={deduct_head.length}>Deduction</th>
                                       <th></th>
                                    </tr>
                                    <tr>
                                       <th>#</th>
                                       <th>Name</th>
                                       <th>Total Days</th>
                                       <th>Paid Days</th>
                                       {info.map((val, key) => {
                                          return (
                                             <th>{val.abbreviation}</th>
                                          );
                                       })
                                       }
                                       {deduct_head.map((val, key) => {
                                          return (
                                             <th>{val.abbreviation}</th>
                                          );
                                       })
                                       }
                                       <th>Salary</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {List.map((val, key) => {
                                       const er_data = [];

                                       var makeDate = new Date();
                                       var paid_day = 0
                                       if (val.paid_days !== null) {
                                          paid_day = val.paid_days;
                                       }

                                       if (paid_day !== 0) {
                                          paid_day = paid_day + holiday_cnt;
                                       }
                                      
                                       if(salaryinfo[key] !== undefined){
                                          paid_day =  salaryinfo[key];
                                       }


                                       var emp_tax_cnt = 0;
                                       var tax_cnt_deduction = 0;
                                       var mst_id = val.id;
                                       var months_days = moment(new Date(makeDate.getFullYear(), makeDate.getMonth() - 1), "YYYY-MM").daysInMonth();
                                       return (
                                          <tr key={key}>
                                             <th scope="row">{key + 1}</th>
                                             {/* <td>{val.emp_code}</td> */}
                                             <td>{val.first_name} {val.last_name}</td>
                                             <td><input type="text" placeholder={months_days} name="office_days" style={{ width: '100px' }} value='' readOnly /></td>
                                             <td><input type="text" onChange={e => onInputChangePaidDays(e,{key,mst_id})} name='present_days' style={{ width: '100px' }} placeholder={paid_day} value={present_days} /></td>
                                             {
                                                info.map((eh_val, eh_key) => {
                                                   const empid = [];
                                                   const tax = [];
                                                   const er_head_id = [];
                                                   salary_head.map(val => {
                                                      if (empid.indexOf(val.emp_mst_id) === -1) {
                                                         empid.push(val.emp_mst_id)
                                                      }
                                                   });
                                                   salary_head.map(val => {
                                                      if (er_head_id.indexOf(val.head_id) === -1) {
                                                         if(val.typ === 'earning'){
                                                            er_head_id.push(val.head_id);
                                                         }
                                                      }
                                                   });

                                                   

                                                   if (empid.includes(val.id) && er_head_id.includes(eh_val.id)) {
                                                      return (
                                                         salary_head.map((val1, key1) => {
                                                            if (val1.head_id == eh_val.id && val1.typ == 'earning' && val1.emp_mst_id == val.id) {
                                                               tax[val1.id] = 0;
                                                               if (val1.formula_id !== null) {
                                                                  // console.log(val.id);
                                                                  getFormulaList(val1.formula_id);

                                                                  if(salaryinfo[key] !== undefined && salaryinfo[key] !== '' && salaryinfo[key] !== 0){
                                                                     var amount = Math.round(Number(val1.amount)/months_days*salaryinfo[key]);
                                                                  }else{
                                                                     var amount = val1.amount;
                                                                  }

                                                                  //var amount = val1.amount;
                                                                 
                                                                 formula.map((v, k) => {
                                                                  var if_con = v.if_condition;
                                                                  var chk = v.check_value;
                                                                  var opr = v.f_operator;
                                                            
                                                                  var out =0;
                                                                  var ifcon_o = amount;
                                                                 
                                                                  if(opr=='BETWEEN'){ 
                                                                      var rng = chk.split("-");                   
                                                                      var ifcon_out = ifcon_o +'>='+ rng[0]+ ' && ' + ifcon_o +'<='+ rng[1];
                                                                  }else{
                                                                      var ifcon_out =   ifcon_o+opr+chk;
                                                                  }
                                                                  if(eval(ifcon_out)){
                                                                      out = v.f_then;
                                                                  }
                                                                  if(out !== 0){
                                                                    tax[val1.id] = out;
                                                                  }
                                                               })
                                                               }

                                                               if(salaryinfo[key] !== undefined && salaryinfo[key] !== '' && salaryinfo[key] !== 0){
                                                                var er_amount = Number(tax[val1.id])+Math.round(Number(val1.amount)/months_days*salaryinfo[key]);
                                                               }else{
                                                                  var er_amount = Number(tax[val1.id])+Number(val1.amount);
                                                               }

                                                               emp_tax_cnt = emp_tax_cnt + er_amount;
                                                               //console.log(er_amount+' -- '+amount);
                                                               const ob = {'emp_id':val.id,'head_id':eh_val.id,'amount':er_amount};
                                                                er_data.push(ob);
                                                               //  const list = [...erinfo];
                                                               //  list[key] = er_data;
                                                              //  setErInfo(er_data);
                                                               return (
                                                                  <td key={key1}><input type="text" name="er_amount" placeholder={Number(tax[val1.id])+Number(val1.amount)} style={{ width: '100px' }} value={er_amount} /></td>
                                                               )
                                                            }
                                                           
                                                         })
                                                      )
                                                   } else {
                                                      var er_amount = 0;
                                                      const ob = {'emp_id':val.id,'head_id':eh_val.id,'amount':er_amount};
                                                      er_data.push(ob);
                                                      return (
                                                         <td key={eh_key}><input type="text" name="er_amount"  style={{ width: '100px' }} placeholder="0" value={er_amount} /></td>
                                                      )
                                                   }
                                                   
                                                })
                                               
                                             }
                                             

                                             {
                                                deduct_head.map((dh_val, dh_key) => {
                                                   const empid = [];
                                                   const tax = [];
                                                   const d_head_ids = [];
                                                   salary_head.map(val => {
                                                      if (empid.indexOf(val.emp_mst_id) === -1) {
                                                         empid.push(val.emp_mst_id)
                                                      }
                                                   });
                                                   salary_head.map(val => {
                                                      if (d_head_ids.indexOf(val.head_id) === -1) {
                                                         if(val.typ === 'deduction'){
                                                            d_head_ids.push(val.head_id);
                                                         }
                                                      }
                                                   });
                                                   const dd_data = [];
                                                   if (empid.includes(val.id) && d_head_ids.includes(dh_val.id)) {
                                                      return (
                                                         salary_head.map((val1, key1) => {
                                                            if (val1.head_id == dh_val.id && val1.typ == 'deduction' && val1.emp_mst_id == val.id) {
                                                               tax[val1.id] = 0;
                                                               if (val1.formula_id !== null) {
                                                                  // console.log(val.id);
                                                                  getFormulaList(val1.formula_id);

                                                                  if(salaryinfo[key] !== undefined && salaryinfo[key] !== '' && salaryinfo[key] !== 0){
                                                                     var amount = Math.round(Number(val1.amount)/months_days*salaryinfo[key]);
                                                                  }else{
                                                                     var amount = val1.amount;
                                                                  }
                                                                 
                                                                 formula.map((v, k) => {
                                                                  var if_con = v.if_condition;
                                                                  var chk = v.check_value;
                                                                  var opr = v.f_operator;
                                                            
                                                                  var out =0;
                                                                  var ifcon_o = amount;
                                                                 
                                                                  if(opr=='BETWEEN'){ 
                                                                      var rng = chk.split("-");                   
                                                                      var ifcon_out = ifcon_o +'>='+ rng[0]+ ' && ' + ifcon_o +'<='+ rng[1];
                                                                  }else{
                                                                      var ifcon_out =   ifcon_o+opr+chk;
                                                                  }
                                                                  if(eval(ifcon_out)){
                                                                      out = v.f_then;
                                                                  }
                                                                  if(out !== 0){
                                                                    tax[val1.id] = out;
                                                                  }
                                                               })
                                                               }
                                                               if(salaryinfo[key] !== undefined && salaryinfo[key] !== '' && salaryinfo[key] !== 0){
                                                                  var dd_amount = Number(tax[val1.id])+Math.round(Number(val1.amount)/months_days*salaryinfo[key]);
                                                                 }else{
                                                                    var dd_amount = Number(tax[val1.id])+Number(val1.amount);
                                                                 }
                                                               tax_cnt_deduction = tax_cnt_deduction+dd_amount;
                                                               const ob = {'emp_id':val.id,'head_id':dh_val.id,'amount':dd_amount};
                                                               dd_data.push(ob);
                                                               console.log(dd_data);
                                                               return (
                                                                  <td key={key1}><input type="text" placeholder={Number(tax[val1.id])+Number(val1.amount)} name='d' style={{ width: '100px' }} value={dd_amount} /></td>
                                                               )
                                                            }
                                                         })
                                                      )
                                                   } else {
                                                      var dd_amount = 0;
                                                      const ob = {'emp_id':val.id,'head_id':dh_val.id,'amount':dd_amount};
                                                               dd_data.push(ob);
                                                      return (
                                                         <td key={dh_key}><input type="text" placeholder="0" name='d' style={{ width: '100px' }}  value={dd_amount} /></td>
                                                      )
                                                   }

                                                  
                                                })
                                               
                                             }
                                            
                                             <td><input type="text" style={{ width: '100px' }} value={Number(emp_tax_cnt) - Number(tax_cnt_deduction)} readOnly /></td>
                                          </tr>
                                          
                                       
                                       );
                                    })}
                                    

                                   
                                 </tbody>
                              </table>
                           </div>
                           <div className="row">
                                    <div className="col-md-12 mt-4">
                                    <button type="submit"  name=""   className="btn btn-primary mr-2">Submit</button>
                                    </div>
                                </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>


         </div>

      </>
   )
}

export default Employee_salary_list