import React, { useState, useEffect, useCallback } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import '../pages/uploadfile.css';
import Axios from 'axios';
import Cropper from 'react-easy-crop'
import Webcam from "react-webcam";


const Employee_image_upload = () => {

  const alert = useAlert();

  const localuser = JSON.parse(localStorage.getItem('user'));
  let employee_id = Apiconnect.decrypt(localuser.id);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0)
  const [image, setImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState('http://100dayscss.com/codepen/upload.svg');
  const [saveCroppedImage, setsaveCroppedImage] = useState(null);

  // onCropChange = crop => {
  //     this.setState({ crop })
  //   }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])


  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )
      //   console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)
      //   setImage(croppedImage);

    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])


  const createImage = url =>
    new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', error => reject(error))
      image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = url
    })

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
  }


  async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc)

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )


    const blob = DataURLtoBlob(canvas.toDataURL('image/jpeg'))
    let file = new File([blob], employee_id + '.png', {
      lastModified: new Date(),
      type: "image/png"
    })


    // As Base64 string
    // return canvas.toDataURL('image/jpeg');
    setsaveCroppedImage(file);
    //   const reader = new FileReader();
    //     console.log(canvas.toDataURL('image/jpeg'))
    // As a blob
    return new Promise(resolve => {
      canvas.toBlob(file => {

        resolve(URL.createObjectURL(file))
      }, 'image/jpeg')
    })
  }

  function DataURLtoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

  }

  const handleInputChange = e => {

    const file = e.target.files[0]
    const reader = new FileReader()

    reader.addEventListener('load', () => {
      setImage(reader.result);
    }, false)

    if (file) {
      reader.readAsDataURL(file)
    }



  }

  const submit = e => {
     
      const data = new FormData();
      data.append('thumbdp', saveCroppedImage);     
      data.append('emp_id', employee_id);

        console.log(data);
        console.log('--->>>>-----');
        console.log(saveCroppedImage);
      

      Apiconnect.postData(`employee_info/uploadplaindp`, data).then((response) => {
        alert.success(response.data.message)
        console.log(response.data);
      });
  }

  const submitattendance = e => {
     
    const data = new FormData();
    data.append('thumbrequest', saveCroppedImage);     
    data.append('emp_id', employee_id);

      console.log(data);
      console.log('--->>>>-----');
      console.log(saveCroppedImage);
    

    Apiconnect.postData(`employee_info/uploadcsvemployee`, data).then((response) => {
      alert.success(response.data.message)
      console.log(response.data);
    });
}

  const [submitvalue, setSubmitValue1] = useState(1)
  const setSubmitvalue = (val) =>{
    setSubmitValue1(val);
  }


  const show1 = (e) =>{
    document.getElementById('camera').style.display ='';
    document.getElementById('file').style.display ='none';
  }
  const show2 = (e) =>{
    document.getElementById('file').style.display ='';
    document.getElementById('camera').style.display ='none';
  }

  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

  const handleClick = React.useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);

  const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: FACING_MODE_USER
  };

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
     
      const imageSrc = webcamRef.current.getScreenshot();
      console.log(imageSrc);
      // setImage1(imageSrc)
      setImage(imageSrc)
    },

    [webcamRef]
  );

  return (



    <div id="content-page" className="content-page">
      <div className="container-fluid">
        <div className="row">

          <div className="col-sm-9">
            <div class="btn-container">

            <div className="row" style={{ paddingTop: "10px" }}>
            <div className="col-sm-1">
                </div>
                <div className="col-sm-5 col-md-5 col-12">
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-primary active text-white">
                      <input type="radio" name="options" id="option1" autocomplete="off" defaultChecked onClick={(e)=>setSubmitvalue(1)} /> For Profile
                    </label>
                    <label class="btn btn-primary text-white">
                      <input type="radio" name="options" id="option2" autocomplete="off" onClick={(e)=>setSubmitvalue(2)}/> For Attendance
                    </label>
                  </div>
             </div>
             <div className="col-sm-5 col-md-5 col-12">
              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-info active text-white">
                  <input type="radio" name="options1" id="option1" autocomplete="off" defaultChecked onClick={(e)=>show2(e)} /> Click To Gallery
                </label>
                <label class="btn btn-info text-white">
                  <input type="radio" name="options1" id="option2" autocomplete="off" onClick={(e)=>show1(e)}/> Click To Camera
                </label>
              </div>
            </div>
            </div>

              <div class="dropzone">

                {/* <div className="row" style={{ margin: ' 0 10px 25px 10px' }}> */}
                  {/* <input type="file" accept="image/*" id="file" className="form-control" name="csvfile" onChange={e => handleInputChange(e)} /> */}
              
                  <div id="file">
                   
                   <div className="row" style={{ margin: " 0 10px 25px 10px" }}>
                     <input
                       type="file"
                       accept="image/*"
                       id="file"
                       className="form-control"
                       name="csvfile"
                       onChange={(e) => handleInputChange(e)}
                     />
                   </div>
                   </div>
                   <div id="camera" style={{display:'none'}}>
                  <div className="webcam-container">
                  <div className="webcam-img">
                  {image=='' || image == null?<Webcam
                    audio={false}
                    height={200}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={220}
                    videoConstraints={{
                      ...videoConstraints,
                      facingMode
                    }}
                  />
                  :<img src={image}/>}
                    </div>

                    <div style={{margin:'5px'}}>
                  {image!='' && image != null?
                  <button onClick={(e)=>
                  {
                  e.preventDefault();
                  setImage('')
                  }}
                  className="webcam-btn btn btn-danger">
                  Retake Image</button>:
                  <>
                  <button onClick={(e)=>{
                  e.preventDefault();
                  capture();
                  }}
                  className="webcam-btn btn btn-primary" >Capture</button>
                  <button style={{marginLeft:'5px'}} className="webcam-btn btn btn-info" onClick={(e)=>handleClick(e)}><i class="fa fa-camera"></i></button>
                  </>
                  }
                  </div>
                </div>   
            </div>
                {/* </div> */}
              </div>



              <div className="row">
                <div className="col-sm-6">
                  <Cropper style={{ borderRadius: '10px', marginBottom: '10px', width: '98%' }}
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="col-sm-6">
                  <img src={croppedImage} alt="Logo" style={{ width: '98%', borderRadius: '10px', marginBottom: '3px' }} />
                </div>
              </div>

              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col-sm-6" style={{ textAlign: 'center' }}>
                  <button type="button" id="btnup" class="btn btn-info " onClick={showCroppedImage}>Crop Image</button>
                </div>
               
                <div className="col-sm-6" style={{ textAlign: 'center' }}>
                {(submitvalue == 1)?
                  <button type="button" id="btnup" class="btn btn-primary " onClick={(e) => submit(e)}>Submit</button>
                  :
                  <button type="button" id="btnup" class="btn btn-primary " onClick={(e) => submitattendance(e)}>Submit</button>
                }
                </div>
              </div>



            </div>
          </div>
        </div>



        {/* <div className="row">
                    <div className="col-sm-6">
                        <div class="btn-container">
                            <div class="dropzone">
                               
                                 <img src={croppedImage} alt="Logo" style={{width:'35%',borderRadius: '10px',marginBottom: '10px'}} />
                                <div className="row" style={{ margin: ' 0 10px 25px 10px' }}>
                                    <input type="file" accept="image/*" id="file" className="form-control" name="csvfile" onChange={e => handleInputChange(e)} />
                                </div>
                            </div>
                            <button style={{float:'right',marginRight: '20px'}} type="button" id="btnup" class="btn btn-info " onClick={showCroppedImage}>Crop Image</button>
                            <button style={{float:'right', marginRight: '5px'}} type="button" id="btnup" class="btn btn-primary " onClick={(e) => submit(e)}>Submit</button>
                        </div>
                    </div>
                    <div className="col-sm-6">
                   
                        <Cropper
                            image={image}
                            crop={crop}
                            zoom={zoom}
                            aspect={4 / 3}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>
                </div> */}
      </div>
    </div>

  )
}

export default Employee_image_upload