import React, { useState,useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import '../pages/pages.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



const Perk_head = () => {

   const alert = useAlert();

   let history = useHistory();
   

   const [info, setInfo] = useState({
      name: "",
      abbreviation: "",
      accounting_code: "",
      round_of_method: "",
      round_to: "",
      id: null,
    });
    const { name,abbreviation,accounting_code,round_of_method,round_to,id } = info;
    const onInputChange = e => {
      setInfo({ ...info, [e.target.name]: e.target.value });
    };

   

    const onSubmit = async e => {
      e.preventDefault();

      if(id>0){
         console.log('in edit function' +id);
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
         Apiconnect.postData(`perk/update`, inf_a).then((response) => {
            history.push("/perk_head"); 
            setInfo({  name: "",abbreviation: "",accounting_code: "",round_of_method: "",round_to: "",id: ''});
            getPerks();
            alert.success('Perk updated successfully.');  
         });
      }else{
         console.log('in add function' );
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
         await Apiconnect.postData("perk/create", inf_a);
         history.push("/perk_head"); 
         setInfo({ name: "",abbreviation: "",accounting_code: "",round_of_method: "",round_to: "",id: ''});
         getPerks();
         alert.success('Perk added successfully.');  
         
        
      }
      
    };

    const getItem = (id) => {
      Apiconnect.getData(`perk/get/${id}`).then((response) => {
         console.log(response.data.data[0]);   
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setInfo({ name: _xtract[0].name, abbreviation: _xtract[0].abbreviation,accounting_code: _xtract[0].accounting_code,round_of_method: _xtract[0].round_of_method,
            round_to: _xtract[0].round_to,id: _xtract[0].id });
         
      });
    };

    
 function refreshPage(){ 
  window.location.reload(); 
}

const ondlt = (id) => {
   confirmAlert({
     title: 'Are you sure to Delete?',
     buttons: [
       {
         label: 'Yes',
         onClick: () => deleteItem(id), 
       },
       {
         label: 'No',
       }
     ]
   });
 };

     const deleteItem = (id) => {
      Apiconnect.deleteData(`perk/delete/${id}`).then((response) => {
         history.push("/perk_head"); 
         alert.success('Perk Deleted successfully.');  
         getPerks();
      });
    };

    
    useEffect(() => {getPerks();}, []);
    const [perkList, setPerkList] = useState([]);
    const getPerks = () => {
      Apiconnect.getData("perk/getall").then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data); 
         setPerkList(_xtract.reverse());
       });
     };

     
     const [viewList,setViewList] = useState({
         name1: "",
         abbreviation1: "",
         accounting_code1: "",
         round_of_method1: "",
         round_to1: "",
     });
     const { name1,abbreviation1,accounting_code1,round_of_method1,round_to1 } = viewList;

     const viewItem = (id)=>{
      setModalIsOpen(true);

      Apiconnect.getData(`perk/get/${id}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setViewList({ name1: _xtract[0].name, abbreviation1: _xtract[0].abbreviation,accounting_code1: _xtract[0].accounting_code,round_of_method1: _xtract[0].round_of_method,
            round_to1: _xtract[0].round_to,id: _xtract[0].id });
      });
   }
   
   const customStyles = {
      content: {
         top: '50%', width: '40%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
      },
   };

   let subtitle;
   const [modalIsOpen, setModalIsOpen] = useState(false);

   return (
      <>

<div id="content-page" className="content-page">
         <div className="container-fluid">

               <div className="row">

               <div>

<Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
   <div className="iq-card">
      <div className="iq-card-header d-flex justify-content-between model_head">
         <div className="iq-header-title">
            <h4 className="card-title ">Perk View</h4>
         </div>
      </div>

      <div className="iq-card-body">
         <div className="row">
            <div className="col-md-6">
            <div className="form-group">
                  <span><b>Perk Name : </b></span>
                  <span> {name1}</span>
            </div>
            </div>

            <div className="col-md-6">
            <div className="form-group">
                  <span><b>Abbreviation : </b></span>
                  <span>{abbreviation1}</span>
               </div>
            </div>
            <div className="col-md-6">
            <div className="form-group">
                  <span><b>Accounting Code : </b></span>
                  <span>{accounting_code1}</span>
            </div>
            </div>


            <div className="col-md-6">
               <div className="form-group">
                  <span><b>Method Of Rounding Off : </b></span>
                  <span>{round_of_method1}</span>
               </div>
            </div>

            <div className="col-md-6">
               <div className="form-group">
               <span><b>Amount Round Off To : </b></span>
                  <span>{round_to1}</span>
               </div>
            </div>
         </div>
      </div>
      <div className="text_center">
      <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
      </div>
   </div>
</Modal>
</div>

                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card" style={{padding:'20px',paddingLeft:'80px',paddingRight:'80px'}}>
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Perk Add</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                              <form onSubmit={e => onSubmit(e)}>
                              <div className="row">
                                 
                                
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label>Perk Head Name <strong className="required"> *</strong></label>
                                          <input type="text" className="form-control validate[required]" name="name" 
                                           value={name}
                                           onChange={e => onInputChange(e)}  
                                          placeholder="Perk Head Name" required />
                                       </div>
                                    </div>
                                    

                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label>Abbrevition</label>
                                          <input className="form-control" placeholder="Abbrevition" id="" 
                                          name="abbreviation" 
                                          value={abbreviation}
                                          onChange={e => onInputChange(e)} />
                                       </div>
                                    </div>

                                   
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label>Accounting Code</label>
                                          <input className="form-control" placeholder="Abbrevition Code" id="" 
                                          name="accounting_code" 
                                          value={accounting_code}
                                          onChange={e => onInputChange(e)} />
                                       </div>
                                    </div>
                                  
                                

                                
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label>Method Of Rounding Off</label>
                                          <input className="form-control" placeholder="Method Of Rounding Off" name="round_of_method"
                                           value={round_of_method}
                                           onChange={e => onInputChange(e)} />
                                       </div>
                                    </div>
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label>Amount Round Off To</label>
                                          <input className="form-control" placeholder="Amount Round Off To" id="" name="round_to"
                                           value={round_to}
                                           onChange={e => onInputChange(e)}  />
                                       </div>
                                    </div>
                                 

                                    <div className="col-md-12 m-t-20">
                                    <button type="submit"  name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    {(() => {
                                       if (id>0) {
                                          return (
                                             <button type="button" className="btn" onClick={ refreshPage }> Return </button> 
                                          )
                                       } 
                                    })()}     
                                 </div>
                              </div>



                              </form>
                        </div>
                     </div>
                  </div>

                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card" style={{padding:'20px',paddingLeft:'50px',paddingRight:'40px'}}>
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List Of Perk</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                        <table className="table table-striped table-hover">
                        <thead className="">
                           <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Abbrevition</th>
                              <th scope="col">Code</th>
                              <th scope="col">Method Of Rounding Off</th>
                              <th scope="col">Amount Round Off To</th>
                              <th scope="col">Action</th>
                           </tr>
                        </thead>
                        <tbody className="">
                        {perkList.map((val, key) => { 
                            return (
                              <tr key={key}>
                                 <th scope="row">{key+1}</th>
                                 <td>{val.name}</td>
                                 <td>{val.abbreviation}</td>
                                 <td>{val.accounting_code}</td>
                                 <td>{val.round_of_method}</td>
                                 <td>{val.round_to}</td>
                                 <td>
                                 <button onClick={() => {ondlt(val.id);}}
 className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
 
                                  <button onClick={() => {getItem(val.id);}}
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                             
                                  <button onClick={() => viewItem(val.id)}
                                                className="btn btn-info mr-2"><i className="fa fa-eye" aria-hidden="true"></i></button>
                                 
                                 </td>
                              </tr> 
                           );
                        })}               
                         
                        </tbody>
                     </table>
                        </div>
                     </div>
                  </div>
               </div>
         </div>
         </div>


     
      </>
   )
}

export default Perk_head