import React, { useState, useEffect } from 'react';
import Apiconnect from "../services/Apiconnect.js";
import { useHistory } from "react-router-dom";
import './public.css';
import Loadder from "./webview_lodder";


function Webview_salary_slip(props) {

    let emp = props.match.params.id;
    let history = useHistory();

   
    const [salaryMonths, setSallaryData] = useState([]);
    const [crnt_year, set_crnt_year] = useState(new Date().getFullYear());

    const yr = [new Date().getFullYear()-1,new Date().getFullYear()];

    useEffect(() => { getSalarySlipData(crnt_year); }, []);

    const getSalarySlipData = (crnt_year) => {
        Apiconnect.getData(`employee_monthly_salary/getSalarySlipYearWise/${emp}/${crnt_year}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setSallaryData(_xtract.reverse());
        });

    };

    const viewslip = (year) =>{
        set_crnt_year(year);
        getSalarySlipData(year)
    }

    const viewItem = (month, year, isslip) => {
        if (isslip == 1) {
            history.push(`/salaryslippdf/${emp}/${year}/${month}`);
        }
    }

    const months = {
        1: 'JAN', 2: 'FEB', 3: 'MAR', 4: 'APR', 5: 'MAY',
        6: 'JUN', 7: 'JUL', 8: 'AUG', 9: 'SEP', 10: 'OCT', 11: 'NOV', 12: 'DEC'
    };

    const listsalaary = [];
    Object.keys(months).forEach(function (key, val) {

        var slry = salaryMonths.find(item => key == item.month);
        if (slry != undefined) {
            slry.slip = 1;
        } else {
            slry = {};
            slry.month = key;
            slry.year = '';
            slry.slip = 0;
        }
        listsalaary.push(slry);
    });



    return (
        <>
            <div id="content-page" className="" style={{ marginLeft: 'unset !important', overflow: 'hidden', background: '#cccccc', padding: '20px 0px', minHeight: '100vh' }}>
                <div className="container-fluid leave_div">
                    <div className="row" id="page">
                        <div className="col-sm-12 ">
                            <div className="iq-card">
                              
                                <div className="iq-card-header justify-content-between">
                                <div className="iq-header-title" style={{paddingTop:'5px'}}>
                                    <h3 className="card-title" style={{paddingTop: '5px'}}>Salary Slip</h3>
                                </div>
                                  <div style={{ fontSize: '20px',float: 'right' }}>
                                        <ul className="list-group list-group-horizontal">
                                           
                                          {
                                            yr.map((val)=>{
                                                var ul = 'list-group-item';
                                                if(crnt_year == val){
                                                    var ul = 'list-group-item ul ulbg';
                                                }
                                                return (
                                                    <li onClick={() => viewslip(val)} className={ul} style={{ border:'unset !important'}}><a href="#">{val}</a></li>
                                                )
                                            })
                                          }
                                        </ul>
                                    </div>


                            </div>


                                <div className="iq-card-body">

                                    <ul className="media-story row m-0 p-0">


                                        {
                                            listsalaary.map((value, key) => {

                                                var bg = { border: '8px solid', height:140, width:'40%', borderRadius: '100px',  padding: '8px', overflow:'hidden', marginRight:25,  };
                                                if (value.slip == 0) {
                                                    var bg = { opacity: 0.4, border: '8px solid',  width:'40%', height:140, borderRadius: '100px', overflow:'hidden',   marginRight:25, padding: '8px', };
                                                }
                                                return (
                                                    <li key={key} className=" mb-4    active" style={bg}>
                                                        <div className="stories-data ml-3 row"  >
                                                            <div className="col-md-12" style={{ textAlign: 'center', background:'#ccc',  paddingTop:35, marginLeft:-26, marginTop:-32, minWidth:130,  }}><h3> {months[value.month]} </h3></div>
                                                            <div className="col-md-12" style={{ textAlign: 'center', paddingTop: '7px', marginLeft:-17, }}>
                                                                <span className="btn btn-success btn-sm">
                                                                    <i style={{ fontSize: '20px', paddingLeft: '10px', paddingRight: '10px' }} className="fa fa-download" aria-hidden="true" onClick={() => viewItem(value.month, value.year, value.slip)}></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    {/* <li key={key} className="d-flex mb-4  active" style={bg}>
                                                    <div className="stories-data ml-3 row" style={{ display: 'contents' }}>
                                                        <div className="col-md-10"><b>Salary Slip For {months[value.month]} </b></div>
                                                        <div className="" style={{ textAlign: 'right' }}><i style={{ fontSize: '20px', paddingLeft: '10px', paddingRight: '10px' }} className="fa fa-download" aria-hidden="true" onClick={() => viewItem(value.month, value.year,value.slip)}>Download</i></div>
                                                    </div>
                                                </li> */}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}


export default Webview_salary_slip