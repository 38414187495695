import React from 'react';

const InputTextField = ({name, value, placeholder,required, _handleChange}) =>(

<>
    <input  type="text" name={name}  value={value}  className="form-control" placeholder={placeholder} onChange={_handleChange} />
</>



);
export default InputTextField;