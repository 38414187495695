import React from 'react';

function Reject_attendance() {
    return (
<>
<div id="content-page" className="content-page">
             <div className="container-fluid">

                  
                <div className="row">
                     <div className="col-sm-12">
                        <div className="iq-card">
                          <div>
                          <h4 className="card-title hd" >Reject Attendance</h4>
                     </div>
                     <div className="iq-card-body">

                         <div className="row">

                             <div className="table-responsive">
                           
                           <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                             <thead>
                                 <tr className="clr">
                                    <th>Emp. Name</th>
                                    <th>Emp. Code</th>
                                    <th>In Time</th>
                                    <th>Out Time</th>
                                    <th>Remarks</th>
                                    <th>Senctioned</th>
                                    <th>Save</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr>
                                    <td></td>
                                    <td></td>
                                     <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><select><option>Select</option></select></td>
                                    <td><button>save</button></td>
                                 </tr> 
                             </tbody>
                           </table>
                        </div>

                         </div>

                     </div>
                  </div>
                  </div>
                </div>

          </div>
        </div>

       
          
</>
    )
}

export default Reject_attendance
