import React, { Component } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { withAlert } from 'react-alert';
import { useAlert } from "react-alert";
import '../pages/admin.css';
import { Link } from 'react-router-dom';

class Employee_manage_sal extends Component {
    constructor(props) {
        super(props);
        this.state = { earning_head: [], emp_info: '', deduct_head: [], reimbursement_head: [], emp_salary_template: [], emp_salary_template_deduction: [] };

        this.get_earning_head();
        this.getdeductList();
        // this.getreimbursementList();
        this.getEmpTemplateList();
        this.getEmpTemplateDeductionList();


    }

    getEmpTemplateList = () => {
        var emp_id = this.props.match.params.id;
        Apiconnect.getData(`employee_salary_template/getEmployeeTemplateById/${emp_id}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            console.log('---...>>>>-----');
            console.log(_xtract);
            console.log('---...>>>>-----');
            this.setState({ emp_salary_template: _xtract });


        });
    }

    getEmpTemplateDeductionList = () => {
        var emp_id = this.props.match.params.id;
        Apiconnect.getData(`employee_salary_template/getEmployeeTemplateDeductionById/${emp_id}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            this.setState({ emp_salary_template_deduction: _xtract });
        });
    }





    get_earning_head = () => {
        var empid = this.props.match.params.id;
        var company_id = Apiconnect.get_company_id();
        Apiconnect.getData(`employee_salary/getAllhead/${empid}/${company_id}`).then((response) => {
            let ern = Apiconnect.decrypt_obj(response.data.data);
            this.setState({ earning_head: ern });
        });
    };
    getdeductList = () => {
        var empid = this.props.match.params.id;
        Apiconnect.getData(`employee_salary/getDeducthead/${empid}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            this.setState({ deduct_head: _xtract });

        });
    };
    // getreimbursementList = () => {
    //     var empid = this.props.match.params.id;
    //     Apiconnect.getData(`employee_salary/getreimbursementhead/${empid}`).then((response) => {
    //         let _xtract = Apiconnect.decrypt_obj(response.data.data);
    //         this.setState({ reimbursement_head: _xtract });
    //     });
    // };



    onInputChange = (e, i) => {
        let ki = i.key;
        let markers = this.state.earning_head;
        markers[ki] = { ...markers[ki], amount: e.currentTarget.value };
        this.setState({ earning_head: markers });

        let ddx = this.state.deduct_head;
        ddx.map((vk, kk) => {
            if (vk.formula_id > 0) {
                this.formula_man(kk, vk.formula_id);
            }
        })


        let ernz = this.state.earning_head;
        ernz.map((vkx, kkx) => {
            if (vkx.formula_id > 0) {
                this.formula_man_er(kkx, vkx.formula_id);
            }
        })


    };
    onInputChange_deduct = (e, i) => {
        let ki = i.keyx;
        let markers = this.state.deduct_head;
        markers[ki] = { ...markers[ki], amount: e.currentTarget.value };
        this.setState({ deduct_head: markers });
    };
    // onInputChange_remb = (e, i) => {
    //     let ki = i.keyy;
    //     let markers = this.state.reimbursement_head;
    //     markers[ki] = { ...markers[ki], amount: e.currentTarget.value };
    //     this.setState({ reimbursement_head: markers });
    // };






    changeFormula = (e, i) => {
        let ki = i.keyx;
        let va = e.target.value;
        //console.log(ki);
        //console.log(va);
        //console.log('====....====>>>>'); 
        this.formula_man(ki, va);

        if (va > 0) {
            this.formula_man(ki, va);
        } else {
            let markers = this.state.deduct_head;
            ////markers[ki] = { ...markers[ki], amount: out };
            markers[ki] = { ...markers[ki], formula_id: va };
            this.setState({ deduct_head: markers });
        }
    };

    formula_man = (ki, va) => {
        Apiconnect.getData(`employee_salary/get_formula/${va}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            var logic = _xtract[0].logic;

            var itm = {};
            this.state.earning_head.map((val, key) => {
                let kkn = val.abbreviation;
                itm[kkn] = val.amount;
            });
            const kkk = Object.keys(itm);
            const vvv = Object.values(itm);
            var out = 0;
            logic.map((v, k) => {
                var if_con = v.if_condition;
                var chk = v.check_value;
                var opr = v.f_operator;

                kkk.map((v1, k1) => {
                    var finder = v1;
                    var rplcer = vvv[k1];



                    var rr = if_con.replace(finder, rplcer);
                    if_con = rr.toString();
                });

                var ifcon_o = eval(if_con);
                if (opr == 'BETWEEN') {
                    var rng = chk.split("-");
                    var ifcon_out = ifcon_o + '>=' + rng[0] + ' && ' + ifcon_o + '<=' + rng[1];
                } else {
                    var ifcon_out = ifcon_o + opr + chk;
                }
                //console.log(ifcon_out);
                //console.log(eval(ifcon_out));

                if (eval(ifcon_out)) {
                    out = v.f_then;
                    //console.log('--====-->>');
                    //console.log(out);
                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1];
                        //console.log(finder);
                        //console.log(rplcer);
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();


                    });
                    //console.log(out);


                    out = eval(out);
                    //console.log(out);
                    // console.log('--====-->>');
                } else {
                    out = v.f_else;
                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1];
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();
                    });
                    //console.log(out);
                    out = eval(out);
                    //console.log(out);
                    //console.log('--====-->>');
                }
            });
            ///console.log('::: PTAX -->::' + out);
            let markers = this.state.deduct_head;
            markers[ki] = { ...markers[ki], amount: out };
            markers[ki] = { ...markers[ki], formula_id: va };
            this.setState({ deduct_head: markers });

        });
    }







    changeFormula_er = (e, i) => {
        let ki = i.key;
        let va = e.target.value;

        //console.log(ki);
        //console.log(va);
        if (va > 0) {
            this.formula_man_er(ki, va);
        } else {
            let markers = this.state.earning_head;
            //markers[ki] = { ...markers[ki], amount: out };
            markers[ki] = { ...markers[ki], formula_id: va };
            this.setState({ earning_head: markers });
        }

    };

    formula_man_er = (ki, va) => {
        Apiconnect.getData(`employee_salary/get_formula/${va}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            var logic = _xtract[0].logic;

            var itm = {};
            this.state.earning_head.map((val, key) => {
                let kkn = val.abbreviation;
                itm[kkn] = val.amount;
            });
            const kkk = Object.keys(itm);
            const vvv = Object.values(itm);
            var out = 0;

            logic.map((v, k) => {
                var if_con = v.if_condition;
                var chk = v.check_value;
                var opr = v.f_operator;

                kkk.map((v1, k1) => {
                    var finder = v1;
                    var rplcer = vvv[k1];



                    var rr = if_con.replace(finder, rplcer);
                    if_con = rr.toString();
                });

                var ifcon_o = eval(if_con);
                if (opr == 'BETWEEN') {
                    var rng = chk.split("-");
                    var ifcon_out = ifcon_o + '>=' + rng[0] + ' && ' + ifcon_o + '<=' + rng[1];
                } else {
                    var ifcon_out = ifcon_o + opr + chk;
                }
                //console.log(ifcon_out);
                ///console.log(eval(ifcon_out));

                if (eval(ifcon_out)) {
                    out = v.f_then;
                    //console.log('--====-->>');
                    //console.log(out);
                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1];
                        //console.log(finder);
                        //console.log(rplcer);
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();


                    });
                    //console.log(out);


                    out = eval(out);
                    //console.log(out);
                    //console.log('--====-->>');
                } else {
                    out = v.f_else;
                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1];
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();
                    });
                    //console.log(out);
                    out = eval(out);
                    //console.log(out);
                    //console.log('--====-->>');
                }
            });

            //console.log('::: ERN-- -->::' + out);
            let markers = this.state.earning_head;
            markers[ki] = { ...markers[ki], amount: out };
            markers[ki] = { ...markers[ki], formula_id: va };
            this.setState({ earning_head: markers });

        });
    }






    submitForm = async e => {

        //const alert = useAlert();       
        e.preventDefault();
        var empid = this.props.match.params.id;
        const localuser = JSON.parse(localStorage.getItem('user'));
        let user_id = Apiconnect.decrypt(localuser.id); 

        let info_enc = Apiconnect.encrypt_obj(this.state.earning_head);
        let deduct_head_enc = Apiconnect.encrypt_obj(this.state.deduct_head);
        // let remb_head_enc = Apiconnect.encrypt_obj(this.state.reimbursement_head);

        const inf_a = { enc: info_enc, enc_deduct: deduct_head_enc, emp_id: empid,user_id:user_id };

        await Apiconnect.postData("employee_salary/create", inf_a).then((response) => {
            // console.log(response);



            this.props.alert.success(response.data.message)


        });
    };
















    render() {
        console.log(this.state.earning_head);
        console.log((this.state.earning_head.reduce((a, v) => a = a + v.amount, 0)))
        //console.log(this.state.emp_salary_template_deduction);

        const alert = this.props.alert;

        return (





            <div id="content-page" className="content-page">







                <div className="container-fluid">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">Employee Salary Info <span style={{ fontSize: '15px', color: 'blue' }}>Stage 6 to 6</span></h4>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <div className="bs-example">
                            <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                    <Link className="nav-link " data-toggle="tab" to={`/employee_manage/${this.props.match.params.id}`} >Personnel</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link " data-toggle="tab" to={`/employee_manage_info/${this.props.match.params.id}`} >Other Details</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link " data-toggle="tab" to={`/employee_manage_bank/${this.props.match.params.id}`} >Financial</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link " data-toggle="tab" to={`/employee_manage_employement/${this.props.match.params.id}`} >Employement Details</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link" data-toggle="tab" to={`/employee_manage_reimbursement/${this.props.match.params.id}`} >Reimbursement Info</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link active" data-toggle="tab" to={`/employee_manage_sal/${this.props.match.params.id}`} >Salary (Monthly Wages)</Link>
                                    </li>
                                </ul>
                            </div>
                            <form onSubmit={e => this.submitForm(e)}>

                                <div className="border">


                                    <div className="row">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th width="120" scope="col">#</th>
                                                    <th scope="col">Head</th>
                                                    <th scope="col">Type</th>
                                                    <th width="50%" scope="col">Set Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ background: "#948a9e", padding: "0.3rem" }} colSpan="5"><h6 style={{ color: "#fff", 'fontWeight': "bold" }}>Earning Head</h6></td>
                                                </tr>
                                                {this.state.earning_head.map((val, key) => {

                                                    let fr_id = this.state.emp_salary_template.find(item => val.id == item.head_id);
                                                    let fm_id = '';
                                                    if (fr_id != undefined) {
                                                        if (fr_id.formula_id != 0) {

                                                            if (val.formula_id == null) {
                                                                fm_id = fr_id.formula_id;
                                                                val.formula_id = fm_id;
                                                            }


                                                        }
                                                    }

                                                    let frmla = val.formula;
                                                    return (
                                                        <tr key={key}>
                                                            <td>{key + 1}</td>
                                                            <td>{val.name}  </td>
                                                            <td>
                                                                <select className="form-control" value={val.formula_id} onChange={e => this.changeFormula_er(e, { key })}  >
                                                                    <option>Static Data</option>
                                                                    {frmla.map((valza, keyza) => {
                                                                        return (<option key={keyza} value={valza.id}>{valza.title}</option>);
                                                                    })}
                                                                </select>
                                                            </td>

                                                            <td>
                                                                <input name={val.id} className="form-control" value={val.amount} placeholder="Amount" onChange={e => this.onInputChange(e, { key })} />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                <td colSpan="3"><h6>Earning Total</h6></td>
                                                <td colSpan="3">{this.state.earning_head.reduce((a, v) => a = Number(a) + Number(v.amount), 0)}</td>
                                                </tr>
                                                <td style={{ background: "#948a9e", padding: "0.3rem" }} colSpan="5"><h6 style={{ color: "#fff", 'fontWeight': "bold" }}>Deduction Head</h6></td>

                                                {this.state.deduct_head.map((valx, keyx) => {

                                                    let fr_id = this.state.emp_salary_template.find(item => valx.id == item.head_id);
                                                    let fm_id = '';
                                                    if (fr_id != undefined) {
                                                        if (fr_id.formula_id != 0) {
                                                            // fm_id = fr_id.formula_id;
                                                            if (valx.formula_id == null) {
                                                                fm_id = fr_id.formula_id;
                                                                valx.formula_id = fm_id;
                                                            }
                                                        }
                                                    }

                                                    // let formula_id = '';
                                                    // if (valx.formula_id == null) {
                                                    //     formula_id = fm_id;
                                                    // } else {
                                                    //     formula_id = valx.formula_id;
                                                    // }

                                                    let frml = valx.formula;
                                                    return (
                                                        <tr key={keyx}>
                                                            <td>{keyx + 1}</td>
                                                            <td>{valx.name} </td>
                                                            <td>
                                                                <select className="form-control" value={valx.formula_id} onChange={e => this.changeFormula(e, { keyx })}  >
                                                                    <option value="">Static Data</option>
                                                                    {frml.map((valz, keyz) => {
                                                                        return (<option key={keyz} value={valz.id}>{valz.title}</option>);
                                                                    })}
                                                                </select>
                                                            </td>

                                                            <td>

                                                                <input placeholder="Amount" className="form-control" name={valx.id} value={valx.amount} defaultValue={valx.amount} onChange={e => this.onInputChange_deduct(e, { keyx })} />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                <td colSpan="3"><h6>Deduction Total</h6></td>
                                                <td colSpan="3">{this.state.deduct_head.reduce((a, v) => a = Number(a) + Number(v.amount), 0)}</td>
                                                </tr>

                                                <tr style={{ background: "#948a9e", padding: "0.3rem" }}>
                                                <td colSpan="3"><h6 style={{ color: "#fff", 'fontWeight': "bold" }}>Net Amount</h6></td>
                                                <td>{this.state.earning_head.reduce((a, v) => a = Number(a) + Number(v.amount), 0) - this.state.deduct_head.reduce((a, v) => a = Number(a) + Number(v.amount), 0)}</td>
                                                </tr>

                                                {/* <td style={{ background: "#948a9e", padding: "0.3rem" }} colSpan="5"><h6 style={{ color: "#fff", 'fontWeight': "bold" }}>Reimbursement Head</h6></td>


                                                {this.state.reimbursement_head.map((valy, keyy) => {
                                                    return (
                                                        <tr key={keyy}>
                                                            <td>{keyy + 1}</td>
                                                            <td>{valy.name}  </td>
                                                            <td>

                                                            </td>

                                                            <td>
                                                                <input name={valy.id} placeholder="Amount" value={valy.amount} className="form-control" onChange={e => this.onInputChange_remb(e, { keyy })} />
                                                            </td>
                                                        </tr>
                                                    );
                                                })} */}













                                            </tbody>
                                        </table>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <button type="submit" name="id" className="btn btn-primary mr-2">  Proceed </button>



                                        <Link className="btn btn-info mr-2" to={`/employee_manage_reimbursement/${this.props.match.params.id}`} >Previous</Link>
                                        {/* <Link className="btn btn-success mr-2" to={`/employee_manage_reimbursement/${this.props.match.params.id}`} >Next</Link> */}
                                        <Link style={{ float: 'right' }} className="btn btn-secondary" to="/employee_manage_list" >Back to List</Link>

                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>





        );
    }
}

export default withAlert()(Employee_manage_sal);