
import React, { useState,useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import '../pages/pages.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



const Leave_policy = () => {

    const alert = useAlert();

    let history = useHistory();

    const localuser = JSON.parse(localStorage.getItem('user'));
    let user_id = Apiconnect.decrypt(localuser.id); 


    const [info, setInfo] = useState({
        name: "",
        leave_code: "",
        typ: "",
        leave_credit_count: "",
        min_working_days: "",
        is_lapse: "0",
        max_carry_forward:"",
        leave_type_selection:'monthly',
        min_leave_encash_days:"",
        leave_apply_days_min:"",
        leave_apply_days_max:"",
        leave_gap_before:"",
        leave_gap_after:"",
        yearly_max_apply:"",
        monthly_max_apply:"",
        company_id : Apiconnect.get_company_id(),
        id: null,
     });
        const { name, leave_code, typ,leave_apply_days_min, leave_credit_count, min_working_days, is_lapse,max_carry_forward,leave_type_selection,min_leave_encash_days,leave_apply_days_max,leave_gap_after,leave_gap_before,yearly_max_apply,monthly_max_apply,company_id,id } = info;
     const onInputChange = e => {
        setInfo({ ...info, [e.target.name]: e.target.value });
     };
  
     const onSubmit = async e => {
        e.preventDefault();
  
        if(id>0){
           console.log('in edit function' +id);
         //   console.log(info);
           let info_enc =  Apiconnect.encrypt_obj(info);
           const inf_a = { enc: info_enc,user_id:user_id };
           Apiconnect.postData(`company_leave_policy/update/`, inf_a).then((response) => {
              history.push("/leave_policy"); 
              setInfo({ name: "", leave_code: "", typ: "",leave_credit_count: "",min_working_days: "",is_lapse: "0",max_carry_forward:"",leave_type_selection:'monthly',
              min_leave_encash_days:"",leave_apply_days_min:"", leave_apply_days_max:"",leave_gap_before:"",leave_gap_after:"",yearly_max_apply:"",monthly_max_apply:"",id: ''});
              getList();
              autoGenerateLeaveCode();
              alert.success('Leave Policy updated successfully.');  
           });
        }else{
           console.log('in add function' );
           let info_enc =  Apiconnect.encrypt_obj(info);
           const inf_a = { enc: info_enc,user_id:user_id };
           await Apiconnect.postData("company_leave_policy/create", inf_a);
           history.push("/leave_policy"); 
           setInfo({  name: "", leave_code: "", typ: "",leave_credit_count: "",min_working_days: "",is_lapse: "0",max_carry_forward:"",leave_type_selection:'monthly',
           min_leave_encash_days:"",leave_apply_days_min:"", leave_apply_days_max:"",leave_gap_before:"",leave_gap_after:"",yearly_max_apply:"",monthly_max_apply:"",id: ''});
           getList();
           alert.success('Leave Policy added successfully.');  
           autoGenerateLeaveCode();
           
          
        }
  
     };

     const ondlt = (id) => {
      confirmAlert({
        title: 'Are you sure to Delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteItem(id), 
          },
          {
            label: 'No',
          }
        ]
      });
    };

     const deleteItem = (id) => {
      Apiconnect.deleteData(`company_leave_policy/delete/${id}/${user_id}`).then((response) => {
           history.push("/leave_policy");
           getList();
        });
     };
  
     const getItem = (id) => {
      Apiconnect.getData(`company_leave_policy/get/${id}`).then((response) => {
         //   console.log(response.data.data[0]);   
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setInfo({ name: _xtract[0].name, leave_code: _xtract[0].leave_code, typ: _xtract[0].typ, leave_credit_count: _xtract[0].leave_credit_count,
            min_working_days: _xtract[0].min_working_days, is_lapse: _xtract[0].is_lapse, max_carry_forward: _xtract[0].max_carry_forward,
            leave_type_selection: _xtract[0].leave_type_selection, min_leave_encash_days: _xtract[0].min_leave_encash_days, leave_apply_days_min: _xtract[0].leave_apply_days_min, 
            leave_apply_days_max: _xtract[0].leave_apply_days_max, leave_gap_before: _xtract[0].leave_gap_before, leave_gap_after: _xtract[0].leave_gap_after, 
            yearly_max_apply: _xtract[0].yearly_max_apply, monthly_max_apply: _xtract[0].monthly_max_apply,  id: _xtract[0].id });
            setLeaveCode( _xtract[0].leave_code);
        });
      };
  
      
     function refreshPage(){ 
        window.location.reload(); 
     }
  
  
     useEffect(() => { getList();autoGenerateLeaveCode(); }, []);

     const [List, setList] = useState([]);
     const [auto_leave_code, setLeaveCode] = useState(null);

     const getList = () => {
      Apiconnect.getData(`company_leave_policy/getall/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setList(_xtract.reverse());
        });
     };

     const autoGenerateLeaveCode = () => {
      Apiconnect.getData(`company_leave_policy/getleavecode`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setLeaveCode(Number(_xtract[0].cnt)+1);
        });
     }
     


     const [viewList,setViewList] = useState({
      name1: "",
      leave_code1: "",
      typ1: "",
      leave_credit_count1: "",
      min_working_days1: "",
      is_lapse1: "0",
      max_carry_forward1:"",
      leave_type_selection1:'monthly',
      min_leave_encash_days1:"",
      leave_apply_days_min1:"",
      leave_apply_days_max1:"",
      leave_gap_before1:"",
      leave_gap_after1:"",
      yearly_max_apply1:"",
      monthly_max_apply1:"",
   });

   const { name1, leave_code1, typ1,leave_apply_days_min1, leave_credit_count1, min_working_days1, is_lapse1,max_carry_forward1,leave_type_selection1,min_leave_encash_days1,
      leave_apply_days_max1,leave_gap_after1,leave_gap_before1,yearly_max_apply1,monthly_max_apply1} = viewList;

   const viewItem = (id)=>{
      setModalIsOpen(true);

      Apiconnect.getData(`company_leave_policy/get/${id}`).then((response) => {
         // console.log(response.data.data[0]);
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setViewList({ name1: _xtract[0].name, leave_code1: _xtract[0].leave_code, typ1: _xtract[0].typ, leave_credit_count1: _xtract[0].leave_credit_count,
            min_working_days1: _xtract[0].min_working_days, is_lapse1: _xtract[0].is_lapse, max_carry_forward1: _xtract[0].max_carry_forward,
            leave_type_selection1: _xtract[0].leave_type_selection, min_leave_encash_days1: _xtract[0].min_leave_encash_days, leave_apply_days_min1: _xtract[0].leave_apply_days_min, 
            leave_apply_days_max1: _xtract[0].leave_apply_days_max, leave_gap_before1: _xtract[0].leave_gap_before, leave_gap_after1: _xtract[0].leave_gap_after, 
            yearly_max_apply1: _xtract[0].yearly_max_apply, monthly_max_apply1: _xtract[0].monthly_max_apply });
      });
   }

   const customStyles = {
      content: {
         top: '50%', width: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
        
      },
   };
   const [modalIsOpen, setModalIsOpen] = useState(false);

   let lt = 'Yearly';
   if(leave_type_selection1 == 'monthly'){
      let lt = 'Monthly';
   }

    return (
        <>

<div id="content-page" className="content-page">
            <div className="container-fluid">

            <div >
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Category View</h4>
                           </div>
                        </div>

                        <div className="iq-card-body">
                           <div className="row">
                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Name : </b></span>
                                    <span> {name1}</span>
                              </div>
                              </div>

                              {/* <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Type : </b></span>
                                    <span></span>
                                 </div>
                              </div> */}

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Leave Code : </b></span>
                                    <span>{leave_code1}</span>
                                 </div>
                              </div>
                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Leave Credit Count : </b></span>
                                    <span>{leave_credit_count1}</span>
                                 </div>
                              </div>
                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Max Carry Forword : </b></span>
                                    <span>{max_carry_forward1}</span>
                                 </div>
                              </div>
                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Leave Type Selection : </b></span>
                                    <span>{lt}</span>
                                 </div>
                              </div>
                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Minimum Leave Encash Days : </b></span>
                                    <span>{min_leave_encash_days1}</span>
                                 </div>
                              </div>

                              </div>
                              <div className="row">
                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Leave Apply Days Minimum : </b></span>
                                    <span>{leave_apply_days_min1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Leave Apply Days Maximum : </b></span>
                                    <span>{leave_apply_days_max1}</span>
                                 </div>
                              </div>
                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Leave Gap Before : </b></span>
                                    <span>{leave_gap_before1}</span>
                                 </div>
                              </div>
                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Leave Gap After : </b></span>
                                    <span>{leave_gap_after1}</span>
                                 </div>
                              </div>
                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Yearly Maximum Apply : </b></span>
                                    <span>{yearly_max_apply1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Monthly Maximum Apply : </b></span>
                                    <span>{monthly_max_apply1}</span>
                                 </div>
                              </div>

                             
                           </div>
                        </div>
                        <div className="text_center">
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>


               <div className="row">
                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Leave Policy</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <form onSubmit={e => onSubmit(e)}>
                              <div className="row">


                                               
                                              
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Name <strong className="required"> *</strong></label>
                                                        <input type="text" className="form-control validate[required]" name="name" placeholder="Name" 
                                                         value={name}
                                                         onChange={e => onInputChange(e)}
                                                         required />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Type</label>
                                                        <select className="form-control" name="typ"  value={typ}
                                                         onChange={e => onInputChange(e)}>
                                                            <option value="">Select Type</option>
                                                        </select>
                                                    </div>
                                                </div> 

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Leave Code</label>
                                                        <input type="text" className="form-control validate[required]" name="leave_code" 
                                                        value={auto_leave_code}
                                                        onChange={e => onInputChange(e)}
                                                         placeholder="Leave Code" readOnly/>
                                                    </div>
                                                </div>

                                                */}

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Leave Type</label>
                                                        <select className="form-control" name="leave_type_selection"  value={leave_type_selection}
                                                         onChange={e => onInputChange(e)}>
                                                            <option value="monthly">Monthly</option>
                                                            <option value="yearly">Yearly</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Leave Credit (Monthly/Yearly)</label>
                                                        <input type="text" className="form-control validate[required]" name="leave_credit_count" 
                                                         value={leave_credit_count}
                                                         onChange={e => onInputChange(e)} placeholder="Leave Credit Count" />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Max Carry Forword</label>
                                                        <input type="text" className="form-control validate[required]" name="max_carry_forward" 
                                                         value={max_carry_forward}
                                                         onChange={e => onInputChange(e)}
                                                         placeholder="Max Carry Forword" />
                                                    </div>
                                                </div>
                                                

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Minimum Leave Encash Days</label>
                                                        <input type="text" className="form-control validate[required]" name="min_leave_encash_days" 
                                                        value={min_leave_encash_days}
                                                        onChange={e => onInputChange(e)} placeholder="Minimum Leave Encash Days" />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label> Minimum Day Gap (to apply leave)</label>
                                                        <input type="text" className="form-control validate[required]" name="leave_apply_days_min" 
                                                         value={leave_apply_days_min}
                                                         onChange={e => onInputChange(e)} placeholder="Leave Apply Days Minimum"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        {
                                                        //<label>Monthly Cap</label>
                                                        }
                                                        <label>Max Days Limit (in single application)</label>
                                                        <input type="text" className="form-control validate[required]" name="leave_apply_days_max"
                                                         value={leave_apply_days_max}
                                                         onChange={e => onInputChange(e)} placeholder="Max days allowed in a single application " />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label> Leave Gap Before</label>
                                                        <input type="text" className="form-control validate[required]" name="leave_gap_before" 
                                                         value={leave_gap_before}
                                                         onChange={e => onInputChange(e)} placeholder="Leave Gap Before" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label> Leave Gap After</label>
                                                        <input type="text" className="form-control validate[required]" name="leave_gap_after" 
                                                        value={leave_gap_after}
                                                        onChange={e => onInputChange(e)}  placeholder="Leave Gap After" />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label> Yearly Maximum Apply</label>
                                                        <input type="text" className="form-control validate[required]" name="yearly_max_apply" 
                                                          value={yearly_max_apply}
                                                          onChange={e => onInputChange(e)} placeholder="Yearly Maximum Apply" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label> Monthly Maximum Apply</label>
                                                        <input type="text" className="form-control validate[required]" name="monthly_max_apply" 
                                                         value={monthly_max_apply}
                                                         onChange={e => onInputChange(e)} placeholder="Monthly Maximum Apply" />
                                                    </div>
                                                </div>

                                 <div className="col-md-12 mt-4">
                                    <button type="submit"  name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    {(() => {
                                       if (id>0) {
                                          return (
                                             <button type="button" className="btn" onClick={ refreshPage }> Return </button> 
                                          )
                                       } 
                                    })()}     
                                 </div>
                              </div>

                           </form>


                         
                        </div>
                     </div>
                  </div>
                  
                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List Of Leave Policy</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                              <thead className="">
                              <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Leave Code</th>
                                                        <th scope="col">Leave Gap Before</th>
                                                        <th scope="col">Leave Gap After</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                              </thead>
                              <tbody className="">
                                 {List.map((val, key) => {
                                    return (
                                       <tr key={key}>
                                          <th scope="row">{key + 1}</th>
                                          <td>{val.name}</td>
                                          <td>{val.leave_code}</td>
                                          <td>{val.leave_gap_before}</td>
                                          <td>{val.leave_gap_after}</td>
                                          <td>
                                          <button onClick={() => {ondlt(val.id);}}
 className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                             {/* <button onClick={() => { deleteItem(val.id); }}
                                                className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button> */}
                                             <button onClick={() => {getItem(val.id);}}
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                             
                                             <button onClick={() => viewItem(val.id)}
                                                className="btn btn-info mr-2"><i className="fa fa-eye" aria-hidden="true"></i></button>

                                          </td>
                                       </tr>
                                    );
                                 })}

                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                
               </div>
            </div>
         </div>


         
        </>
    )
}

export default Leave_policy