import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { Link } from "react-router-dom";
import "./admin.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Modal from "react-modal";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import Loadder from "./Loadder";
import Axios from "axios";
// const { createProxyMiddleware } = require('http-proxy-middleware');

const Employee_requested_details = (props) => {
   var emp_id = props.match.params.id;
   const localuser = JSON.parse(localStorage.getItem('user'));
   let user_id = Apiconnect.decrypt(localuser.id);
   const alert = useAlert();
   let history = useHistory();

  useEffect(() => {getEditProfileData()},[]) 

  const [info2, setInfo2] = useState({
   employee_mst_id: '',present_address_one: '',present_address_one_status: '',present_address_two: '',present_address_two_status: '',present_landmark: '',present_landmark_status: '',present_area_id: '',present_area_id_status: '',present_city_id: '',present_city_id_status: '',present_state_id: '',present_state_id_status: '',present_country_id: '',present_country_id_status: '',present_zip: '',present_zip_status: '',fax: '',fax_status: '',parmanent_address_one: '',parmanent_address_one_status: '',parmanent_address_two: '',parmanent_address_two_status: '',parmanent_landmark: '',parmanent_landmark_status: '',parmanent_area_id: '',parmanent_area_id_status: '',parmanent_city_id: '',parmanent_city_id_status: '',parmanent_state_id: '',parmanent_state_id_status: '',parmanent_country_id: '',parmanent_country_id_status: '',parmanent_zip: '',parmanent_zip_status: '',fax1: '',fax1_status: '',phone_secondary: '',phone_secondary_status: '',email_secondary: '',email_secondary_status: '',blood_group: '',blood_group_status: '',religion: '',religion_status: '',caste: '',caste_status: '',nationality: '',nationality_status: '',marital_status: '',marital_status_status: '',anniversary_date: '',anniversary_date_status: '',emergency_contact_person: '',emergency_contact_person_status: '',emergency_relationship_person_id: '',emergency_relationship_person_id_status: '',emergency_contact_number: '',emergency_contact_number_status: '',emergency_contact_person2: '',emergency_contact_person2_status: '',emergency_relationship_person_id2: '',emergency_relationship_person_id2_status: '',emergency_contact_number2
   : '',emergency_contact_number2_status: '',pan: '',pan_status: '',passport_no: '',passport_no_status: '',aadhaar: '',aadhaar_status: '',payment_mode: '',payment_mode_status: '',bank_name: '',bank_name_status: '',branch_name: '',branch_name_status: '',ifsc_code: '',ifsc_code_status: '',account_number: '',account_number_status
 : '',tax_borne_by_copany: '',tax_borne_by_copany_status: '',is_senior_citizen: '',is_senior_citizen_status: '',is_epf: '',is_epf_status: '',UAN_number: '',UAN_number_status: '',epf_number: '',epf_number_status: '',pf_start_date: '',pf_start_date_status: '',is_esi: '',is_esi_status: '',esi_number: '',esi_number_status: '',dispencery: '',dispencery_status: '',present_area: '',present_area_status: '',parmanent_area: '',parmanent_area_status: '',father_name: '',father_name_status: '',spouse_name: '',spouse_name_status:'',
 
});
const {
   employee_mst_id,present_address_one,present_address_one_status,present_address_two,present_address_two_status,present_landmark,present_landmark_status,present_area_id,present_area_id_status,present_city_id,present_city_id_status,present_state_id,present_state_id_status,present_country_id,present_country_id_status,present_zip,present_zip_status,fax,fax_status,parmanent_address_one,parmanent_address_one_status,parmanent_address_two,parmanent_address_two_status,parmanent_landmark,parmanent_landmark_status,parmanent_area_id,parmanent_area_id_status,parmanent_city_id,parmanent_city_id_status,parmanent_state_id,parmanent_state_id_status,parmanent_country_id,parmanent_country_id_status,parmanent_zip,parmanent_zip_status,fax1,fax1_status,phone_secondary,phone_secondary_status,email_secondary,email_secondary_status,blood_group,blood_group_status,religion,religion_status,caste,caste_status,nationality,nationality_status,marital_status,marital_status_status,anniversary_date,anniversary_date_status,emergency_contact_person,emergency_contact_person_status,emergency_relationship_person_id,emergency_relationship_person_id_status,emergency_contact_number,emergency_contact_number_status,emergency_contact_person2,emergency_contact_person2_status,emergency_relationship_person_id2,emergency_relationship_person_id2_status,emergency_contact_number2
   ,emergency_contact_number2_status,pan,pan_status,passport_no,passport_no_status,aadhaar,aadhaar_status,payment_mode,payment_mode_status,bank_name,bank_name_status,branch_name,branch_name_status,ifsc_code,ifsc_code_status,account_number,account_number_status
 ,tax_borne_by_copany,tax_borne_by_copany_status,is_senior_citizen,is_senior_citizen_status,is_epf,is_epf_status,UAN_number,UAN_number_status,epf_number,epf_number_status,pf_start_date,pf_start_date_status,is_esi,is_esi_status,esi_number,esi_number_status,dispencery,dispencery_status,present_area,present_area_status,parmanent_area,parmanent_area_status,father_name,father_name_status,spouse_name,spouse_name_status,
  } = info2;


  const getEditProfileData = () => {    
   Apiconnect.getData(`employee_info/geteditprofilerequest/${emp_id}`).then((response) => {        
      let _xtract = Apiconnect.decrypt_obj(response.data.data);  
       setInfo2(_xtract[0]);
       if(_xtract[0].present_address_one_status == 1 || _xtract[0].present_address_two_status == 1 || _xtract[0].present_landmark_status == 1 || _xtract[0].present_area_id_status == 1 || _xtract[0].present_city_id_status == 1 || _xtract[0].present_state_id_status == 1 || _xtract[0].present_country_id_status == 1 || _xtract[0].present_zip_status == 1 || _xtract[0].fax_status == 1 || _xtract[0].parmanent_address_one_status == 1 || _xtract[0].parmanent_address_two_status == 1 || _xtract[0].parmanent_landmark_status == 1 || _xtract[0].parmanent_area_id_status == 1 || _xtract[0].parmanent_city_id_status == 1 || _xtract[0].parmanent_state_id_status == 1 || _xtract[0].parmanent_country_id_status == 1 || _xtract[0].parmanent_zip_status == 1 || _xtract[0].fax1_status == 1 || _xtract[0].phone_secondary_status == 1 || _xtract[0].email_secondary_status == 1 || _xtract[0].blood_group_status == 1 || _xtract[0].religion_status == 1 || _xtract[0].caste_status == 1 || _xtract[0].nationality_status == 1 || _xtract[0].marital_status_status == 1 || _xtract[0].anniversary_date_status == 1 || _xtract[0].emergency_contact_person_status == 1 || _xtract[0].emergency_relationship_person_id_status == 1 || _xtract[0].emergency_contact_number_status == 1 || _xtract[0].emergency_contact_person2_status == 1 || _xtract[0].emergency_relationship_person_id2_status == 1 || _xtract[0].emergency_contact_number2_status == 1 || _xtract[0].pan_status == 1 || _xtract[0].passport_no_status == 1 || _xtract[0].aadhaar_status == 1 || _xtract[0].payment_mode_status == 1 || _xtract[0].bank_name_status == 1 || _xtract[0].branch_name_status == 1 || _xtract[0].ifsc_code_status == 1 || _xtract[0].account_number_status
         == 1 || _xtract[0].tax_borne_by_copany_status == 1 || _xtract[0].is_senior_citizen_status == 1 || _xtract[0].is_epf_status == 1 || _xtract[0].UAN_number_status == 1 || _xtract[0].epf_number_status == 1 || _xtract[0].pf_start_date_status == 1 || _xtract[0].is_esi_status == 1 || _xtract[0].esi_number_status == 1 || _xtract[0].dispencery_status == 1 || _xtract[0].present_area_status == 1 || _xtract[0].parmanent_area_status == 1 || _xtract[0].father_name_status == 1 || _xtract[0].spouse_name_status == 1){
       }else{
        Apiconnect.getData(`employee_info/updatedetailsstatus/${emp_id}`).then((response) => {
          history.push("/employee_requested_thumb");
       })
       }
   });
 };

 

 const request_approve = (key,value) =>{

   var data = {
        [key]: value,
      }
   var col = key+'_status';
   var data1 = {
              [col]: 0,
            }
  var main_data = {user_id:user_id, emp_id:emp_id,datareq:data, datareqstatus:data1};

  //  var data = {user_id:user_id, emp_id:emp_id,key:key,value:value};
   Apiconnect.postData(`employee_info/approvedetailsrequest`,main_data).then((response) => {        
      alert.success(response.data.message);
      getEditProfileData();
   });
 }

 const request_reject = (key,value) =>{

  var data = {
    [key]: value,
  }
  var col = key+'_status';
  var data1 = {
            [col]: 2,
          }
var main_data = {user_id:user_id, emp_id:emp_id,datareq:data, datareqstatus:data1};
   Apiconnect.postData(`employee_info/rejectdetailsrequest`,main_data).then((response) => {        
      alert.success(response.data.message);
      getEditProfileData();
   });
}

const request_approve_present_addres = () =>{

  var data = {present_address_one:present_address_one,present_address_two: present_address_two,present_landmark: present_landmark,
  present_area_id: present_area_id,present_city_id: present_city_id,present_state_id: present_state_id,present_country_id: present_country_id,present_zip: present_zip};
 
  var data1={present_address_one_status: 0,present_address_two_status: 0,present_landmark_status: 0,present_area_id_status: 0,present_city_id_status: 0,present_state_id_status: 0,
  present_country_id_status: 0,present_zip_status: 0}; 

  var main_data = {user_id:user_id, emp_id:emp_id,datareq:data, datareqstatus:data1};
  Apiconnect.postData(`employee_info/approvedetailsrequest`,main_data).then((response) => {        
     alert.success(response.data.message);
     getEditProfileData();
  });
}

const request_reject_present_addres = () =>{

  var data = {present_address_one:present_address_one,present_address_two: present_address_two,present_landmark: present_landmark,
    present_area_id: present_area_id,present_city_id: present_city_id,present_state_id: present_state_id,present_country_id: present_country_id,present_zip: present_zip};
 
  var data1={present_address_one_status: 2,present_address_two_status: 2,present_landmark_status: 2,present_area_id_status: 2,present_city_id_status: 2,present_state_id_status: 2,
    present_country_id_status: 2,present_zip_status: 2}; 
    
  var main_data = {user_id:user_id, emp_id:emp_id,datareq:data, datareqstatus:data1};
  Apiconnect.postData(`employee_info/rejectdetailsrequest`,main_data).then((response) => {        
     alert.success(response.data.message);
     getEditProfileData();
  });
}

const request_approve_parmanent_addres = () =>{

  var data = {parmanent_address_one:parmanent_address_one,parmanent_address_two: parmanent_address_two,parmanent_landmark: parmanent_landmark,
  parmanent_area_id: parmanent_area_id,parmanent_city_id: parmanent_city_id,parmanent_state_id: parmanent_state_id,parmanent_country_id: parmanent_country_id,parmanent_zip: parmanent_zip};
 
  var data1={parmanent_address_one_status: 0,parmanent_address_two_status: 0,parmanent_landmark_status: 0,parmanent_area_id_status: 0,parmanent_city_id_status: 0,parmanent_state_id_status: 0,
  parmanent_country_id_status: 0,parmanent_zip_status: 0}; 

  var main_data = {user_id:user_id, emp_id:emp_id,datareq:data, datareqstatus:data1};
  Apiconnect.postData(`employee_info/approvedetailsrequest`,main_data).then((response) => {        
     alert.success(response.data.message);
     getEditProfileData();
  });
}


const request_reject_parmanent_addres = () =>{

  var data = {parmanent_address_one:parmanent_address_one,parmanent_address_two: parmanent_address_two,parmanent_landmark: parmanent_landmark,
    parmanent_area_id: parmanent_area_id,parmanent_city_id: parmanent_city_id,parmanent_state_id: parmanent_state_id,parmanent_country_id: parmanent_country_id,parmanent_zip: parmanent_zip};
   
    var data1={parmanent_address_one_status: 2,parmanent_address_two_status: 2,parmanent_landmark_status: 2,parmanent_area_id_status: 2,parmanent_city_id_status: 2,parmanent_state_id_status: 2,
    parmanent_country_id_status: 2,parmanent_zip_status: 2}; 
    
    var main_data = {user_id:user_id, emp_id:emp_id,datareq:data, datareqstatus:data1};
  Apiconnect.postData(`employee_info/rejectdetailsrequest`,main_data).then((response) => {        
     alert.success(response.data.message);
     getEditProfileData();
  });
}


useEffect(() => { getArea(); getCity(); getState(); getCountry(); }, []);
const [areaList, setArea] = useState([]);
const [cityList, setCity] = useState([]);
const [stateList, setState] = useState([]);
const [countryList, setCountry] = useState([]);

const getArea = () => {
   Apiconnect.getData("area/getall").then((response) => {
       setArea(response.data.data.reverse());
   });
};

const getCity = () => {
   Apiconnect.getData("city/getall").then((response) => {
       setCity(response.data.data.reverse());
   });
};

const getState = () => {
   Apiconnect.getData("state/getall").then((response) => {
       setState(response.data.data.reverse());
   });
};

const getCountry = () => {
   Apiconnect.getData("country/getall").then((response) => {
       setCountry(response.data.data.reverse());
   });
};

  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Edit Profile</h4>
                  </div>
                </div>
                <div className="iq-card-body">

                   <div className="row">

                  
                      
                    {(present_address_one_status == 1 || present_address_two_status == 1 || present_landmark_status == 1 || present_area_id_status == 1
                    || present_city_id_status == 1 || present_state_id_status == 1 || present_country_id_status == 1 || present_zip_status == 1)?
                    <div className="col-md-12 col-sm-12 col-12" style={{display: 'flex',margin:'5px 0px'}}>
                     <div className="col-md-3 col-sm-3 col-3">
                       <label>Present Address :</label>
                     </div>
                     <div className="col-md-6 col-sm-6 col-6">
                       {present_address_one} {(present_address_one_status == 1)?<br/>:''} {present_address_two} {(present_address_two_status==1)?<br/>:''} {present_landmark}
                       {areaList.map((val, key) => {
                          if(val.id == present_area_id){
                           return(<span>{val.name}</span>)
                          }
                         })},
                         {cityList.map((val, key) => {
                          if(val.id == present_city_id){
                           return(<span>{val.name}</span>)
                          }
                         })},
                         {stateList.map((val, key) => {
                          if(val.id == present_state_id){
                           return(<span>{val.name}</span>)
                          }
                         })},
                            {countryList.map((val, key) => {
                          if(val.id == present_country_id){
                           return(<span>{val.name}</span>)
                          }
                         })},
                           {present_zip}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                       <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve_present_addres()} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject_present_addres()} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                {(parmanent_address_one_status == 1 || parmanent_address_two_status == 1 || parmanent_landmark_status == 1 || parmanent_area_id_status == 1
                    || parmanent_city_id_status == 1 || parmanent_state_id_status == 1 || parmanent_country_id_status == 1 || parmanent_zip_status == 1)?
                    <div className="col-md-12 col-sm-12 col-12" style={{display: 'flex',margin:'15px 0px'}}>
                     <div className="col-md-3 col-sm-3 col-3">
                       <label>Permanent Address :</label>
                     </div>
                     <div className="col-md-6 col-sm-6 col-6">
                       {parmanent_address_one} {(parmanent_address_one_status==1)?<br/>:''} {parmanent_address_two} {(parmanent_address_two_status==1)?<br/>:''} {parmanent_landmark}
                       {areaList.map((val, key) => {
                          if(val.id == parmanent_area_id){
                           return(<span>{val.name}</span>)
                          }
                         })},
                         {cityList.map((val, key) => {
                          if(val.id == parmanent_city_id){
                           return(<span>{val.name}</span>)
                          }
                         })},
                         {stateList.map((val, key) => {
                          if(val.id == parmanent_state_id){
                           return(<span>{val.name}</span>)
                          }
                         })},
                            {countryList.map((val, key) => {
                          if(val.id == parmanent_country_id){
                           return(<span>{val.name}</span>)
                          }
                         })},
                           {parmanent_zip}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                       <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve_parmanent_addres()} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject_parmanent_addres()} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {/* {(present_address_one_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Present Address 1 :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {present_address_one}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                       <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('present_address_one',present_address_one)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('present_address_one',present_address_one)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}  */}

                   {/* {(present_address_two_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Present Address 2 :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {present_address_two}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('present_address_two',present_address_two)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('present_address_two',present_address_two)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''} */}

                  {/* {(present_landmark_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Present Landmark :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {present_landmark}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('present_landmark',present_landmark)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('present_landmark',present_landmark)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''} */}

                  {/* {(present_area_id_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Present Area :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {areaList.map((val, key) => {
                          if(val.id == present_area_id){
                           return(<span>{val.name}</span>)
                          }
                         })}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('present_area_id',present_area_id)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('present_area_id',present_area_id)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''} */}

                  {/* {(present_city_id_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Present City :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {cityList.map((val, key) => {
                          if(val.id == present_city_id){
                           return(<span>{val.name}</span>)
                          }
                         })}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('present_city_id',present_city_id)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('present_city_id',present_city_id)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''} */}

                  {/* {(present_state_id_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Present State :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {stateList.map((val, key) => {
                          if(val.id == present_state_id){
                           return(<span>{val.name}</span>)
                          }
                         })}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('present_state_id',present_state_id)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('present_state_id',present_state_id)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''} */}

                  {/* {(present_country_id_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Present Country :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {countryList.map((val, key) => {
                          if(val.id == present_country_id){
                           return(<span>{val.name}</span>)
                          }
                         })}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('present_country_id',present_country_id)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('present_country_id',present_country_id)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''} */}

                  {/* {(present_zip_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Present Zip :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {present_zip}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('present_zip',present_zip)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('present_zip',present_zip)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}     */}

                                       

                  {/* {(parmanent_address_one_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Permanent Address 1 :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {parmanent_address_one}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                       <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('parmanent_address_one',parmanent_address_one)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('parmanent_address_one',parmanent_address_one)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                   {(parmanent_address_two_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Permanent Address 2 :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {parmanent_address_two}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('parmanent_address_two',parmanent_address_two)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('parmanent_address_two',parmanent_address_two)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(parmanent_landmark_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Permanent Landmark :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {parmanent_landmark}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('parmanent_landmark',parmanent_landmark)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('parmanent_landmark',parmanent_landmark)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(parmanent_area_id_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Permanent Area :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {areaList.map((val, key) => {
                          if(val.id == parmanent_area_id){
                           return(<span>{val.name}</span>)
                          }
                         })}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('parmanent_area_id',parmanent_area_id)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('parmanent_area_id',parmanent_area_id)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(parmanent_city_id_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Permanent City :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {cityList.map((val, key) => {
                          if(val.id == parmanent_city_id){
                           return(<span>{val.name}</span>)
                          }
                         })}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('parmanent_city_id',parmanent_city_id)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('parmanent_city_id',parmanent_city_id)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(parmanent_state_id_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Permanent State :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {stateList.map((val, key) => {
                          if(val.id == parmanent_state_id){
                           return(<span>{val.name}</span>)
                          }
                         })}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('parmanent_state_id',parmanent_state_id)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('parmanent_state_id',parmanent_state_id)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(parmanent_country_id_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Permanent Country :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                       {countryList.map((val, key) => {
                          if(val.id == parmanent_country_id){
                           return(<span>{val.name}</span>)
                          }
                         })}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('parmanent_country_id',parmanent_country_id)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('parmanent_country_id',parmanent_country_id)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(parmanent_zip_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Permanent Zip :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {parmanent_zip}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('parmanent_zip',parmanent_zip)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('parmanent_zip',parmanent_zip)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}    

                      {(parmanent_zip_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Permanent Zip :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {parmanent_zip}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('parmanent_zip',parmanent_zip)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('parmanent_zip',parmanent_zip)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''} */}

                  {(phone_secondary_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Phone Secondary :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {phone_secondary}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('phone_secondary',phone_secondary)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('phone_secondary',phone_secondary)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                      {(email_secondary_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Email Secondary :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {email_secondary}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('email_secondary',email_secondary)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('email_secondary',email_secondary)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                      {(blood_group_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Blood Group :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {blood_group}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('blood_group',blood_group)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('blood_group',blood_group)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(religion_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Religion :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {religion}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('religion',religion)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('religion',religion)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(caste_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Caste :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {caste}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('caste',caste)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('caste',caste)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(nationality_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Nationality :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {nationality}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('nationality',nationality)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('nationality',nationality)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(marital_status_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Marital Status :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {marital_status}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('marital_status',marital_status)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('marital_status',marital_status)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

               {/* {(anniversary_date_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Anniversary Date :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {anniversary_date}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('anniversary_date',anniversary_date)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('anniversary_date',anniversary_date)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''} */}

               {(emergency_contact_person_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Emergency Contact Person :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {emergency_contact_person}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('emergency_contact_person',emergency_contact_person)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('emergency_contact_person',emergency_contact_person)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

               {(emergency_contact_number_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Emergency Contact Number :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {emergency_contact_number}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('emergency_contact_number',emergency_contact_number)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('emergency_contact_number',emergency_contact_number)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(bank_name_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Bank Name :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {bank_name}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('bank_name',bank_name)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('bank_name',bank_name)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

               {(ifsc_code_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>IFSC Code :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {ifsc_code}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('ifsc_code',ifsc_code)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('ifsc_code',ifsc_code)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(account_number_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Account Number :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {account_number}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('account_number',account_number)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('account_number',account_number)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                      {(branch_name_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Branch Name :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {branch_name}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('branch_name',branch_name)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('branch_name',branch_name)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                     
                    {(pan_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Pan Card :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {pan}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('pan',pan)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('pan',pan)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}

                  {(aadhaar_status == 1)?
                    <div className="col-md-6 col-sm-6 col-12" style={{display: 'flex'}}>
                     <div className="col-md-4 col-sm-4 col-4">
                       <label>Aadhar Number :</label>
                     </div>
                     <div className="col-md-5 col-sm-5 col-5">
                        {aadhaar}
                     </div>
                     <div className="col-md-3 col-sm-3 col-3">
                     <input type="button" className="btn btn-success btn-sm" onClick={e=>request_approve('aadhaar',aadhaar)} value="Approve" style={{width: '70px',margin:'3px'}}/>
                       <input type="button" className="btn btn-danger btn-sm" onClick={e=>request_reject('aadhaar',aadhaar)} value="Reject" style={{width: '70px',margin:'3px'}}/>
                     </div>
                    </div>
                     :''}


                   </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employee_requested_details;
