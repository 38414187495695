import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateTimePicker from 'react-datetime-picker';

const Manual_attendance = () => {

    const localuser = JSON.parse(localStorage.getItem('user'));
    let empid = Apiconnect.decrypt(localuser.id);

    const alert = useAlert();
    let history = useHistory();
    const [date, setDate] = useState(new Date());
    const [info, setInfo] = useState({
       remark:'',company_id: Apiconnect.get_company_id(),emp_id:empid
    });
    const {remark} = info;
    const onInputChange = (e) => {
        setInfo({ ...info, [e.target.name]: e.target.value });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
            // info.punch_date = format(date, 'yyyy-MM-dd HH:mm:ss');
           
            // console.log(date);
            // console.log(info);
            // return false;
            // info.punch_date = date;
            info.punch_time = format(date, 'yyyy-MM-dd HH:mm:ss');
            info.mode = 'manual';
            // console.log(format(date, 'yyyy-MM-dd HH:mm:ss'));
            // return false;
            console.log('in add function.....' );
            let info_enc =  Apiconnect.encrypt_obj(info);
            const inf_a = { enc: info_enc };
            await Apiconnect.postData("employee_punchtime/attendance_manual",info).then((response) => {
               setInfo({remark:'',company_id: Apiconnect.get_company_id(),emp_id:empid});
               alert.success(response.data.message);  
               //let _xtract = Apiconnect.decrypt_obj(response.data);
               console.log(response.data); 
            });
    }




    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                       
                        <div className="col-sm-12 col-lg-8">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Manual Attendance</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <form onSubmit={e => onSubmit(e)}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Punch Time</label>
                                                    <DateTimePicker
                                                    onChange={e => setDate(e)}
                                                    value={date}
                                                    name="punch_time"
                                            />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Reason</label>
                                                <textarea rows="4" className="form-control validate[required]" name="remark" value={remark}
                                                    onChange={e => onInputChange(e)}
                                                    placeholder="Enter Reason Here...">
                                                </textarea>
                                            </div>
                                        </div>
                                        </div>

                                        <div className="col-md-12 m-t-20">
                                        <button type="submit"  name="id" className="btn btn-primary mr-2">Submit </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )

}

export default Manual_attendance