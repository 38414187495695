import React, {useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Axios from 'axios';
import Apiconnect from "../../services/Apiconnect.js";



const Employee_list = () =>{
   const local_user = JSON.parse(localStorage.getItem('user'));
   const AuthStr = local_user.accessToken; 
   
   
   useEffect(() => {
      getEmployees();
    }, []);
    

   const [employeeList, setEmployeeList] = useState([]);

   const getEmployees = () => {
      Axios.get(`${process.env.React_App_BASE_URL_API}/api/employee/getall`,{ headers: { Authorization: AuthStr } }).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setEmployeeList(_xtract.reverse());
      });
    };


    


    return ( 
<>
         <div id="content-page" classNme="content-page">
            <div classNme="container-fluid">
               <div classNme="row">
                  <div classNme="col-sm-12 col-lg-12">                      
                     <div classNme="iq-card">
                        <div classNme="iq-card-header d-flex justify-content-between">
                           <div classNme="iq-header-title">
                              <h4  classNme="card-title">Employee List</h4>

                              <Link  className="btn btn-success" to="/employee_add">Add User</Link>
                              
                           </div>
                        </div>
                        <div class="iq-card-body">






                           <table class="table table-hover">
                              <thead>
                                 <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">First Name</th>
                                    <th scope="col">Last Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">City</th>
                                    <th scope="col">Zip</th>
                                    <th scope="col">Action</th>
                                 </tr>
                              </thead>
                              <tbody>



        
                                 
  
{employeeList.map((val, key) => {
       
          return (
            <tr>
               <th scope="row">{key+1}</th>
               <td>{val.name}</td>
               <td>{val.lname}</td>
               <td>{val.email}</td>
               <td>{val.phone}</td>
               <td>{val.address}</td>
               <td>{val.city}</td>
               <td>{val.zip}</td>
               <td>
                  <button className="btn btn-warning mr-2"><i class="fa fa-trash" aria-hidden="true"></i></button>
                  <Link to=""  className="btn btn-primary mr-2" ><i class="fa fa-eye" aria-hidden="true"></i></Link>
                  <Link to=""  className="btn btn- " ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
               </td>
            </tr>

          );
})}







                                 
                                 

                              </tbody>
                           </table>
                           
                        </div>
                     </div>                     
                  </div>                  
               </div>
            </div>
         </div>       
       
          
</>
    )
}

export default Employee_list
