import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Team_member_short_leaves() {

    const alert = useAlert();
    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id);


    useEffect(() => { leavedata(); empdata(); }, []);
    const [leaveList, setleaveList] = useState([]);
    const [empList, setEmpList] = useState([]);

    const leavedata = () => {
        Apiconnect.getData(`leave_short/shortleavereportingdata/${emp}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setleaveList(_xtract.reverse());
        });
    }

    const empdata = () => {
        Apiconnect.getData(`employee_dailydata/employeereportingdata/${emp}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setEmpList(_xtract.reverse());
        });
    }

    var date = new Date();
    const [from_date, setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [to_date, setToDate] = useState(new Date());

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">


                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div>
                                    <h4 className="card-title hd">Team Member Leaves</h4>
                                </div>
                                <div className="iq-card-body">

                                    <div className="row p_t_15">

                                        <div className="col-md-3">
                                            From :  <br /><DatePicker
                                                selected={from_date}
                                                onChange={e => setFromDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="From Date"
                                                name="from_date"
                                                required=""
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            TO :  <br /> <DatePicker
                                                selected={to_date}
                                                onChange={e => setToDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="To Date"
                                                name="to_date"
                                                required=""
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            User : <br />
                                            <select className="form-control" name="emp_id">
                                                <option value="">Select User</option>
                                                {empList.map((val, key) => {
                                                    return (
                                                        <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <input type="button" className="btn btn-success" value="Submit" style={{ marginTop: "23px" }} />
                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="table-responsive">

                                            <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                                <thead>
                                                    <tr className="clr" >
                                                        <th>#</th>
                                                        <th>Emp. Name</th>
                                                        <th>Applied On</th>
                                                        <th>Start Time</th>
                                                        <th>End Time</th>
                                                        <th>Reason</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        leaveList.map((val, key) => {
                                                            return (
                                                                <tr className="" key={key}>
                                                                    <th>{key + 1}</th>
                                                                    <td>{val.first_name} {val.last_name}</td>
                                                                    <td>{val.date}</td>
                                                                    <td>{val.start_time}</td>
                                                                    <td>{val.end_time}</td>
                                                                    <td>{val.reason}</td>
                                                                    <td><div className=" badge badge-pill badge-success">Approved</div></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}

export default Team_member_short_leaves
