import React from 'react';


function Leave_section() {

  
    return (
<>
<div id="content-page" className="content-page">
             <div className="container-fluid">

                  
                <div className="row">
                     <div className="col-sm-12">
                        <div className="iq-card">
                          <div>
                          <h4 className="card-title hd">Leave Sanction</h4>
                     </div>
                     <div className="iq-card-body">

                         <div className="row">

                             <div className="table-responsive">
                           
                           <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                             <thead>
                                 <tr className="clr">
                                    <th><div className="checkbox">
                                      <input className="mr-2" id="all_check" type="checkbox"/></div>
                                   </th>
                                    <th>Emp. Name</th>
                                    <th>Emp. Code</th>
                                    <th>leaves Applied</th>
                                    <th>From Date</th>
                                    <th>To Date</th>
                                    <th>No. of Leaves</th>
                                    <th>Leave Type</th>
                                    <th>Document</th>
                                    <th>Remarks</th>
                                    <th>Available Leaves</th>
                                    <th>Senctioned</th>
                                    <th>Without Type</th>
                                    <th>Comment</th>
                                    <th>Save</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr>
                                    <td><div className="checkbox">
                                      <input className="mr-2 ck" type="checkbox"/></div>
                                    </td>
                                    <td>Causal Leave</td>
                                    <td>41</td>
                                    <td>41</td>
                                    <td>1</td>
                                    <td>Causal Leave</td>
                                    <td>41</td>
                                    <td>41</td>
                                    <td>1</td>
                                    <td>Causal Leave</td>
                                    <td>
                                    </td>
                                    <td><select><option>Select</option></select></td>
                                    <td><div className="checkbox">
                                      <input className="mr-2" type="checkbox"/></div></td>
                                    <td><input type="text" name=""/></td>
                                    <td><button>save</button></td>
                                 </tr> 
                             </tbody>
                           </table>
                        </div>

                         </div>

                     </div>
                  </div>
                  </div>
                </div>

          </div>
        </div> 
       
          
</>
    )
}

export default Leave_section
