import React, { useState, useEffect, useRef } from "react";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loadder from "./Loadder";
import Axios from "axios";
import { CSVLink } from "react-csv";
import Modal from "react-modal";
import moment from 'moment';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import ExportExcel from './Attendance_excel'   

function Monthly_attendance_report() {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp = Apiconnect.decrypt(localuser.id);

  const csvLink = useRef();
  // const [from_date,setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [from_date, setFromDate] = useState(new Date());
  const [emp_id, setempid] = useState(null);
  const [modalLodder, setModalLodder] = useState(false);
  const [dwnEmpAttendanceList, setDwnEmpAttendanceList] = useState([]);

  const onInputChange = (e) => {
    setempid(e.target.value);
  };

  useEffect(() => {
    empdata();
  }, []);
  const [empList, setEmpList] = useState([]);
  const [dailyAttendanceList, setDailyAttendanceList] = useState([]);

  const empdata = () => {
    Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setEmpList(_xtract.reverse());
      }
    );
  };

  const submit = () => {
    if (from_date != null) {
      var from = format(from_date, "yyyy-MM");
    }

    let info = {
      company_id: Apiconnect.get_company_id(),
      selected_date: from,
      emp_id: emp_id,
    };
    // console.log(info);
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };

    setModalLodder("true");
     Apiconnect.postData(`employee/monthlyAttendaceinfo`,inf_a).then((response) => {
    // Axios.post(
    //   `http://localhost:3027/api/employee/monthlyAttendaceinfo`,
    //   inf_a
    // ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setModalLodder(false);
     // var date_period = format(from_date, "MM/dd/yyyy");

      var year = moment(from_date).format('YYYY');
      var month = moment(from_date).format('MM');
      var year_month = moment(from_date).format('YYYY-MM');
      var firstDay =  moment( new Date(year, month-1, 1)).format('DD');
      var lastDay =  moment( new Date(year, month, 0)).format('DD');

      var date_period = month+'/'+firstDay+'/'+year+' To '+month+'/'+lastDay+'/'+year;
      var run_date =  moment().format("MM/DD/YYYY hh:mm a");
      const csvData = [["Report Detail Name", ":Attendance Detail By Date"], 
      ["Run Date & Time", run_date]];
      var header1 = ["Period", date_period,'','',''];

      let shortMonth = from_date.toLocaleString('en-us', { month: 'short' });
      var header = [];
        header.push('Employee Id','Name','Department','Designation','Company');
        _xtract[0].attendance.map((att1,key1)=>{
          if(key1 != 0){
            header1.push(key1+'-'+shortMonth,'','','');
            header.push('In Time','Out Time','Net In Time','Status');
          }
        })
         csvData.push(header1);
         csvData.push(header);

      _xtract.map((val)=>{
        // console.log(val.shift_hour);
       var arr = [];
          arr.push(val.Employee_id,val.Name,val.Department,val.Designation,val.Company);
          val.attendance.map((att,key)=>{
            if(key != 0){

              var production_time = timeConvert(att.production_hour);

              var msg = '';
              var shift_tot = val.shift_hour;
              if(shift_tot == null){
                  var shift_tot = 8;
              }
              var shift_tot2 = shift_tot/2;
              var shift_tot3 = 3/4*shift_tot;

             var shift_h =  shift_porduction(shift_tot);
             var shift_half =  shift_porduction(shift_tot2);
             var shift_half3 =  shift_porduction(shift_tot3);

             

             if (production_time >= shift_h) {
               msg = 'PRESENT';
              } else if (production_time <= shift_h && production_time >= shift_half) {
              } else {
                  msg = 'ABSENT';
              }
              if(shift_h >  production_time && production_time > shift_half3){
                  msg = 'SHORT LEAVE';
              }
              if(shift_half3 >  production_time && production_time > shift_half){
                  msg = 'HALF DAY';
              }
              if (att.punch_count != null) {
                  if (att.punch_count == 1) {
                      var msg = 'PUNCH MISS';
                  }
              }


              
              // console.log(time);
              arr.push(att.start_time,att.end_time,production_time,msg);
            }
        })
        csvData.push(arr);
      })
              setDwnEmpAttendanceList(csvData);
              csvLink.current.link.click();
    });
  };

  function timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    if(rhours.toString().length <= 1){
        rhours = '0'+rhours;
    }
    if(rminutes == 0){
        rminutes = '00';
    }
    if(rminutes.toString().length <= 1){
      rminutes = '0'+rminutes;
    }
    
    if(n == ''){
      return '';
    }else{
      return rhours + ":" + rminutes;
    }

  
    }

    const shift_porduction = (num) => {

      var hours = num;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if(rhours.toString().length <= 1){
          rhours = '0'+rhours;
      }
      if(rminutes == 0){
          rminutes = '00';
      }
      return  rhours + ":" + rminutes;

  }

  const customStyles1 = {
    content: {
      top: "55%",
      width: "60%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "unset ",
      backgroundColor: "unset ",
    },
  };



  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            {/* ////////////------------>Lodder<------------////////////////// */}
            <div>
              <Modal isOpen={modalLodder} style={customStyles1}>
                {<Loadder />}
              </Modal>
            </div>
            {/* ////////////------------>Lodder<------------////////////////// */}

            <div className="col-sm-12">
              <div className="iq-card">
                <div>
                  <h4 className="card-title hd">Monthly Atteandance</h4>
                </div>
                <div className="iq-card-body">

         

                  <div className="row p_t_15">
                    <div className="col-md-3">
                      Date : <br />
                      <DatePicker
                        selected={from_date}
                        onChange={(date) => setFromDate(date)}
                        maxDate={new Date()}
                        dateFormat="MM/yyyy"
                        className="form-control"
                        showMonthYearPicker
                      />
                    </div>

                    <div className="col-md-3">
                      User : <br />
                      <select
                        className="form-control"
                        name="emp_id"
                        value={emp_id}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option value="">Select User</option>
                        {empList.map((val, key) => {
                          return (
                            <option key={key} value={val.id}>
                              {val.first_name} {val.last_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="button"
                        className="btn btn-success"
                        value="Submit And Download"
                        style={{ marginTop: "23px" }}
                        onClick={(e) => submit(e)}
                      />
                      <CSVLink
                        data={dwnEmpAttendanceList}
                        filename="employee-attendance.csv"
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      />
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "25px" }}>
                    <div className="col-lg-12">
                      <div className="iq-card"></div>
                    </div>
                  </div>


                  {/* <div className="App">  
                      <ExportExcel/>  
                  </div>   */}


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Monthly_attendance_report;
