import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";

const Loadder_progress = (props) => {
    var percentage = Math.round((props.sync_count+props.noSync)/props.total*100);
  return (
    <loaddercontainer style={{ textAlign: "center" }}>
      <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
      <div style={{ color: "black", background: "white",margin:'10px',padding:'30px',barderRadius:'10px' }}>
        <div className="progress" style={{margin: '10px'}}>
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            style={{ width: percentage+"%"}}
            aria-valuenow="5"
            aria-valuemin="0"
            aria-valuemax="100"
          >{percentage+"%"}</div>

        </div>
        <b>Synced : </b> <span style={{color:'green'}}>{props.sync_count}</span> &nbsp;&nbsp; <b>Not Synced : </b>
        <span style={{color:'red'}}>{props.noSync}</span> &nbsp;&nbsp; <b>Total Employee :</b> {props.total}
      </div>
    </loaddercontainer>
  );
};

export default Loadder_progress;
