import React from 'react';

import Dashboard from './admin_component/pages/Dashboard';
import Dashboard_superadmin from './admin_component/pages/Dashboard_superadmin';
import Test from './admin_component/pages/test';
import Formula_list from './admin_component/pages/Formula_list';
import Formula_add from './admin_component/pages/Formula_add';
import Formula_edit from './admin_component/pages/Formula_edit';
 
import Employee_list from './admin_component/pages/Employee_list';
import Employee_add from './admin_component/pages/Employee_add';
import Employee_edit from './admin_component/pages/Employee_edit';

import Company from './admin_component/pages/Company';
import Company_list from './admin_component/pages/Company_list';
import Company_edit from './admin_component/pages/Company_edit';
import Company_category from './admin_component/pages/Company_category';
import Leaves_assign from './admin_component/pages/Leaves_assign';
import Shift_assign from './admin_component/pages/Shift_assign';
import Company_holiday from './admin_component/pages/Company_holiday';
import Weekly_off from './admin_component/pages/Weekly_off';
import Leave_policy from './admin_component/pages/Leaves_policy';
import Earning_head from './admin_component/pages/Earning_head';
import Deduction_head from './admin_component/pages/Deduction_head';
import Reimbursement from './admin_component/pages/Reimbursement';
import Perk_head from './admin_component/pages/Perk_head';
import Shift from './admin_component/pages/Shift';

import Employee_manage from './admin_component/pages/Employee_manage';
import Employee_manage_info from './admin_component/pages/Employee_manage_info';
import Employee_manage_bank from './admin_component/pages/Employee_manage_bank';
import Employee_manage_employement_info from './admin_component/pages/Employee_manage_employement_info';
import Employee_manage_salary_info from './admin_component/pages/Employee_manage_salary_info';
import Employee_manage_sal from './admin_component/pages/Employee_manage_sal';
import Employee_manage_reimbursement from './admin_component/pages/Employee_manage_reimbursement';
import Employee_month_sal from './admin_component/pages/Employee_month_sal';
import Employee_manage_doc from './admin_component/pages/Employee_manage_doc';

import Employee_salary_list from './admin_component/pages/Employee_salary_list';
import Upload from './admin_component/pages/Upload';

import Employee_manage_list from './admin_component/pages/Employee_manage_list';
import ResetPassword from './admin_component/pages/ResetPassword';
import Team_member_leaves from './admin_component/pages/Team_member_leaves';
import Manual_leaves from './admin_component/pages/Manual_leaves';

import Employee_salary_template from './admin_component/pages/Employee_salary_template';
import Employee_salary_template_edit from './admin_component/pages/Employee_salary_template_edit';
import Employee_salary_template_list from './admin_component/pages/Employee_salary_template_list';

import Employee_bulk_upload from './admin_component/pages/Employee_bulk_upload';
import Employee_image_upload from './admin_component/pages/Employee_image_upload';
import Fr_image_upload from './admin_component/pages/Fr_image_upload';
import Company_upload_logo from './admin_component/pages/Company_upload_logo';

import Employee_requested_thumb from './admin_component/pages/Employee_requested_thumb';
import Employee_requested_details from './admin_component/pages/Employee_requested_details';




import Area from './admin_component/pages/Area';
import City from './admin_component/pages/City';

import Employee_punchtime_report from './admin_component/pages/Employee_punchtime_report';
import Employee_attendance_report from './admin_component/pages/Employee_attendance_report';
import Monthly_attendance_report from './admin_component/pages/Monthly_attendance_report';

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/superadmin', name: 'Dashboard', component: Dashboard_superadmin },

  { path: '/upload', name: 'Upload', component: Upload },
  { path: '/punchtime_report', name: 'upload', component: Employee_punchtime_report },
  { path: '/daily_attendance_report', name: 'upload', component: Employee_attendance_report },
  { path: '/monthly_attendance_report', name: 'upload', component: Monthly_attendance_report },

  { path: '/Test/:id?', name: 'Test', component: Test },
  { path: '/employee_manage_sal/:id', name:'Emloyee Salary',component:Employee_manage_sal},
  { path: '/employee_manage_reimbursement/:id', name:'Emloyee Salary',component:Employee_manage_reimbursement},
  { path: '/employee_month_sal/:id?', name:'Emloyee Salary',component:Employee_month_sal},

  { path: '/formula_list', name: 'formula_list', component: Formula_list },
  { path: '/formula_add/', name: 'formula_add', component: Formula_add },
  { path: '/formula_edit/:id', name: 'formula_edit', component: Formula_edit },

  { path: '/employee_add', name: 'Employee Add', component: Employee_add },
  { path: '/employee_edit', name: 'Employee Add', component: Employee_edit },
  { path: '/employee_list', name: 'Employee List', component: Employee_list },
  { path: '/company', name: 'Company', component: Company },
  { path: '/company_category', name: 'Company Category', component: Company_category },
  { path: '/leaveassign/:id', name: 'Company Category', component: Leaves_assign },
  { path: '/shiftassign/:id', name: 'Shift Assign', component: Shift_assign },


  { path: '/company_list', name: 'Company List', component: Company_list },
  { path: '/company_edit/:id', name: 'Company Edit', component: Company_edit },

  { path: '/company_holiday', name: 'Company Holiday', component: Company_holiday },
  { path: '/weekly_off', name: 'Weekly Off', component: Weekly_off },
  { path: '/leave_policy', name: 'Leave Policy', component: Leave_policy },
  { path: '/earning_head', name: 'Earning head', component: Earning_head },
  { path: '/deduction_head', name: 'Deduction head', component: Deduction_head },
  { path: '/reimbursement', name: 'Reimbursement', component: Reimbursement },
  { path: '/Perk_head', name: 'Perk head', component: Perk_head },
  { path: '/Shift', name: 'Shift', component: Shift },

  { path: '/employee_manage/:id', name:'Emloyee Manage',component:Employee_manage},
  { path: '/employee_manage_info/:id', name:'Emloyee Manage',component:Employee_manage_info},
  { path: '/employee_manage_bank/:id', name:'Emloyee Manage',component:Employee_manage_bank},
  { path: '/employee_manage_employement/:id', name:'Emloyee Manage',component:Employee_manage_employement_info},
  { path: '/employee_manage_salary/:id', name:'Emloyee Manage',component:Employee_manage_salary_info}, 
  { path: '/employee_manage_doc/:id', name:'Emloyee Docs',component:Employee_manage_doc}, 
  

  { path: '/employee_manage_list', name:'Emloyee Manage',component:Employee_manage_list},
  { path: '/resetPassword', name: 'Dashboard', component: ResetPassword },

  { path: '/salary_list', name: 'Salary List', component: Employee_salary_list },
  { path: '/manual_leaves', name: 'Dashboard', component: Manual_leaves },

  { path: '/team_member_leaves', name: 'Dashboard', component: Team_member_leaves },

  { path: '/salary_template', name: 'Dashboard', component: Employee_salary_template },
  { path: '/salary_template_edit/:id', name: 'Dashboard', component: Employee_salary_template_edit },
  { path: '/salary_template_list', name: 'Dashboard', component: Employee_salary_template_list },

  { path: '/area', name: 'Dashboard', component: Area },
  { path: '/city', name: 'Dashboard', component: City },

  { path: '/employee_upload_bulk', name: 'upload', component: Employee_bulk_upload },
  { path: '/employee_upload_image/:id?', name: 'upload', component: Employee_image_upload },
  { path: '/fr_upload_image/:id?', name: 'upload', component: Fr_image_upload },
  { path: '/company_upload_logo/:id?', name: 'upload', component: Company_upload_logo },
  { path: '/employee_requested_thumb/:id?', name: 'upload', component: Employee_requested_thumb },
  { path: '/employee_requested_details/:id?', name: 'upload', component: Employee_requested_details },

];


export default routes;
