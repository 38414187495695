import React, {useState} from 'react';
import Axios from 'axios';
import {Link} from 'react-router-dom';
import { useHistory } from "react-router-dom";



const Employee_add = () =>{
   let history = useHistory();
   const [user, setUser] = useState({
      name: "",
      lname: "",
      email: "",  
      phone: "",
      address: "",
      city: "",
      state: "",
      zip: "",
    });
  
    const { name, lname, email, phone, address, city, state, zip } = user;
    const onInputChange = e => {
      setUser({ ...user, [e.target.name]: e.target.value });
    };


    const onSubmit = async e => {
      e.preventDefault();
      await Axios.post("https://phpstack-181417-2015125.cloudwaysapps.com/api/employee/create", user);
      history.push("/employee_list");
    };


    return (
<>
         <div id="content-page" class="content-page">
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12 col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Add Employee</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                          
                          
                        <form onSubmit={e => onSubmit(e)}>
                           
                          <div class="row">
                              <div class="form-group col-md-3">
                                 <label >First Name:</label>
                                 <input type="text" required placeholder="Add first Name" class="form-control" 
                                name="name"
                                value={name}
                                onChange={e => onInputChange(e)}
                                    
                                 />
                              </div>
                              <div class="form-group col-md-3">
                                 <label >Last Name:</label>
                                 <input type="text" required placeholder="Add Last Name"  class="form-control"  
                                 name="lname"
                                 value={lname}
                                 onChange={e => onInputChange(e)}                                  
                                 />
                              </div>
                              <div class="form-group col-md-3">
                                 <label >Email:</label>
                                 <input type="text" required placeholder="EX:abc@xyz.com"  class="form-control"  
                                 name="email"
                                 value={email}
                                 onChange={e => onInputChange(e)}
                                 />
                              </div>
                              <div class="form-group col-md-3">
                                 <label >Phone:</label>
                                 <input type="text" required class="form-control"  
                                    name="phone"
                                    value={phone}
                                    onChange={e => onInputChange(e)}
                                />
                              </div>

                              <div class="form-group col-md-3">
                                 <label >Address:</label>
                                 <input type="text" required class="form-control"  
                                    name="address"
                                    value={address}
                                    onChange={e => onInputChange(e)}
                                  />
                              </div>
                              <div class="form-group col-md-3">
                                 <label >City:</label>
                                 <input type="text" required class="form-control"  
                                  name="city"
                                  value={city}
                                  onChange={e => onInputChange(e)}
                                   
                                  />
                              </div>
                              <div class="form-group col-md-3">
                                 <label >State:</label>
                                 <input type="text" required class="form-control"   
                                  name="state"
                                  value={state}
                                  onChange={e => onInputChange(e)}
                                  />
                              </div>
                              <div class="form-group col-md-3">
                                 <label >Zip:</label>
                                 <input type="text" required max_length="6" placeholder="Ex:751006"  class="form-control"  
                                  name="zip"
                                  value={zip}
                                  onChange={e => onInputChange(e)}  
                                  />
                              </div> 

                              <div class="form-group  col-md-12">
                              <button type="submit"  class="btn btn-primary mr-2">Submit</button>

                              <Link  className="btn btn-info" to="/employee_list">Back to list</Link>
                              </div>
                               
                           </div>

                        </form>







                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>      
       
          
</>
    )
}

export default Employee_add
