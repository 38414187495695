import React, { useState, useEffect,useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { Link } from 'react-router-dom';
import "./admin.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-modal';
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import Loadder from "./Loadder";
import Loadder_progress from "./Loadder_progress";
import Loadder_progress_bar from "./Loadder_progress_bar";
import Employee_manage_view from './Employee_manage_view.js';
import Axios from 'axios';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { format } from "date-fns";

const Employee_manage_list = () => {

   const alert = useAlert();
   let history = useHistory();
   const localuser = JSON.parse(localStorage.getItem('user'));
   let employee_id = Apiconnect.decrypt(localuser.id);
   let AuthStr = localuser.accessToken;
   
   const [loader, setLoader] = useState(false);
   const [loader2, setLoader2] = useState(false);

   useEffect(() => { getEmployeeList(); getCategory(); getEmployees(); getEmployeeSalary();getEmployeeList_new('50','1','');getEmpCount(''); }, []);
   const [List, setEmployeeList] = useState([]);
   const [catList, setCategory] = useState([]);
   const [dept_id, setDept] = useState(null);
   const [shift_id, setShiftId] = useState(null);
   const [allchecked, setallchecked] = useState(null);
   const [selectedEmp, setSelectedEmp] = useState([]);
   // const [selectedCountEmp, setSelectedCountEmp] = useState([]);
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [modalEmpIsOpen, setEmpModalIsOpen] = useState(false);

   const [modalLodder, setModalLodder] = useState(false);
   const [modalProgressLodder, setModalProgressLodder] = useState(false);
   
   const [shiftList, setShift] = useState([]);
   const [dwnEmpList, setDwnEmpList] = useState([]);
   const [dwnEmpSalaryList, setDwnEmpSalaryList] = useState([]);
   const [dwnEmpAttendanceList, setDwnEmpAttendanceList] = useState([]);


   const csvLink = useRef()

   const getEmployeeList = () => {
      // setLoader(true);
      // Apiconnect.getData(`employee/getall/${Apiconnect.get_company_id()}`).then((response) => {
      //    let _xtract = Apiconnect.decrypt_obj(response.data.data);
      //    setEmployeeList(_xtract.reverse());
      //    setLoader(false);
      // });
   }

    ////--------> Start Pagination And Search<--------/////

   const [activePage, setActivePage] = useState(1);
   const handlePageChange = (pageNumber) => {
      setActivePage(pageNumber);
      let offset = (pageNumber - 1) * 50 + 1;
      console.log(`active page is ${pageNumber} -- ${offset}`);
      let limit = 50;
      getEmployeeList_new(limit,offset,'');

    }

    const getEmployeeList_new = (limit,offset,search) => {
       setLoader(true);
      const data = {limit:limit,offset:offset,search:search,company_id:Apiconnect.get_company_id()};
     // Axios.post(`http://localhost:3050/api/city/getallcitylist`,data).then((response) => {
     Apiconnect.postData(`employee/getallemployeelist`,data).then((response) => {
         let _xtract =Apiconnect.decrypt_obj(response.data.data);
         setEmployeeList(_xtract.reverse());     
         setLoader(false);
      });
   };

   const [empCount, setempCount] = useState(0);
   const getEmpCount = (search) => {
       Apiconnect.getData(`employee/getEmpSearchcount/${Apiconnect.get_company_id()}/${search}`).then((response) => {
         let _xtract =Apiconnect.decrypt_obj(response.data.data);
         setempCount(_xtract[0].cnt); 
      });
   };

   const inputsearch =  e => {
      let search = e.target.value;
      console.log(search);
      let offset = (activePage - 1) * 50 + 1;
      let limit = 50;

      console.log(offset);
      console.log(limit);

      getEmployeeList_new(limit,offset,search);
         // Axios.get(`http://localhost:3050/api/tush/getcitycount/${search}`).then((response) => {
            Apiconnect.getData(`employee/getEmpSearchcount/${Apiconnect.get_company_id()}/${search}`).then((response) => {
                let _xtract = Apiconnect.decrypt_obj(response.data.data);
                setempCount(_xtract[0].cnt); 
             });
   }

   ////--------> End Pagination And Search<--------/////
  

   const getEmployeeListByDept = (id) => {
      setLoader(true);
      Apiconnect.getData(`employee/getallbydept/${id}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setEmployeeList(_xtract.reverse());
         setLoader(false);
      });
   }

   const getEmployees = () => {
      var company_id = Apiconnect.get_company_id();
      Apiconnect.getData(`employee/allempinfo/${company_id}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setDwnEmpList(_xtract.reverse());
      });
   }

   const getEmployeeSalary = () => {
      var company_id = Apiconnect.get_company_id();
      Apiconnect.getData(`employee/allempsalaryinfo/${company_id}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setDwnEmpSalaryList(_xtract.reverse());
      });
      
   }
   const getEmployeeAttendance = () => {

      setModalLodder('true');
      var company_id = Apiconnect.get_company_id();
       Apiconnect.getData(`employee/allempAttendaceinfo/${company_id}`).then((response) => {
      //  Axios.get(`http://localhost:3050/api/employee/allempAttendaceinfo/${company_id}`).then((response) => {

              let _xtract = Apiconnect.decrypt_obj(response.data.data);
               var from_date = new Date();
              var year = moment(from_date).format('YYYY');
              var month = moment(from_date).format('MM');
              var year_month = moment(from_date).format('YYYY-MM');
              var firstDay =  moment( new Date(year, month-1, 1)).format('DD');
              var lastDay =  moment( new Date(year, month, 0)).format('DD');
        
              var date_period = month+'/'+firstDay+'/'+year+' To '+month+'/'+lastDay+'/'+year;
              var run_date =  moment().format("MM/DD/YYYY hh:mm a");
              const csvData = [["Report Detail Name", ":Attendance Detail By Date"], 
              ["Run Date & Time", run_date]];
              var header1 = ["Period", date_period,'','',''];
        
              let shortMonth = from_date.toLocaleString('en-us', { month: 'short' });
              var header = [];
                header.push('Employee Id','Name','Department','Designation','Company');
                _xtract[0].attendance.map((att1,key1)=>{
                  if(key1 != 0){
                    header1.push(key1+'-'+shortMonth,'','','');
                    header.push('In Time','Out Time','Net In Time','Status');
                  }
                })
                 csvData.push(header1);
                 csvData.push(header);
        
              _xtract.map((val)=>{
               var arr = [];
                  arr.push(val.Employee_id,val.Name,val.Department,val.Designation,val.Company);
                  val.attendance.map((att,key)=>{
                    if(key != 0){
                     
                      var production_time = timeConvert(att.production_hour);
        
                      var msg = '';
                      var shift_tot = val.shift_hour;
                      if(shift_tot == null){
                          var shift_tot = 8;
                      }
                      var shift_tot2 = shift_tot/2;
                      var shift_tot3 = 3/4*shift_tot;
        
                     var shift_h =  shift_porduction(shift_tot);
                     var shift_half =  shift_porduction(shift_tot2);
                     var shift_half3 =  shift_porduction(shift_tot3);
        
                     
        
                     if (production_time >= shift_h) {
                       msg = 'PRESENT';
                      } else if (production_time <= shift_h && production_time >= shift_half) {
                      } else {
                          msg = 'ABSENT';
                      }
                      if(shift_h >  production_time && production_time > shift_half3){
                          msg = 'SHORT LEAVE';
                      }
                      if(shift_half3 >  production_time && production_time > shift_half){
                          msg = 'HALF DAY';
                      }
                      if (att.punch_count != null) {
                          if (att.punch_count == 1) {
                              var msg = 'PUNCH MISS';
                          }
                      }
        
                      arr.push(att.start_time,att.end_time,production_time,msg);
                    }
                })
                csvData.push(arr);
              })

            setDwnEmpAttendanceList(csvData);
            // setChangeButtonAtt('false');
            setModalLodder(false);
            csvLink.current.link.click();
      });
      
   }

   function timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
  
      if(rhours.toString().length <= 1){
          rhours = '0'+rhours;
      }
      if(rminutes == 0){
          rminutes = '00';
      }
      if(rminutes.toString().length <= 1){
        rminutes = '0'+rminutes;
      }
      
      if(n === '' ){
        return '';
      }else if(n === 0){
         return 0;
      }else{
         return rhours + ":" + rminutes;
      }
  
    
      }
  
      const shift_porduction = (num) => {
  
        var hours = num;
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if(rhours.toString().length <= 1){
            rhours = '0'+rhours;
        }
        if(rminutes == 0){
            rminutes = '00';
        }
        return  rhours + ":" + rminutes;
  
    }
  

   const attendance_popup = e => {

      confirmAlert({
         message: 'Download Employee Attendance',
         buttons: [
            {
               label: 'Yes',
               onClick: () => getEmployeeAttendance(),
            },
            {
               label: 'No',
            }
         ]
      });


   }


   const getCategory = () => {
      var company_id = Apiconnect.get_company_id();
      Apiconnect.getData(`company_category/getall/${company_id}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setCategory(_xtract.reverse());
      });
   }

   const onDeptChange = e => {
      let dept_id = e.target.value;
      getEmployeeListByDept(dept_id);
      setDept(dept_id);
      console.log(e.target.value);
   };

   const onShiftChange = e => {
      let shift_id = e.target.value;
      setShiftId(shift_id);
      //console.log(e.target.value);
   };


   const onInputChange2 = e => {

      setallchecked(null);

      if (e.target.checked === true) {
         setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: e.currentTarget.value });
         // setSelectedCountEmp({ ...selectedCountEmp, [e.currentTarget.name]: e.currentTarget.value });
      } else {
         setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 });

         // let ck12 = Object.keys(selectedCountEmp).find(function (element) {
         //    if(selectedCountEmp[element] == e.currentTarget.name){
         //       delete selectedCountEmp[element];
         //    } 
         // });
      }
   };


   const sellectAll = e => {
      if (e.target.checked === true) {
         // console.log(e.target.checked);
         setallchecked('checked');
         let ob = {};
         List.map((val, key) => {
            ob[val.id] = val.id;
         });
         setSelectedEmp(ob);
         // setSelectedCountEmp(ob);

      } else {
         setallchecked(null);
         setSelectedEmp([]);
         // setSelectedCountEmp([]);
      }
   }
   
   // const sync_attendace = async (e) =>   {
   //    setModalLodder(true);
   //    var formData = new FormData();
   //    formData.append('company_domain', process.env.React_App_DETECTION_DOMAIN);
   //    Axios.post(`${process.env.React_App_DETECTION_API}/restoreEmployee`, formData)
   //      .then(response =>{
   //       console.log(response)
   //       if(response.data.Status === "Success"){
   //          setModalLodder(false);
   //          alert.success('Successfully Sync');
   //       }else{
   //          setModalLodder(false);
   //          alert.success('Something Went Wrong');
   //       }
   //      });
   // }


   const [progressCount, setProgressCount] = useState(0);
   const [totalCount, setTotalCount] = useState(0);
   const [noSync, setNoSync] = useState(0);

    const sync_attendace_new =async (e) =>   {

       if (selectedEmp == '') {
         confirmAlert({
            title: 'Select Employee',
            buttons: [
               {
                  label: 'OK',
               }
            ]
         });
         return false;
      } 

      setTotalCount(Object.keys(selectedEmp).length);

      setModalProgressLodder(true);
      var emp_data =  Object.keys(selectedEmp);
      setProgressCount(0);
      var cnt = 0;
      var cnt1 = 0;
     for (var emp_id  of emp_data ) {
       if(emp_id != 0){
               var formData = new FormData();
               formData.append('company_domain', process.env.React_App_DETECTION_DOMAIN);
               formData.append('emp_id', emp_id);
              var res = await Axios.post(`${process.env.React_App_DETECTION_API}/restoreSingleEmployee`, formData)
                  .then(response =>{
                     return response;
                  });
                  if(res.data.Status == 'Success'){
                     setProgressCount(++cnt);
                  }else{
                     setNoSync(++cnt1);
                  }
         }
      };
      alert.success('Successfully Sync');
         //   setModalProgressLodder(false);
   }

   var percentage = Math.round((progressCount+noSync)/totalCount*100);
   if(percentage == 100){
      
      var timerId = setTimeout(() => {
         setModalProgressLodder(false);
        timerId = null;
      }, 3000);
   }

  

   const shift_popup = e => {

      if (selectedEmp != '') {
         setModalIsOpen(true);
         getShift();
         // console.log(selectedEmp);
      } else {
         confirmAlert({
            title: 'Select Employee',
            buttons: [
               {
                  label: 'OK',
               }
            ]
         });
      }
   }

   const getShift = () => {
      Apiconnect.getData(`shift/getall/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setShift(_xtract.reverse());
      });
   }

   const ondlt = (id) => {
      confirmAlert({
         title: 'Are you sure to Delete?',
         buttons: [
            {
               label: 'Yes',
               onClick: () => deleteItem(id),
            },
            {
               label: 'No',
            }
         ]
      });
   };
   const deleteItem = (id) => {

      Apiconnect.deleteData(`employee/delete/${id}/${employee_id}`).then((response) => {
         getEmployeeList();
         alert.success(response.data.message);
      });
   };

   const set_attendance = (id) => {
      confirmAlert({
         title: 'Are you sure to set this as Attendance Image ?',
         message: 'This will be used for face recognition while marking attendance.',
         buttons: [
            {
               label: 'Yes',
               onClick: () => copy_profile_to_attendance(id),
            },
            {
               label: 'No',
            }
         ]
      });
   };

   const copy_profile_to_attendance = (id) => {

      Apiconnect.getData(`employee_info/copy_profile_to_attendance/${id}`).then((response) => {
         sync_attendace(id);
         getEmployeeList();
         alert.success(response.data.message);
      });
   };

   const sync_attendace =async (emp_id) =>   {
      if(emp_id != 0){
              var formData = new FormData();
              formData.append('company_domain', process.env.React_App_DETECTION_DOMAIN);
              formData.append('emp_id', emp_id);
             var d = await Axios.post(`${process.env.React_App_DETECTION_API}/restoreSingleEmployee`, formData)
                 .then(response =>{
                    return emp_id;
                 });
   }
        alert.success('Successfully Sync');

  }


   const onSubmit = async e => {
      e.preventDefault();

      const inf_a = { shift_id: shift_id, employee: selectedEmp };
      console.log(inf_a);
      await Apiconnect.postData("employee/shift_assign_update", inf_a).then((response) => { });
      alert.success('Shift Assign Successfully');
      setModalIsOpen(false)
      setSelectedEmp([]);
      // setSelectedCountEmp([]);
      setallchecked(null);

      if (dept_id !== null) {
         getEmployeeListByDept(dept_id);
      } else {
         getEmployeeList();
      }

   };

   const customStyles = {
      content: {
         top: '55%', width: '60%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',

      },
   };

   const customStyles1 = {
      content: {
         top: '55%', width: '60%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         border:'unset ',
         backgroundColor:'unset ',
      },
   };

       ///////---------->Start Employee View<-----------/////////
      const [viewId, setviewId] = useState(null);

      const viewItem = (id) => {
         setviewId(id);
         setEmpModalIsOpen(true);
      }
      ///////---------->End Employee View<-----------/////////

      //console.log(List);

      // background-color: rgba(0, 0, 0, 0.75) !important;    position: fixed;inset: 0px;

   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">


            {/* ///////---------->Start Employee View<-----------///////// */}


               <div>
                  <Modal isOpen={modalEmpIsOpen} style={customStyles} onRequestClose={() => setEmpModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Employee Details</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <div className="row">
                              <div className="col-md-12">
                                 {viewId !== null? <Employee_manage_view emp_id={viewId}/>:''}
                              </div>
                           </div>
                        </div>
                        <div className="col-md-12 mt-2">
                           <button className="btn btn-info" onClick={() => setEmpModalIsOpen(false)}>Cancel</button>
                        </div>
                     </div>
                  </Modal>
               </div>

         {/* ////////////------------>Lodder<------------////////////////// */}
               <div >
                  <Modal isOpen={modalLodder} style={customStyles1}>
                                 {<Loadder/>}
                  </Modal>
               </div>
          {/* ////////////------------>Lodder<------------////////////////// */}

              {/* ////////////------------>Lodder<------------////////////////// */}
              <div >
                  <Modal isOpen={modalProgressLodder} style={customStyles1}>
                                 {<Loadder_progress sync_count={progressCount} noSync={noSync} total={totalCount} />}
                  </Modal>
               </div>
          {/* ////////////------------>Lodder<------------////////////////// */}

            {/* ////////////------------>Lodder<------------////////////////// */}
            {/* <div >
                  <Modal isOpen={modalProgressLodder1} style={customStyles1}>
                                 {<Loadder_progress_bar sync_count={progressCount1} noSync="0" total={totalCount1} />}
                  </Modal>
               </div> */}
          {/* ////////////------------>Lodder<------------////////////////// */}

          


               {/* ///////---------->End Employee View<-----------///////// */}

               <div>
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Assign Shift</h4>
                           </div>
                        </div>

                        <div className="iq-card-body">
                           <form onSubmit={e => onSubmit(e)} >
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="form-group">
                                       <label>Shift <span className="required">*</span></label>
                                       <select className="form-control" required name="shift_id" value={shift_id} onChange={e => onShiftChange(e)}>
                                          <option value="">Select Shift</option>
                                          {
                                             shiftList.map((val, key) => {
                                                return (
                                                   <option key={key} value={val.id}>{val.name}</option>
                                                )
                                             })
                                          }
                                       </select>
                                    </div>
                                 </div>

                              </div>
                              <div className="col-md-12 mt-2">
                                 <button type="submit" name="id" className="btn btn-primary mr-2">Submit</button>
                                 <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>Cancel</button>
                              </div>
                           </form>
                        </div>
                        {/* <div className="text_center">
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div> */}
                     </div>
                  </Modal>
               </div>

               <div className="row">
                  <div className="col-sm-12 col-lg-12 col-md-12">
                        
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div style={{ float: 'left' }} className="iq-header-title">
                              <h4 className="card-title">List Of Employee</h4>
                           </div>
                           <div style={{ float: 'right' }}>
                              <Link style={{ float: 'right', marginLeft: '5px' }} className="btn btn-success" to="/employee_manage/0" >Add New</Link>
                              <Link style={{ float: 'right' }} className="btn btn-info" to="/employee_upload_bulk">Upload Bulk</Link>
                           </div>

                        </div>
                        <div className="iq-card-body">
                        {/* <div className="" style={{float:'right'}}>
                        <div className="col-md-2">
                            <button style={{ marginTop: '30px' }} onClick={() => { shift_popup(); }}
                                    className="btn btn-info mr-2">Shift Assign</button>
                              </div>
                        </div> */}
                           <div className="row">
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Department</label>
                                    <select className="form-control" required name="emp_category" value={dept_id} onChange={e => onDeptChange(e)}>
                                       <option value="">Select Department</option>
                                       {
                                          catList.map((val, key) => {
                                             return (
                                                <option key={key} value={val.id}>{val.name}</option>
                                             )
                                          })
                                       }
                                    </select>
                                 </div>
                              </div>
                              <div className="col-md-2">
                                 <button style={{ marginTop: '30px' }} onClick={() => { shift_popup(); }}
                                    className="btn btn-info mr-2">Shift Assign</button>
                              </div>

                              <div className="col-md-7">

                                 <table style={{width:'100%'}}>
                                    <tr>
                                    <td>  <CSVLink filename={"employee-data.csv"} style={{ marginTop: '20px', float: 'right' }} className="btn btn-warning btn-sm" data={dwnEmpList}  >
                                    <i class="fa fa-download"></i>Employee Data
                                 </CSVLink></td>
                                       <td> <CSVLink filename={"employee-salary.csv"} style={{ marginTop: '20px', float: 'right', marginLeft: '5px' }} className="btn btn-sm btn-primary" data={dwnEmpSalaryList}  >
                                    <i class="fa fa-download"></i>Employee Salary
                                 </CSVLink></td>
                                       <td>
                                       <div>
                                          <button className="btn btn-info btn-sm mr-2 " onClick={() => { attendance_popup(); }} style={{ marginTop: '20px', float: 'right', marginLeft: '5px' }}><i class="fa fa-download"></i> Employee Attendance</button>
                                          <CSVLink
                                             data={dwnEmpAttendanceList}
                                             filename='employee-attendance.csv'
                                             className='hidden'
                                             ref={csvLink}
                                             target='_blank'
                                          />
                                       </div>

                                          {/* {
                                             (changeButtonAtt === 'false')?
                                             <CSVLink filename={"employee-attendance.csv"} style={{ marginTop: '20px', float: 'right', marginLeft: '5px' }} className="btn btn-sm btn-info" data={dwnEmpAttendanceList}  >
                                                 <i class="fa fa-download"></i>Employee Attendance12
                                             </CSVLink>:
                                             <button style={{ marginTop: '20px',float: 'right', marginLeft: '5px' }} onClick={() => { attendance_popup(); }} className="btn btn-info btn-sm mr-2 ">Employee Attendance</button>
                                             
                                          } */}
                                       

                                         {/* <CSVLink filename={"employee-attendance.csv"} style={{ marginTop: '20px', float: 'right', marginLeft: '5px' }} className="btn btn-sm btn-info" data={dwnEmpAttendanceList}  >
                                            <i class="fa fa-download"></i>Employee Attendance
                                         </CSVLink> */}

                                       </td>
                                     <td> <button style={{ marginTop: '20px',float: 'right',backgroundColor:'#f7786b',borderColor:'#f7786b' }} onClick={() => { sync_attendace_new(); }}
                                    className="btn btn-info btn-sm mr-2">Sync Attendance images</button></td>
                                    </tr>
                                 </table>
                             

                                
                               
                                 
                                

                              </div>
                             
                              {/* <div className="col-md-2">
                                
                              </div> */}


                           </div>
                         
                           <div style={{padding:'10px',textAlign:'right'}}>   
                             <div><input type="text" placeholder='Search here...' name="search" onChange={e => inputsearch(e)}/></div>
                            
                           </div>

                          

                           <table className="table table-striped table-hover">
                              <thead className="">
                                 <tr>
                                    <th scope="col"><input type="checkbox" name="select_all" checked={allchecked} onChange={e => sellectAll(e)} /></th>
                                    <th scope="col">#</th>
                                    <th scope="col" style={{textAlign:'center'}}>Profile</th>
                                    <th scope="col" style={{textAlign:'center'}}>Attendance Profile</th>
                                    <th scope="col">Emp Code</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Mobile</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Action</th>
                                 </tr>
                              </thead>
                              <tbody className="">
                                 {
                                   loader ? <Loadder/>:
                                 List.map((val, key) => {
                                    if(val.display_picture == null){
                                       var profile_img = '/images/user/1.jpg'; 
                                    }else{
                                       const min = 1;
                                       const max = 1000;
                                       var rand = min + Math.random() * (max - min);
                                       var profile_img = process.env.React_App_BASE_URL_API+'/uploads/thumbdp/'+val.display_picture+'?'+rand;
                                    }

                                    if(val.thumb == null){
                                       var atten_img = ''; 
                                    }else{
                                       const min = 1;
                                       const max = 1000;
                                       var rand = min + Math.random() * (max - min);
                                       var atten_img = process.env.React_App_BASE_URL_API+'/uploads/thumb/'+val.thumb+'?'+rand;
                                    }

                                    // var profile_img = '/images/user/1.jpg'; 
                                    // var atten_img = '/images/user/1.jpg'; 

                                    var ck = Object.keys(selectedEmp).find(function (element) {
                                       return selectedEmp[element] == val.id;
                                    });

                                    var ck1 = '';
                                    if (ck !== undefined) {
                                       ck1 = true;
                                    }

                                    return (
                                       <tr key={key}>
                                          <th scope="row"><input type="checkbox" name={val.id} value={val.id} checked={ck1} onChange={e => onInputChange2(e)} /></th>
                                          <th scope="row">{key + 1}</th>
                                          <td style={{width:'130px',textAlign:'center'}}>
                                             <div className="" style={{display:'grid'}}>
                                                <img src={profile_img} alt="profile-img"  className="prof" />
                                                <Link to={`/fr_upload_image/${val.id}`} >Change Pic</Link>
                                                {/* <button>Set As Attendance Image</button> */}
                                                <a href='#'  onClick={() => { set_attendance(val.id); }}>Set As Attendance</a>
                                                {/* <button
                                                className="btn  mr-2">Set As Attendance Image</button> */}
                                             </div>
                                          </td>
                                          <td style={{width:'130px',textAlign:'center'}}>
                                             {(atten_img != '')?
                                             <div className="" style={{display:'grid'}}>
                                                <img src={atten_img} alt="profile-img"  className="prof" />
                                                {/* <Link to={`/fr_upload_image/${val.id}`} >Change Pic</Link> */}
                                             </div>:''}
                                          </td>
                                          <td>{val.emp_code}</td>
                                          <td>{val.first_name} {val.last_name}</td>
                                          <td>{val.phone}</td>
                                          <td>{val.email}</td>
                                          <td>
                                             <button onClick={() => { viewItem(val.id); }}
                                                className="btn btn-info mr-2 m-1" ><i className="fa fa-eye" aria-hidden="true"></i></button>
                                             <button onClick={() => { ondlt(val.id); }}
                                                className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>

                                             <div className="dropdown f_left">
                                                <span className="dropdown-toggle btn " id="dropdownMenuButton4" data-toggle="dropdown">
                                                   <i className="fa fa-cog"></i>
                                                </span>

                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton4">
                                                   <Link to={`/employee_manage/${val.id}`}
                                                      className="dropdown-item"><i className="ri-pencil-fill mr-2" aria-hidden="true"></i>Personnel</Link>
                                                   <Link to={`/employee_manage_info/${val.id}`}
                                                      className="dropdown-item"><i className="ri-pencil-fill mr-2" aria-hidden="true"></i>Other Details</Link>
                                                   <Link to={`/employee_manage_bank/${val.id}`}
                                                      className="dropdown-item"><i className="ri-pencil-fill mr-2" aria-hidden="true"></i>Financial</Link>
                                                   <Link to={`/employee_manage_employement/${val.id}`}
                                                      className="dropdown-item"><i className="ri-pencil-fill mr-2" aria-hidden="true"></i>Employment Details</Link>
                                                   <Link to={`/employee_manage_reimbursement/${val.id}`}
                                                      className="dropdown-item"><i className="ri-pencil-fill mr-2" aria-hidden="true"></i>Reimbursement Info</Link>
                                                   <Link to={`/employee_manage_sal/${val.id}`}
                                                      className="dropdown-item"><i className="ri-pencil-fill mr-2" aria-hidden="true"></i>Salary (Monthly Wages)</Link>
                                                   <Link to={`/employee_manage_doc/${val.id}`}
                                                      className="dropdown-item"><i className="ri-pencil-fill mr-2" aria-hidden="true"></i>Previous Docs</Link>

                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                    );
                                 })}
                              </tbody>
                           </table>
                           <div>
                              <Pagination
                                 activePage={activePage}
                                 itemsCountPerPage={50}
                                 totalItemsCount={empCount}
                                 pageRangeDisplayed={5}
                                 onChange={e => handlePageChange(e)}
                              />
                           </div>
                        </div>
                     </div>


                  </div>
               </div>

            </div>
         </div>
      </>
   )
}

export default Employee_manage_list