import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Attendance_master_report() {

  const alert = useAlert();
  const localuser = JSON.parse(localStorage.getItem('user'));
  let emp = Apiconnect.decrypt(localuser.id);

  useEffect(() => { attendancedata();empdata(); }, []);
  const [attendanceList, setAttendanceList] = useState([]);
  const [empList, setEmpList] = useState([]);

  const attendancedata = () => {
    Apiconnect.getData(`employee_dailydata/attendancereportingdata/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setAttendanceList(_xtract.reverse());
    });
  }

  const empdata = () => {
    Apiconnect.getData(`employee_dailydata/employeereportingdata/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmpList(_xtract.reverse());
    });
  }

  var date = new Date();
  const [from_date,setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [to_date,setToDate] = useState(new Date());

  console.log(empList);

  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">


          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div>
                  <h4 className="card-title hd" >Attendance Master Report</h4>
                </div>
                <div className="iq-card-body">

                  <div className="row p_t_15">
                   
                    <div className="col-md-3">
                      From :  <br/><DatePicker
                                                selected={from_date}
                                                onChange={e => setFromDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="From Date"
                                                name="from_date"
                                                required=""
                                            />
                    </div>
                    <div className="col-md-3">
                      TO :  <br/> <DatePicker
                                                selected={to_date}
                                                onChange={e => setToDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="To Date"
                                                name="to_date"
                                                required=""
                                            />
                    </div>
                    <div className="col-md-3">
                      User : <br/>
                      <select className="form-control" name = "emp_id">
                              <option value="">Select User</option>
                      {empList.map((val, key) => {       
                                       return (
                                          <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                       );
                                    })} 
                                    </select>
                    </div>
                    <div className="col-md-2">
                      <input type="button" className="btn btn-success" value="Submit" style={{marginTop:"23px"}}/>
                    </div>

                  </div>

                  <div className="row">

                    <div className="table-responsive">

                      <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                        <thead>
                          <tr className="clr">
                            <th>#</th>
                            <th>Emp. Name</th>
                            <th>Emp. Code</th>
                            <th>Department</th>
                            <th>InTime</th>
                            <th>OutTime</th>
                            <th>Working Hours</th>
                            <th>Break Hour</th>
                            <th>Production Hour</th>
                            {/* <th>Shift Late</th>
                            <th>Status</th>
                            <th>Latitude</th>
                            <th>Logitude</th>
                            <th>Address of InTime</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {attendanceList.map((val, key) => {

                            if (val.production_hour1 >= '08:00') {
                              var production_hour = <div className=" badge badge-pill badge-success">{val.production_hour1}</div>;
                            } else if (val.production_hour1 <= '08:00' && val.production_hour1 >= '04:00') {
                              var production_hour = <div className=" badge badge-pill badge-warning">{val.production_hour1}</div>;
                            } else {
                              var production_hour = <div className=" badge badge-pill badge-danger">{val.production_hour1}</div>;
                            }

                            return (
                              <tr key={key}>
                                <th scope="row">{key + 1}</th>
                                <td>{val.first_name} {val.last_name}</td>
                                <td>{val.emp_code}</td>
                                <td>{val.name}</td>
                                <td>{val.start_time}</td>
                                <td>{val.end_time}</td>
                                <td>{val.total_hour1}</td>
                                <td>{val.break_hours1}</td>
                                <td>{production_hour}</td>
                                {/* <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td> */}

                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default Attendance_master_report
