import React, {Component} from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import Helper from "../../services/HelperCodebase.js";
import { withAlert } from 'react-alert';
import { useAlert } from "react-alert";
import '../pages/admin.css';
import { green } from '@material-ui/core/colors';
import Axios from 'axios';


class Employee_month_sal extends Component {
    constructor(props) {
      super(props); 
      this.state = { earning_head:  [], deduct_head:  [], all_emp: [], all_emp_m: [],holiday:0};       
 
      

      
      //var aa = this.props.match.params.id; 
      //var bb = this.props.match.params.id; 
      if(this.props.match.params.id>0){
        this.yyyymm= this.props.match.params.id;
        this.yyyy= this.props.match.params.id.substring(0, 4);
        this.mm= this.props.match.params.id.substring(4, 6);
      }else{

        let newDate = new Date();        
        let month = newDate.getMonth();
        let year = newDate.getFullYear();

        month = ('0'+month).slice(-2);
        this.yyyymm= year+''+month;
        this.yyyy= year;
        this.mm= month;
      }
      
      
      this.get_earning_head();
      this.getdeductList();
      this.getAllEmp();
      this.getHolidayList();

      this.totdays= Helper.daysInMonth(this.yyyy,this.mm);     
      this.totsundays= Helper.getSundays(parseInt(this.yyyy), parseInt(this.mm));     
      
    }

   
    
    get_earning_head = () => {         
        Apiconnect.getData(`earning_head/getall/${Apiconnect.get_company_id()}`).then((response) => {
              let ern = Apiconnect.decrypt_obj(response.data.data);
              this.setState({earning_head:  ern});
            //   console.log(ern);
          });
      };
      getdeductList = () => {
            //var l_user = JSON.parse(localStorage.getItem('user'));
            //var empid = l_user.emp_id;
          Apiconnect.getData(`deduction_head/getall/${Apiconnect.get_company_id()}`).then((response) => {           
              let _xtract = Apiconnect.decrypt_obj(response.data.data);
              this.setState({deduct_head:  _xtract});
          });
      };

      getHolidayList = () => {
        var yum = this.yyyymm;
        //var l_user = JSON.parse(localStorage.getItem('user'));
        //var empid = l_user.emp_id;
        var company_id = Apiconnect.get_company_id();
    //  Axios.get(`http://localhost:3050/api/tush/getholidays/${company_id}/${yum}`).then((response) => {
      Apiconnect.getData(`employee_salary/getholidays/${company_id}/${yum}`).then((response) => {           
          let _xtract = response.data.data;
        //    console.log(_xtract);
         this.setState({holiday:_xtract});
      });
  };

      getAllEmp = () => {         
          var yum = this.yyyymm;
        //   console.log(yum);
         // Axios.get(`http://localhost:3050/api/tush/allemployee_salaryinfo/${yum}`).then((response) => {
          Apiconnect.getData(`employee_salary/allemployee_salaryinfo/${yum}/${Apiconnect.get_company_id()}`).then((response) => {           
              let _xtract = Apiconnect.decrypt_obj(response.data.data);
                //  console.log(_xtract);
              this.setState({all_emp_m:  _xtract}); 

              /////////////////////////////////////////////////////////
              
              

                var mx_info=[];
                _xtract.map((vvx, kkx) => { 
                    let ind_info = vvx;
                    var prsnt_days = ind_info.present_count;
                    ////// ADD HOLIDAY AND SUNDAY TO IT...
                    
                    let cur_salx = ind_info.salary_info; 
                    //console.log(cur_salx);

                    var mx_sal=[];
                    cur_salx.map((vv, kk) => {
                        var mn_days = this.totdays;
                        var pr_days = prsnt_days;

                        if(pr_days>0){
                        }else{
                            var pr_days = 1
                        }
                            var tt = Math.round((parseInt(vv.amount)*pr_days)/mn_days);
                            let mark_sal = vv;
                            mark_sal = {...mark_sal, amount: tt};
                            mx_sal.push(mark_sal);
                        
                    })

                    ind_info = {...ind_info, salary_info: mx_sal};
                    ind_info = {...ind_info, present_count: prsnt_days};  
                    
                    mx_info.push(ind_info);
                    
                 })
        
                 



              ///////////////////////////////////////////////////////////////////////////////////
              this.setState({all_emp:  mx_info});              
          });
      };





    changedays = (e,i) => {
        let ki = i.key_e;
        let va = e.currentTarget.value;
        //console.log(ki);
        //console.log(va);

        



         
        let markers = this.state.all_emp;
        let markers_m = this.state.all_emp_m;
        //console.log('--->>>>-----');
        //console.log(markers[ki]);
        //console.log(markers_m[ki]);
         

        let ff = parseInt(e.currentTarget.value); 
        let cur_uu = markers_m[ki]; 
        let cur_sal = cur_uu.salary_info; 

        
        var mx_sal=[];
        cur_sal.map((vv, kk) => {
            var mn_days = this.totdays;
            var pr_days = ff;
            //console.log('-->>>----');
            //console.log(vv.amount);
            //console.log(pr_days);
            //console.log(mn_days);
            //console.log('-->>>----');
            if(pr_days>0){
            }else{
                pr_days = 0;
            }
                var tt = Math.round((parseInt(vv.amount)*pr_days)/mn_days);
                //console.log(tt);
                //console.log('-->>>----');
                let mark_sal = vv;
                mark_sal = {...mark_sal, amount: tt};
                mx_sal.push(mark_sal);
            
             
            
         })

          

        


        markers[ki] = {...markers[ki], present_count: ff}; 
        markers[ki] = {...markers[ki], salary_info: mx_sal}; 
        this.setState({all_emp:  markers});


        //console.log(markers[ki]);
        //console.log(cur_sal);
        //console.log('--->><<<<>>-----');


        //this.formula_man_er(ki,va);
    };






    saveData = async (e,i) => {
        e.preventDefault();
        // console.log(this.state.all_emp);
        // return false;
        let info_enc =  Apiconnect.encrypt_obj(this.state.all_emp);
        const inf_a = { enc: info_enc,month:this.mm,year:this.yyyy,total_day:this.totdays};
        await Apiconnect.postData("employee_monthly_salary/create", inf_a).then((response) => {
             this.props.alert.success(response.data.message)
            this.props.history.push("/dashboard/?");

        });
        
    };


    // gotopage(){ 
    //     window.location.href('dashboard'); 
    //  }








   
    
      


  
    render() { 
      //var ll =    this.state.all_emp[0];   
      
      
      //console.log(this.yyyymm); 
    //   console.log(this.yyyy); 
    //   console.log(this.mm); 
       
    //   console.log(this.totdays);
    //  console.log(this.state.all_emp);
      
      const alert = this.props.alert;
       
      return (
        <div id="content-page" className="content-page">
        <div className="container-fluid">
           <div className="row">
              <div className="col-lg-12">
                 <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                       <div className="iq-header-title">
                          <h4 className="card-title">Employee Salary List ({this.yyyy}-{this.mm})</h4>
                       </div>
                    </div>
                    <div className="iq-card-body">
                    <form onSubmit={e => this.saveData(e)}>



                    <div className="table-responsive">
                              <table className="table mb-0 table-borderless">
                                 <thead>
                                    <tr>
                                       <th></th>
                                       <th></th>
                                       <th></th>
                                       <th></th>
                                       <th style={{ textAlign: 'center', borderLeft: '1px solid #12171d', background: '#388238', borderRight: '1px solid #12171d' }} 
                                                colSpan={this.state.earning_head.length} >Credit</th>
                                       <th style={{ textAlign: 'center', background: '#b95318', }} colSpan={this.state.deduct_head.length}  >Deduction</th>
                                       <th></th>
                                    </tr>

                                    <tr>
                                       <th>#</th>
                                       <th>Name</th>
                                       <th style={{ width: '60px' }} >Total Days</th>
                                       <th  style={{ width: '60px' }} >Paid Days</th>
                                       {this.state.earning_head.map((val, key) => {
                                          return (
                                             <th key={key} style={{ textAlign: 'center', borderLeft: '1px solid #12171d', background: '#388238', }} >{val.abbreviation}</th>
                                          );
                                       })
                                       }
                                       {this.state.deduct_head.map((val, key) => {
                                          return (
                                             <th key={key} style={{ textAlign: 'center', borderLeft: '1px solid #12171d', background: '#b95318', }} >{val.abbreviation}</th>
                                          );
                                       })
                                       }
                                       <th>Total</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.all_emp.map((val_e, key_e) => {
                                         var sngle =    val_e;   
                                         var sngle_sal = sngle.salary_info;
                                         var all_earning = [];
                                         var tot = 0;
                                         if(sngle_sal.length>0){                                              
                                            all_earning = Helper.array_search_multidim('typ','earning',sngle_sal);                                             
                                         }

                                         var all_deduct = [];
                                         if(sngle_sal.length>0){                                              
                                            all_deduct = Helper.array_search_multidim('typ','deduction',sngle_sal);                                             
                                         }
                                         
                                         
                                         
                                         
                                        return (
                                            <tr key={key_e}>
                                                <th scope="row">{key_e + 1}</th>                                                 
                                                <td>{val_e.first_name} {val_e.last_name}</td>
                                                <td   ><input style={{ width: '60px' }} type="text" placeholder="TotalDays"   value={this.totdays} readOnly /></td>
                                                <td   ><input style={{ width: '60px' }} type="text" placeholder="Presence"   value={Number(val_e.present_count)+Number(this.state.holiday)-Number(val_e.lop_count)}  onChange={e => this.changedays(e,{key_e})}   /></td>

                                                {this.state.earning_head.map((val, key) => {
                                                    //////////////////////////////////////////////
                                                    var this_earning=[];
                                                    if(all_earning.length>0){                                              
                                                        var th = Helper.array_search_multidim('head_id',val.id,all_earning); 
                                                        this_earning = th[0];                                            
                                                    }                                                   
                                                    var earning_amount = '';                                                    
                                                    if(typeof(this_earning) !== "undefined" && this_earning !== null){
                                                        earning_amount =  this_earning.amount; 
                                                        tot = tot+earning_amount;
                                                    }
                                                  // console.log(earning_amount+'--'+tot);


                                                    /////////////////////////////////////////////////////////////////////////

                                                    return (
                                                        <th style={{ background: '#a9c7a9', }} >
                                                            <input style={{ width: '60px' }}  type="text" placeholder="" value={earning_amount}      />
                                                        </th>
                                                    );
                                                })
                                                }
                                                {this.state.deduct_head.map((val, key) => {

                                                    //////////////////////////////////////////////
                                                    var this_deduct=[];
                                                    if(all_deduct.length>0){                                              
                                                        var th = Helper.array_search_multidim('head_id',val.id,all_deduct); 
                                                        this_deduct = th[0];                                            
                                                    }                                                   
                                                    var deduct_amount = '';                                                     
                                                    if(typeof(this_deduct) !== "undefined" && this_deduct !== null){
                                                        deduct_amount =  this_deduct.amount; 
                                                        tot = tot+deduct_amount;
                                                    }
                                                    /////////////////////////////////////////////////////////////////////////


                                                    return (
                                                        <th style={{ background: '#e6ab89', }} >
                                                            <input style={{ width: '60px' }}  type="text" placeholder=""   value={deduct_amount}    />
                                                        </th>
                                                    );
                                                })
                                                }

                                                <th>{tot}</th>



                                            </tr> 
                                        );
                                    })
                                    }




                                </tbody>






                            </table>
                    </div>

                    <button type="submit" className="btn btn-success"  >Save Record</button> 




























                    </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>                
            </div>
      );
    }
  }

  export default withAlert()(Employee_month_sal);