import React, { Component } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { CSVLink } from "react-csv";
import { withAlert } from 'react-alert';
import '../pages/uploadfile.css';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import Loadder from "./Loadder";
import { confirmAlert } from 'react-confirm-alert'; // Import


class Employee_manage_doc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: '', company_id: Apiconnect.get_company_id(), emp_id: props.match.params.id, loader: '', catList: []
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.ondlt = this.ondlt.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.getCats = this.getCats.bind(this);
        // this.inputsearch = this.inputsearch.bind(this);

    }

    componentDidMount() {
        this.getCats();
    }



    handleInputChange(event) {
        this.setState({
            selectedFile: event.target.files[0],
        })
    }

    submit = async e => {
        // console.log(this.state.selectedFile);
        const data = new FormData();
        data.append('emppreviousdocs', this.state.selectedFile);
        data.append('emp_id', this.state.emp_id);
        // console.log(data);
        // Axios.post(`http://localhost:3050/api/upload/employeedocsupload/${this.state.company_id}`, data).then((response) => {

       Apiconnect.postData(`upload/employeedocsupload/${this.state.company_id}`, data).then((response) => {           
            this.props.alert.success(response.data.message)
            this.getCats();
        });

    };

    ondlt = (id) => {

        console.log(id);
        confirmAlert({
            title: 'Are you sure to Delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteItem(id),
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    deleteItem = (id) => {
        Apiconnect.deleteData(`employee_info/docdelete/${id}`).then((response) => {
            this.getCats();
            this.props.alert.success(response.data.message)

        });
    };


    //    getItem = (id) => {    
    //     Apiconnect.getData(`city/get/${id}`).then((response) => {        
    //        let _xtract = response.data.data;      
    //        setInfo({ name: _xtract[0].name, state_id: _xtract[0].state_id, id: _xtract[0].id });      
    //     });

    //   };

    getCats = () => {
        Apiconnect.getData(`employee_info/docgetall/${this.state.emp_id}`).then((response) => {
            //    let _xtract = response.data.data;
            this.setState({
                catList: response.data.data.reverse(),
            })


        });
    };

  

    render() {



        return (

            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        {/* <div className="col-sm-12"> */}
                        <div className="col-sm-6">
                            <div class="container center">
                                <div class="row">
                                    <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                        <h2 class="white" style={{ paddingTop: '10px' }}>Employee Previous Docs Upload</h2>
                                        {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                        <hr style={{ backgroundColor: '#fff' }} />
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">

                                            <div class="dropzone">
                                                <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                                {/* <input type="file" class="upload-input" value="" /> */}
                                                <div className="row" style={{ margin: ' 0 10px 25px 10px' }}>
                                                    <input type="file" id="file" className="form-control" name="emppreviousdocs" onChange={this.handleInputChange} />
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-lg" onClick={() => this.submit()}>Submit</button>
                                            <br />

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-6">
                        <div className="col-sm-12 col-lg-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">List of Documents</h4>


                                    </div>
                                    {/* <div> Search <input type="text" name="search" onChange={e => this.inputsearch(e)}/></div> */}
                                </div>
                                <div className="iq-card-body">

                                    <table className="table table-striped table-hover">
                                        <thead className="">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {
                                                this.state.loader ? <Loadder /> :
                                                    this.state.catList.map((val, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <th scope="row">{key + 1}</th>
                                                                <td>{val.name}</td>
                                                                <td>
                                                                <a href={process.env.React_App_BASE_URL_API+'/uploads/emppreviousdocs/'+val.name} target="_blank" rel="noopener noreferrer" download>
                                                                <button type="button" id="btnup" className="btn btn-primary" style={{marginLeft:'5px',marginRight:'5px'}}><i className="fa fa-download"></i></button>
                                                                  </a>
                                                                    <button onClick={() => { this.ondlt(val.doc_id); }}
                                                                        className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>


                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                        </tbody>
                                    </table>
                                    <div>
                                        {/* <Pagination
                                 activePage={activePage}
                                 itemsCountPerPage={50}
                                 totalItemsCount={cityCount}
                                 pageRangeDisplayed={5}
                                 onChange={e => this.handlePageChange(e)}
                              /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>

             


                    {/* </div> */}



                </div>
            </div>
        )
    }
}

export default withAlert()(Employee_manage_doc)