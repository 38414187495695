import Axios from 'axios';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';



const ENC_KEY = "d0baramatpuchhnakip@55w0rdky@h@1";
const API_URL = process.env.React_App_BASE_URL_API+'/api/';
const local_user = JSON.parse(localStorage.getItem('user'));



let AuthStr = '';
let company_id='';
let emp_typ='';
let company_name='';
let fullname='';
if(local_user){
    AuthStr = local_user.accessToken;
    emp_typ= local_user.emp_typ; 
    company_id= local_user.company_id; 
    fullname= local_user.fullname; 
    company_name= local_user.company_name; 
}
 



class Apiconnect {

   

    get_company_id = () => {
        var _ciphertext = CryptoAES.decrypt(company_id.toString(), ENC_KEY);
        return _ciphertext.toString(CryptoENC);
    }

    get_emp_typ = () => {
        var _ciphertext = CryptoAES.decrypt(emp_typ.toString(), ENC_KEY);
        return _ciphertext.toString(CryptoENC);
    }
    
    
    encrypt = (txt) => {
        var _ciphertext = CryptoAES.encrypt(txt, ENC_KEY).toString();
        return _ciphertext;
    }
    encrypt_obj = (txt) => {
        var _ciphertext = CryptoAES.encrypt(JSON.stringify(txt), ENC_KEY).toString();
        return _ciphertext;
    }



    decrypt = (ciphertext) => {
        var _ciphertext = CryptoAES.decrypt(ciphertext.toString(), ENC_KEY);
        return _ciphertext.toString(CryptoENC);
    }
    decrypt_obj = (ciphertext) => {
        var _ciphertext = CryptoAES.decrypt(ciphertext.toString(), ENC_KEY);
        return  JSON.parse(_ciphertext.toString(CryptoENC));

    }

    getData(urlpart) {
        return Axios.get(API_URL+urlpart, {headers:{Authorization: AuthStr}});
    }
    getData_arr(urlpart,data) {
        return Axios.get(API_URL+urlpart, data, {headers:{Authorization: AuthStr}});
    }

    postData(urlpart, data) {
        return Axios.post(API_URL+urlpart, data, {headers:{Authorization: AuthStr}});
    }


    deleteData(urlpart) {
        return Axios.delete(API_URL+urlpart, {headers:{Authorization: AuthStr}});
    }
     
    

  }
  
  export default new Apiconnect();
  