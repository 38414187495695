import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthService from "../services/auth.service";
import { useHistory } from "react-router-dom";
import Apiconnect from "../services/Apiconnect.js";





function Header() {

   const location = useLocation();
   const { pathname } = location;
   const splitLocation = pathname.split("/");
   const master = ['area', 'city', 'company_category', 'leaveassign', 'shiftassign', 'company_holiday', 'weekly_off', 'leave_policy', 'shift', 'manual_leaves'];
   const salary_info = ['Earning_head', 'deduction_head', 'reimbursement', 'perk_head', 'formula_add', 'formula_list', 'formula_edit', 'salary_template', 'salary_template_edit', 'salary_template_list', 'employee_month_sal'];
   const emp_manage = ['employee_manage_list', 'employee_manage','employee_requested_thumb','employee_requested_details'];
   const report = ['punchtime_report', 'daily_attendance_report','monthly_attendance_report'];
   const company = ['company_list','company','company_edit'];


   /////////---------->breadcrumb<----------//////////
   var pt = 'Add';
   if (splitLocation[1] === 'employee_manage' && splitLocation[2] != 0) {
      var pt = 'Edit';
   }
   const breadcrumb_pages = {'company':'Home/Add Company','company_list':'Home/List','company_edit':'Home/Edit company',
      'dashboard': 'Home', 'employee_manage_list': 'Home/Employee Management', 'employee_manage': 'Home/Employee Management', 'employee_upload_bulk': 'Home/Employee Management/List/Bulk Upload',
      'employee_manage_info': 'Home/Employee Management/Edit', 'employee_manage_bank': 'Home/Employee Management/Edit', 'employee_manage_employement': 'Home/Employee Management/Edit',
      'employee_manage_reimbursement': 'Home/Employee Management/Edit', 'employee_manage_sal': 'Home/Employee Management/Edit',
      'Earning_head': 'Home/Salary Management/Earning Head', 'deduction_head': 'Home/Salary Management/Deduction Head',
      'reimbursement': 'Home/Salary Management/Reimbursement Head', 'perk_head': 'Home/Salary Management/Perk Head',
      'formula_add': 'Home/Salary Management/Formula', 'formula_list': 'Home/Salary Management/Formula', 'formula_edit': 'Home/Salary Management/Formula',
      'employee_month_sal': 'Home/Salary Management/Monthly Salary',
      'salary_template': 'Home/Salary Management/Salary Template', 'salary_template_list': 'Home/Salary Management/Salary Template',
      'salary_template_edit': 'Home/Salary Management/Salary Template',
      'city': 'Home/Setting/City', 'area': 'Home/Setting/Area', 'company_category': 'Home/Setting/Departments', 'leaveassign': 'Home/Setting/Departments/Leaves Assign',
      'shiftassign': 'Home/Setting/Departments/Shift Assign', 'company_holiday': 'Home/Setting/Company Holiday',
      'weekly_off': 'Home/Setting/Weekly Off', 'leave_policy': 'Home/Setting/Leave Policy', 'shift': 'Home/Setting/Shift',
      'manual_leaves': 'Home/Setting/Leave Balance Update', 'punchtime_report': 'Home/Employee PunchTime Report', 'daily_attendance_report': 'Home/Employee Attendance Report','monthly_attendance_report': 'Home/Monthly Attendance Report','employee_requested_thumb':'Home/Employee Requested Profile List','employee_requested_details':'Home/Employee Requested Details'
   };

   if (breadcrumb_pages[splitLocation[1]] != undefined) {
      var breadcrumb = breadcrumb_pages[splitLocation[1]].split("/");
   } else {
      var breadcrumb = '';
   }

   /////////---------->End breadcrumb<----------//////////

   const [show, setShow] = useState(false);

   let history = useHistory();

   function logOut() {
      AuthService.logout();
      history.push("/");
   }

   function resetPassword() {
      history.push("resetPassword/");
   }

   const locusr = JSON.parse(localStorage.getItem('user'));
   let user_id = Apiconnect.decrypt(locusr.id);
   let user_typ = Apiconnect.decrypt(locusr.emp_typ);
   let company_id = Apiconnect.get_company_id();
   let company_name = locusr.company_name;
   let fullname = locusr.fullname;

   const [profile_image, setprofile_image] = useState(null);
   const [logo, setlogo] = useState(null);

   useEffect(() => { getprofile(); getCompanyCount();getCompany(); }, []);
   const getprofile = () => {

      Apiconnect.getData(`employee/get/${user_id}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);

         if (_xtract[0].display_picture == null) {
            setprofile_image('/images/user/1.jpg');
         } else {
            var img = `${process.env.React_App_BASE_URL_API}/uploads/thumbdp/` + _xtract[0].display_picture;
            setprofile_image(img);
         }
         // setInfo({ name: _xtract[0].name, state_id: _xtract[0].state_id, id: _xtract[0].id });      
      });
   };

   const [allcompany, setCompanyCount] = useState([]);
   const getCompanyCount = () => {
      Apiconnect.getData(`company/getallcompanydata`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setCompanyCount(_xtract.reverse());
      });
   }

   const getCompany = () => {

      if(Apiconnect.get_company_id() != ''){

     
      Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         if (_xtract[0].logo == null) {
            setlogo("images/logo.png");
         } else {
            var img = `${process.env.React_App_BASE_URL_API}/uploads/logo/` + _xtract[0].logo;
            setlogo(img);
         }
      });
      }else{
         var img =process.env.React_App_BASE_URL_API+'/uploads/logo/school_logo.png';
         setlogo(img);
      }
   }

   const oncompanyChange = (e) => {
      var cm_id = e.target.value;

      const local_user = JSON.parse(localStorage.getItem('user'));
      var company_id1 = Apiconnect.encrypt(cm_id + '');
      local_user.company_id = company_id1;

      var comp_name = allcompany.find(Element => Element.id == cm_id);
      
      if (cm_id === '') {
         local_user.company_id = '';
         local_user.company_name = '';
      } else {
         local_user.company_name = comp_name.company_name;
      }
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(local_user));
      window.location.reload();

   }



   return (
      <>
         <div id="loading">
            <div id="loading-center">
               <div className="loader">
                  <div className="cube">
                     <div className="sides">
                        <div className="top"></div>
                        <div className="right"></div>
                        <div className="bottom"></div>
                        <div className="left"></div>
                        <div className="front"></div>
                        <div className="back"></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="wrapper">

            <div className="iq-sidebar">
               <div className="iq-sidebar-logo d-flex justify-content-between">
                  <Link to="/dashboard">
                     <img src={logo} className="img-fluid" alt="" />
                     <span>HRMS</span>
                  </Link>
                  <div className="iq-menu-bt align-self-center">
                     <div className="wrapper-menu">
                        <div className="line-menu half start"></div>
                        <div className="line-menu"></div>
                        <div className="line-menu half end"></div>
                     </div>
                  </div>
               </div>
               <div id="sidebar-scrollbar">
                  <nav className="iq-sidebar-menu">
                     <ul id="iq-sidebar-toggle" className="iq-menu">
                        <li className="iq-menu-title"><i className="ri-separator"></i><span>Main</span><span style={{ float: 'right' }}><Link style={{ padding: '0px' }} onClick={e => setShow(!show)}>{show === true ? "Hide All" : "Show All"}</Link></span> </li>

                        {
                           user_typ != 3 ?
                              <li className={splitLocation[1] === "dashboard" ? "active" : ""}>
                                 <Link to="/dashboard"><i className="ri-home-4-line"></i> Dashboard</Link>
                              </li> :
                              <li className={splitLocation[1] === "superadmin" ? "active" : ""}>
                                 <Link to="/superadmin"><i className="ri-home-4-line"></i> Dashboard</Link>
                              </li>
                        }

                        {user_typ === '3' ?
                           <>
                              <li className={company.some(item => splitLocation[1] === item) ? "active" : ""}>
                                 <a href="#company" className={show === true ? "iq-waves-effect" : "iq-waves-effect collapsed"} data-toggle="collapse" aria-expanded={show}>
                                    <i className="ri-chat-check-line"></i><span>Company Management</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></a>
                                 <ul id="company" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                                    {/* <li className={splitLocation[1] === 'company' ? "active" : ""}><Link to="/company">Company</Link></li> */}
                                    <li className={splitLocation[1] === 'company_list' ? "active" : ""}><Link to="/company_list">Company List</Link></li>
                                 </ul>
                              </li>
                           </>
                           : ""}

                        {company_id != '' ?
                           <>
                              <li className={emp_manage.some(item => splitLocation[1] === item) ? "active" : ""}>
                                 <a href="#emp_manage" className={show === true ? "iq-waves-effect" : "iq-waves-effect collapsed"} data-toggle="collapse" aria-expanded={show}>
                                    <i className="ri-chat-check-line"></i><span>Employee Management</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></a>
                                 <ul id="emp_manage" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">

                                    <li className={splitLocation[1] === 'employee_manage_list' ? "active" : ""}><Link to="/employee_manage_list">Employee List</Link></li>
                                    <li className={splitLocation[1] === 'employee_manage' ? "active" : ""}><Link to="/employee_manage/0">Add New Employee</Link></li>
                                    <li className={splitLocation[1] === 'employee_requested_thumb' ? "active" : ""}><Link to="/employee_requested_thumb">Employee Requested Profile</Link></li>  
                                 </ul>
                              </li>


                              <li className={salary_info.some(item => splitLocation[1] === item) ? "active" : ""}>
                                 <a href="#salary_info" className={show === true ? "iq-waves-effect" : "iq-waves-effect collapsed"} data-toggle="collapse" aria-expanded={show}>
                                    <i className="fa fa-money"></i><span>Salary Management</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></a>
                                 <ul id="salary_info" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                                    <li className={splitLocation[1] === 'Earning_head' ? "active" : ""}><Link to="/Earning_head">Earning Head</Link></li>
                                    <li className={splitLocation[1] === 'deduction_head' ? "active" : ""}><Link to="/deduction_head">Deduction Head</Link></li>
                                    <li className={splitLocation[1] === 'reimbursement' ? "active" : ""}><Link to="/reimbursement">Reimbursement Head</Link></li>

                                    <li className={splitLocation[1] === 'perk_head' ? "active" : ""}><Link to="/perk_head">Perk Head</Link></li>
                                    <li className={splitLocation[1] === 'formula_add' ? "active" : ""}><Link to="/formula_add">Add Formula</Link></li>
                                    <li className={splitLocation[1] === 'formula_list' ? "active" : ""}><Link to="/formula_list">Formula List</Link></li>

                                    {/* <li><Link to="/salary_template">Salary Template</Link></li> */}
                                    <li className={splitLocation[1] === 'salary_template_list' ? "active" : splitLocation[1] === 'salary_template' ? "active" : splitLocation[1] === 'salary_template_edit' ? "active" : ""}><Link to="/salary_template_list">Salary Template</Link></li>
                                    <li className={splitLocation[1] === 'employee_month_sal' ? "active" : ""}><Link to="/employee_month_sal">Monthly Salary List</Link></li>
                                 </ul>
                              </li>


                              <li className={master.some(item => splitLocation[1] === item) ? "active" : ""}>
                                 <a href="#master" className={show === true ? "iq-waves-effect" : "iq-waves-effect collapsed"} data-toggle="collapse" aria-expanded={show}>
                                    <i className="fa fa-cog"></i><span>Setting</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></a>
                                 <ul id="master" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">

                                    {/* <li><Link to="/company">Company</Link></li>
                           <li><Link to="/company_list">Company List</Link></li> */}
                                    <li className={splitLocation[1] === "area" ? "active" : ""}><Link to="/area">Area</Link></li>
                                    <li className={splitLocation[1] === "city" ? "active" : ""}><Link to="/city">City</Link></li>
                                    <li className={splitLocation[1] === "company_category" ? "active" : splitLocation[1] === "leaveassign" ? "active" : splitLocation[1] === "shiftassign" ? "active" : ""}>
                                       <Link to="/company_category">Departments</Link></li>
                                    <li className={splitLocation[1] === "company_holiday" ? "active" : ""}><Link to="/company_holiday">Company Holiday</Link></li>
                                    <li className={splitLocation[1] === "weekly_off" ? "active" : ""}><Link to="/weekly_off">Weekly Off</Link></li>
                                    <li className={splitLocation[1] === "leave_policy" ? "active" : ""}><Link to="/leave_policy">Leave Policy</Link></li>
                                    {/* <li><Link to="/Earning_head">Earning Head</Link></li>
                           <li><Link to="/deduction_head">Deduction Head</Link></li>
                           <li><Link to="/reimbursement">Reimbursement</Link></li>
                           <li><Link to="/perk_head">Perk Head</Link></li> */}
                                    <li className={splitLocation[1] === "shift" ? "active" : ""}><Link to="/shift">Shift</Link></li>
                                    <li className={splitLocation[1] === "manual_leaves" ? "active" : ""}><Link to="/manual_leaves">Leave Balance Update</Link></li>
                                 </ul>
                              </li>
                              <li>
                                 <a href="#emp_manage1" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                                    <i className="fa fa-users"></i><span>Team Management</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></a>
                                 <ul id="emp_manage1" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                    <li><Link to="/team_member_leaves">Team Member Leaves</Link></li>
                                 </ul>
                              </li>

                              <li className={report.some(item => splitLocation[1] === item) ? "active" : ""}>
                                 <a href="#report" className={show === true ? "iq-waves-effect" : "iq-waves-effect collapsed"} data-toggle="collapse" aria-expanded={show}>
                                    <i className="ri-chat-check-line"></i><span>Report</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></a>
                                 <ul id="report" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                                    <li className={splitLocation[1] === 'punchtime_report' ? "active" : ""}><Link to="/punchtime_report">Punch Time Report</Link></li>
                                    <li className={splitLocation[1] === 'daily_attendance_report' ? "active" : ""}><Link to="/daily_attendance_report">Attendance Report</Link></li>
                                    <li className={splitLocation[1] === 'monthly_attendance_report' ? "active" : ""}><Link to="/monthly_attendance_report">Monthly Attendance Report</Link></li>
                                 </ul>
                              </li>
                           </>
                           : ''}


                     </ul>
                  </nav>
                  <div className="p-3"></div>
               </div>
            </div>





            <div className="iq-top-navbar">
               <div className="iq-navbar-custom">
                  <div className="iq-sidebar-logo">
                     <div className="top-logo">
                        {/* <a href="index.html" className="logo"> */}
                        <img src={logo} className="img-fluid" alt="" />
                        <span>HRMS</span>
                        {/* </a> */}
                     </div>
                  </div>
                  <div className="navbar-breadcrumb">
                     <h5 className="mb-0">{company_name}</h5>
                     {/* // <nav aria-label="breadcrumb">
                        //    <ul className="breadcrumb">
                        //       <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        //       <li className="breadcrumb-item active" aria-current="page">{breadcrumb_pages[splitLocation[1]]}</li>
                        //    </ul>
                        // </nav> */}

                     <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                           {breadcrumb[0] != undefined ? <li className="breadcrumb-item"> <Link to='/dashboard' >{breadcrumb[0]}</Link></li> : ''}
                           {breadcrumb[1] != undefined ? <li className="breadcrumb-item"> <Link to={pathname} >{breadcrumb[1]}</Link></li> : ''}
                           {breadcrumb[2] != undefined ? <li className="breadcrumb-item"> <Link to={pathname} >{breadcrumb[2]}</Link></li> : ''}
                           {breadcrumb[3] != undefined ? <li className="breadcrumb-item"> <Link to={pathname} >{breadcrumb[3]}</Link></li> : ''}
                           {breadcrumb[4] != undefined ? <li className="breadcrumb-item"> <Link to={pathname} >{breadcrumb[4]}</Link></li> : ''}
                           {breadcrumb[5] != undefined ? <li className="breadcrumb-item"> <Link to={pathname} >{breadcrumb[5]}</Link></li> : ''}
                        </ul>
                     </nav>
                  </div>


                  <nav className="navbar navbar-expand-lg navbar-light p-0">

                     {user_typ === '3' ?
                        <>
                           <div className="col-md-3 col-sm-3"></div>

                           <ul className="navbar-list" style={{ float: 'unset' }}>
                              <li style={{ paddingRight: '5px' }}>Select Company : </li>
                              <li>
                                 <div className="form-group q-waves-effect" style={{ width: '130px', margin: '0px' }}>
                                    {/* Select Company <br/> */}
                                    <select className="form-control" value={company_id} onChange={e => oncompanyChange(e)}>
                                       <option value="">All</option>
                                       {
                                          allcompany.map((val) => {
                                             return (
                                                <option value={val.id}>{val.company_name}</option>
                                             )
                                          })
                                       }
                                    </select>
                                 </div>
                              </li>
                           </ul>
                        </>
                        : ""}




                     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="ri-menu-3-line"></i>
                     </button>
                     <div className="iq-menu-bt align-self-center">
                        <div className="wrapper-menu">
                           <div className="line-menu half start"></div>
                           <div className="line-menu"></div>
                           <div className="line-menu half end"></div>
                        </div>
                     </div>

                     <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto navbar-list">
                           <li className="nav-item iq-full-screen"><a href="#" className="iq-waves-effect" id="btnFullscreen"><i className="ri-fullscreen-line"></i></a></li>
                        </ul>
                     </div>

                     <ul className="navbar-list">
                        <li>
                           <a href="#" className="search-toggle iq-waves-effect bg-primary text-white"><img src={profile_image} className="img-fluid rounded" alt="user" /></a>
                           <div className="iq-sub-dropdown iq-user-dropdown">
                              <div className="iq-card shadow-none m-0">
                                 <div className="iq-card-body p-0 ">
                                    <div className="bg-primary p-3">
                                       <h5 className="mb-0 text-white line-height">Hello {fullname}</h5>
                                       <span className="text-white font-size-12">Available</span>
                                    </div>
                                    <a href="#" className="iq-sub-card iq-bg-primary-hover">
                                       <div className="media align-items-center">
                                          <div className="rounded iq-card-icon iq-bg-primary">
                                             <i className="ri-file-user-line"></i>
                                          </div>
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">My Profile</h6>
                                             <p className="mb-0 font-size-12">View personal profile details.</p>
                                          </div>
                                       </div>
                                    </a>

                                    <Link to={`/employee_upload_image`} className="iq-sub-card iq-bg-primary-hover">
                                       <div className="media align-items-center">
                                          <div className="rounded iq-card-icon iq-bg-primary">
                                             <i className="ri-file-user-line"></i>
                                          </div>
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">Change Profile Pic</h6>
                                          </div>
                                       </div>
                                    </Link>



                                    <div className="d-inline-block w-100 text-center p-2">
                                       <a className="iq-bg-danger iq-sign-btn" onClick={logOut} role="button" >Sign out<i className="ri-login-box-line ml-2"></i></a>
                                       <a className="iq-bg-info " onClick={resetPassword} role="button" style={{ padding: '8.5px 3px', borderRadius: '5px', border: '1px solid transparent', cursor: 'pointer' }}>Change Password<i className="ri-login-box-line ml-2"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </li>
                     </ul>


                  </nav>
               </div>
            </div>




















         </div>


      </>
   )
}

export default Header
