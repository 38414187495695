import React, {useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";


const Company_edit = (props) => {
    const currentUserId = props.match.params.id;

   const alert = useAlert();
   let history = useHistory();

   const [info, setInfo] = useState({

      company_name: "",org_type: "",country_id: "",state_id: "",city_id: "",area_id: "",landmark: "",address_two: "",zip: "" ,
      phone: "",phone_secondary: "",mobile: "",fax: "",website: "",email: "",email_secondary: "",is_pf: "",is_grade: "",is_esi: "",is_leave: "",
      is_loan: "",is_attendance_device: "",
      id: null,
    });
    const { company_name,org_type,country_id,state_id,city_id,area_id,landmark,address_one,address_two,zip ,phone,phone_secondary,
      mobile,fax,website,email,email_secondary,id } = info;
    const onInputChange = e => {
      setInfo({ ...info, [e.target.name]: e.target.value });
    };

 


    const onSubmit = async e => {
      e.preventDefault();

      if(id>0){
         console.log('in edit function' +id);
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
         Apiconnect.postData(`company/update/`, inf_a).then((response) => {
            history.push("/company_list"); 
            setInfo({ company_name: "",org_type: "",country_id: "",state_id: "",city_id: "",area_id: "",landmark: "",address_one: "",address_two: "",zip: "" ,
            phone: "",phone_secondary: "",mobile: "",fax: "",website: "",email: "",email_secondary: "", id: ''});
            // getCats();
            alert.success(response.data.message);  
         });
      }
     
    };
   
   useEffect(() => { const userId = currentUserId; getArea();getCity();getState();getCountry();getOrg();getItem(userId)}, []);
   const [areaList, setArea] = useState([]);
   const [cityList, setCity] = useState([]);
   const [stateList, setState] = useState([]);
   const [countryList, setCountry] = useState([]);
   const [orgList,setOrg]= useState([]);

   const getItem = (id) => {    
    Apiconnect.getData(`company/get/${id}`).then((response) => {        
       let _xtract = Apiconnect.decrypt_obj(response.data.data);
       console.log(_xtract);      
       setInfo({ company_name: _xtract[0].company_name, org_type: _xtract[0].org_type,country_id: _xtract[0].country_id,
        state_id: _xtract[0].state_id,city_id: _xtract[0].city_id,area_id: _xtract[0].area_id,landmark: _xtract[0].landmark,
        landmark: _xtract[0].landmark,address_one: _xtract[0].address_one, address_two: _xtract[0].address_two,zip: _xtract[0].zip,phone: _xtract[0].phone,
        phone_secondary: _xtract[0].phone_secondary,mobile: _xtract[0].mobile,fax: _xtract[0].fax,
        website: _xtract[0].website,email: _xtract[0].email,email_secondary: _xtract[0].email_secondary,
        is_pf: _xtract[0].is_pf, is_grade: _xtract[0].is_grade, is_esi: _xtract[0].is_esi, is_leave: _xtract[0].is_leave, is_loan: _xtract[0].is_loan,
        is_attendance_device: _xtract[0].is_attendance_device,
         id: _xtract[0].id });      
    });
     
  };

  

   const getOrg = ()=>{
      Apiconnect.getData("orgtype/getall").then((response) => {
         setOrg(response.data.data.reverse());
      });
   }

   const getArea = () => {
     Apiconnect.getData("area/getall").then((response) => {
         setArea(response.data.data.reverse());
      });
    };

    const getCity = () => {
     Apiconnect.getData("city/getall").then((response) => {
         setCity(response.data.data.reverse());
      });
    };

    const getState = () => {
     Apiconnect.getData("state/getall").then((response) => {
         setState(response.data.data.reverse());
      });
    };

    const getCountry = () => {
     Apiconnect.getData("country/getall").then((response) => {
         setCountry(response.data.data.reverse());
      });
    };


   function refreshPage(){ 
      window.location.reload(); 
   }

   function returnPage(){
      history.push("/company_list"); 
   }

   return (
      <>


<div id="content-page" className="content-page">
         <div className="container-fluid">

               <div className="row">
                  <div className="col-sm-12 col-lg-12 col-md-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">{id ? 'Company Edit' : 'Company Add'}</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                              <form onSubmit={e => onSubmit(e)}>
                              <div className="row">

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Company Name</label>
                                    <input type="text" className="form-control validate[required]" name="company_name" 
                                     placeholder="Company Name" 
                                     value={company_name}
                                    onChange={e => onInputChange(e)}  />
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Organasation Type</label>
                                    <select className="form-control" name="org_type" 
                                     value={org_type}
                                    onChange={e => onInputChange(e)}>
                                       <option value="">Select Type</option>
                                       {
                                          orgList.map((val,key) => {
                                             return(
                                                <option value={val.id}>{val.name}</option>
                                             )
                                          })

                                       }
                                    </select>
                                 </div>
                              </div>

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Address 1</label>
                                    <input type="text" className="form-control validate[required]" name="address_one"
                                     placeholder="Address 1" 
                                     value={address_one}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Address 2</label>
                                    <input type="text" className="form-control validate[required]" name="address_two" placeholder="Address 2"
                                     value={address_two}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Landmark</label>
                                    <input type="text" className="form-control validate[required]" name="landmark" placeholder="Landmark" 
                                     value={landmark}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>

                             
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Country</label>
                                    <select className="form-control" name="country_id"
                                     value={country_id}
                                     onChange={e => onInputChange(e)}>
                                       <option value="">Select Country</option>
                                       {
                                          countryList.map((val,key)=>{
                                             return(
                                                <option value={val.id}>{val.name}</option>
                                             )
                                          })
                                       }
                                    </select>
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>State</label>
                                    <select className="form-control" name="state_id"
                                     value={state_id}
                                     onChange={e => onInputChange(e)}>
                                       <option value="">Select State</option>
                                       {
                                          stateList.map((val, key)=>{
                                             return(
                                                <option value={val.id}>{val.name}</option>
                                             )
                                          })
                                       }
                                    </select>
                                 </div>
                              </div>

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>City</label>
                                    <select className="form-control" name="city_id"
                                     value={city_id}
                                     onChange={e => onInputChange(e)}>
                                        <option value="">Select City</option>
                                        {cityList.map((val, key)=>{
                                           return(
                                           <option value={val.id}>{val.name}</option>
                                           )
                                        })}
                                       
                                    </select>
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Area</label>
                                    <select className="form-control" name="area_id"  
                                    value={area_id}
                                    onChange={e => onInputChange(e)}>
                                        <option value="">Select Area</option>
                                      {areaList.map((val, key) => {       
                                       return (
                                          <option value={val.id}>{val.name}</option>
                                       );
                                    })}                           
                                       
                                    </select>
                                 </div>
                              </div>

                             
                            
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Zip</label>
                                    <input type="text" className="form-control validate[required]" name="zip"  placeholder="Zip" 
                                     value={zip}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Phone</label>
                                    <input type="text" className="form-control validate[required]" name="phone"placeholder="Phone" 
                                     value={phone}
                                     onChange={e => onInputChange(e)}/>
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Phone ( Secondary )</label>
                                    <input type="text" className="form-control validate[required]" name="phone_secondary" placeholder="Phone Secondary" 
                                     value={phone_secondary}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Mobile</label>
                                    <input type="text" className="form-control validate[required]" name="mobile" placeholder="Mobile" 
                                     value={mobile}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" className="form-control validate[required]" name="email" placeholder="Email" 
                                     value={email}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Email ( Secondary )</label>
                                    <input type="text" className="form-control validate[required]" name="email_secondary" placeholder="Email Secondary"
                                     value={email_secondary}
                                     onChange={e => onInputChange(e)}/>
                                 </div>
                              </div>

                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Fax</label>
                                    <input type="text" className="form-control validate[required]" name="fax"  placeholder="Fax" 
                                     value={fax}
                                     onChange={e => onInputChange(e)}/>
                                 </div>
                              </div>
                              <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Website</label>
                                    <input type="text" className="form-control validate[required]" name="website"  placeholder="Website"
                                     value={website}
                                     onChange={e => onInputChange(e)} />
                                 </div>
                              </div>


                                 <div className="col-md-12 m-t-20">
                                    <button type="submit"  name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    <button type="button" className="btn" onClick={ returnPage }> Return to campany </button> 
                                    {(() => {
                                       if (id>0) {
                                          return (
                                             <button type="button" className="btn" onClick={ refreshPage }> Return</button> 
                                          )
                                       } 
                                    })()}     
                                 </div>
                              </div>



                              </form>
                        </div>
                     </div>
                  </div>
                  
               </div>
         </div>
         </div>
      </>
   )
}

export default Company_edit
