import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import Header from './Header';
import TheContent from './TheContent';
import Apiconnect from "../services/Apiconnect.js";




export default class Profile extends Component {


  

    constructor(props) {
      super(props);
  
      this.state = {
        redirect: null,
        userReady: false,
        currentUser: { username: "" }
      };
    } 
  
    componentDidMount() {
      const currentUser = AuthService.getCurrentUser();
  
      if (!currentUser) this.setState({ redirect: "/login" });
      this.setState({ currentUser: currentUser, userReady: true })
    }
  
    render() {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />
      }
  
      const { currentUser } = this.state;
      let emp_typ =  Apiconnect.get_emp_typ();

       
      if(emp_typ==1 || emp_typ==3){
        return (
          <>
              <Header />
              <TheContent/>
          </> 
        );
      }else{
        return (
          <>
               <h1>...</h1>
          </> 
        );
      }
      
    }
  }
  
