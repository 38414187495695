import React, {useState, useEffect } from 'react';
import "./ems_pages.css";
 
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
 

function Daily_attendance() {

    const alert = useAlert();
    let history = useHistory();

   

    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id); 

    const [info, setInfo] = useState({
        emp_id: emp, 
        company_id:Apiconnect.get_company_id(),       
      });
    const { emp_id, company_id  } = info;
    

        
    const markAttendance = async e => {
        e.preventDefault(); 
        console.log(info);
         
        let info_enc =  Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };
    
        await Apiconnect.postData("employee_punchtime/attendance/",inf_a).then((response) => {
            console.log(response);             
            alert.success(response.data.message);   
         });
      };



      useEffect(() => getCats());
      useEffect(() => getDailyData());

      const [catList, setCatList] = useState([]); 
      const [dailyAttendanceList, setDailyAttendanceList] = useState([]); 

      const getCats = () => {
         Apiconnect.getData(`employee_punchtime/getinfo/${emp}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setCatList(_xtract.reverse());      
         });
      };

      const getDailyData = () => {
        Apiconnect.getData(`employee_dailydata/getinfo/${emp}`).then((response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setDailyAttendanceList(_xtract.reverse());      
        });
     };

     console.log(dailyAttendanceList);

    return (
        <>

            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Manual Attendance</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <form onSubmit={e => markAttendance(e)}>
                                        <div className="row">
                                             
                                             
                                              

                                                

                                             
                                            
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button  type="submit" className="btn btn-primary">Mark Attendance</button>
                                                 
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Latest  attendance</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body padLRTzero">

                                    <div className="table-responsive">
                            
                                        <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                                            <thead>
                                                <tr className="clr">
                                                    <th>#</th>                                     
                                                    <th>Date</th>                                    
                                                    <th>Punch Time</th>                                    
                                                                                       
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>


                                                {catList.map((val, key) => {       
                                                    return (
                                                    <tr key={key}>
                                                        <td>{key+1}</td>
                                                        <td>{val.punch_dt}</td>
                                                        <td>{val.punch_tm}</td>                                                                         
                                                        
                                                    </tr>
                                                    );
                                                })}  
                                                

                                                
                                            </tbody>
                                        </table>
                                    
                                    
                                    
                                    </div>
                                     
                                </div>
                            </div>
                        
                        
                        
                        
                        
                        </div> 


                        <div className="col-md-8">
                                <div className="iq-card">
                                        <div className="iq-card-header d-flex justify-content-between">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">Daily Record</h4>
                                        </div>
                                        </div>
                                    <div className="iq-card-body padLRTzero">
                                    
                                        <div className="table-responsive">
                                        
                                        <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                                            <thead>
                                                <tr className="clr">
                                                    <th>#</th>
                                                    <th>Date</th>
                                                    <th>In time</th>
                                                    <th>Out time</th>
                                                    <th>Break Hour</th>
                                                    <th>Production Hour</th>                                    
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>

                                            {dailyAttendanceList.map((val, key) => {      
                                                
                                                
                                                if (val.production_hour1 >= '08:00') {
                                                    var production_hour = <div className=" badge badge-pill badge-success">{val.production_hour1}</div>;
                                                 }else if(val.production_hour1 <= '08:00' && val.production_hour1 >= '04:00'){
                                                    var production_hour = <div className=" badge badge-pill badge-warning">{val.production_hour1}</div>;
                                                 }else{
                                                    var production_hour = <div className=" badge badge-pill badge-danger">{val.production_hour1}</div>;
                                                 }

                                                    return (
                                                    <tr key={key}>
                                                        <td>{key+1}</td>
                                                        <td>{val.punch_date1}</td>
                                                        <td>{val.start_time}</td>
                                                        <td>{val.end_time}</td>
                                                        <td>{val.break_hours1}</td>
                                                        <td>{production_hour}</td>                                                                         
                                                        
                                                    </tr>
                                                    );
                                                })}  
                                                
                                            </tbody>
                                        </table>
                                        </div>
                                    
                                    
                                    

                                    </div>
                                </div>
                        
                        </div>
                    </div>





 









                </div>
            </div>



        </>
    )
}

export default Daily_attendance
