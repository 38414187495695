import React, { useState, useEffect } from 'react';
import "./ems_pages.css";
import { useHistory } from "react-router-dom";
import Apiconnect from "../../services/Apiconnect.js";

const Salary_slip = () => {

let history = useHistory();
const localuser = JSON.parse(localStorage.getItem('user'));
let emp = Apiconnect.decrypt(localuser.id);

useEffect(() => { getSalarySlipData(); }, []);
const [salaryMonths, setSallaryData] = useState([]);

const getSalarySlipData = () => {
   Apiconnect.getData(`employee_monthly_salary/getsalaryslipmonths/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setSallaryData(_xtract.reverse());
   });

};

// console.log(salaryMonths);

  const viewItem = e => {
   history.push(`/ems/salary_slip_view/${e}`); 
   }

       const months = {1 :'JAN',2 :'FEB',3 :'MAR',4 :'APR',5 :'MAY',
                         6 :'JUN',7 :'JUL',8 :'AUG',9 :'SEP',10 :'OCT',11 :'NOV',12 :'DEC'};
               
                        console.log(months[1]);
    return (
        <>



       <div id="content-page" className="content-page">
             <div className="container-fluid">
                <div className="row" id="page">
                     <div className="col-sm-12">
                        <div className="iq-card">
                      <div className="head">
                          <h4 className="card-title head1">Salary Slip</h4>
                     </div>
                     <div className="iq-card-body">

                        <div className="table-responsive">
                           
                           <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                             <thead>
                                 <tr className="clr" style={{textAlign:'center'}}>
                                    <th>#</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                 </tr>
                             </thead>
                             <tbody>
                                {
                                   salaryMonths.map((value,key)=>{
                                    return (
                                    <tr key={key} style={{textAlign:'center'}}>
                                    <td style={{width:'150px'}} >{key+1}</td>
                                    <td style={{textAlign:'center'}}>{months[value.month]}</td>
                                    <td style={{width:'200px'}}>
                                       <button  className="btn btn-info mr-2" onClick={() => viewItem(value.month)}><i className="fa fa-eye" aria-hidden="true"></i></button>
                                    </td>
                                 </tr>
                                    )
                                   })
                                }
                                
                             </tbody>
                          
                           </table>
                        </div>
                     </div>
                  </div>
                  </div>
                </div>

          </div>
        </div>



        </>
    )
}

export default Salary_slip
