import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import "react-datepicker/dist/react-datepicker.css";
import "./ems_pages.css";


function Reimbursement() {

   const localuser = JSON.parse(localStorage.getItem('user'));
   let empid = Apiconnect.decrypt(localuser.id);

   const alert = useAlert();

   //////////USE EFFECT
   useEffect(() => {
      getreimbursementList();
   }, []
   );

   const [reimbursement_head, setReimbursement_head] = useState([]);

   const getreimbursementList = () => {
      // Apiconnect.getData(`employee_salary/getreimbursementhead/${empid}`).then((response) => {
      //    let _xtract = Apiconnect.decrypt_obj(response.data.data);
      //    setReimbursement_head(_xtract);
      // });

      Apiconnect.getData(`employee_salary/getreimbursementhead/${empid}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
         console.log(_xtract);
          setReimbursement_head(_xtract);
      });

   };
   

    return (
        <>

       <div id="content-page" className="content-page">
             <div className="container-fluid">
                <div className="row">
                     <div className="col-sm-12">
                        <div className="iq-card">
                      <div className="head">
                          <h4 className="card-title head1">Reimbuesement Report</h4>
                     </div>
                     <div className="iq-card-body">
                        <div className="table-responsive">
                           
                           <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                             <thead>
                                 <tr className="clr">
                                    <th>#</th>
                                    <th>Reimbuesement</th>
                                    <th>Eligibility Amount (RS/-)</th>
                                    <th>Current Month Claimed Amount (RS/-)</th>
                                 </tr>
                             </thead>
                             <tbody>
                                
                                    {reimbursement_head.map((valy, keyy) => {


                                       var total = 0;
                                       if(valy.total !== null){
                                          total = valy.total;
                                       }
                                      
                                    return ( 
                                            <tr  key={keyy}>
                                                <td>{keyy+1}</td>
                                                <td>{valy.name}  </td>
                                                <td>{valy.amount} </td>
                                                <td>{total}</td>
                                            </tr> 
                                        );
                                    })}     

                             </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                  </div>
                </div>

          </div>
        </div>



        </>
    )
}

export default Reimbursement
