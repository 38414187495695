import React, {useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import '../pages/pages.css';
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select';
import Axios from 'axios';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';



const Manual_leaves = () => {

   const alert = useAlert();
   let history = useHistory();
   const localuser = JSON.parse(localStorage.getItem('user'));
   let user_id = Apiconnect.decrypt(localuser.id); 
//    const localuser = JSON.parse(localStorage.getItem('user'));
//    let empid = Apiconnect.decrypt(localuser.id);
   
   const [employee, setEmployee] = useState([]);
   const [info, setInfo] = useState({
        is_carryforward: '',typ: "",leave_typ: "",credit_ym: "",reason: "",leave_count: "",
        company_id:Apiconnect.get_company_id(),emp_id:'',status:0,
        id: null,
    });
    const { is_carryforward, typ,leave_typ,credit_ym,reason,leave_count,company_id,emp_id,id } = info;
    const onInputChange = e => {

      console.log(e);
      setInfo({ ...info, [e.target.name]: e.target.value });
    };

    const onSubmit = async e => {
      e.preventDefault();

      console.log(Object.keys(employee).length);
      if(Object.keys(employee).length == 0){
         alert.success('select Employee'); 
         return false;
      }
     // return false;
      if(id>0){

         console.log('in edit function.....' );
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc,employee:employee,user_id:user_id};
         await Apiconnect.postData("employee_leave/update",inf_a).then((response) => {
            setInfo({is_carryforward: '',typ: "",leave_typ: "",credit_ym: "",reason: "",leave_count: "", id: '',emp_id:'', company_id:1});
            alert.success(response.data.message);   
         })

      }else{

         info.leave_start_date = format(new Date(), 'yyyy-MM-dd');
         info.leave_end_date = format(new Date(), 'yyyy-MM-dd');
         info.credit_ym = format(new Date(), 'yyyy-MM');
        
         console.log('in add function.....' );
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc,employee:employee,user_id:user_id };
         //   Axios.post(`http://localhost:3050/api/tush/employee_leave`, inf_a).then((response) => {
          await Apiconnect.postData("employee_leave/create",inf_a).then((response) => {
            setInfo({is_carryforward: '',typ: "",leave_typ: "",credit_ym: "",reason: "",leave_count: "", id: '',emp_id:'', company_id:1});
            alert.success(response.data.message);   
         })
        
      }
      const selected_emp = {};
      getLeaveList();
    };

    const getItem = (id) => {    
      Apiconnect.getData(`employee_leave/get/${id}`).then((response) => {        
         let _xtract = Apiconnect.decrypt_obj(response.data.data);      
         setInfo({ is_carryforward: _xtract[0].is_carryforward, typ: _xtract[0].typ,leave_typ: _xtract[0].leave_typ,
            reason: _xtract[0].reason,leave_count: _xtract[0].leave_count,emp_id: _xtract[0].emp_id, id: _xtract[0].id });      
      });
    };

    const ondlt = (id) => {
      confirmAlert({
        title: 'Are you sure to Delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteItem(id), 
          },
          {
            label: 'No',
          }
        ]
      });
    };

    const deleteItem = (id) => {

         Apiconnect.deleteData(`employee_leave/delete/${id}/${user_id}`).then((response) => {
               getLeaveList();
               alert.success(response.data.message);      
         });
    };

   
   useEffect(() => {getLeaveList();leaveType();getEmp();getEmployeeManualLeave()}, []);

   const [dwnEmpManualLeaveList, setEmpManualLeaveList] = useState([]);

   const getEmployeeManualLeave = () => {
      var company_id = Apiconnect.get_company_id();
      Apiconnect.getData(`employee_leave/getallexport/${company_id}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         console.log(_xtract);
         setEmpManualLeaveList(_xtract.reverse());
      });
   }



   const [leaveList,setleaveList]= useState([]);

   const getLeaveList = () => {
        Apiconnect.getData(`employee_leave/getall/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setleaveList(_xtract.reverse());      
         });
      }


   const [List,setleaveType]= useState([]);
   const leaveType = ()=>{
    Apiconnect.getData(`company_leave_policy/getall/${Apiconnect.get_company_id()}`).then((response) => {
       let _xtract = Apiconnect.decrypt_obj(response.data.data);
       setleaveType(_xtract.reverse());
    });
 }

 const [empList, setEmp] = useState([]);
    const getEmp = () => {
        Apiconnect.getData(`employee/getusers/${Apiconnect.get_company_id()}`).then((response) => {        
            let _xtract = Apiconnect.decrypt_obj(response.data.data);      
            setEmp(_xtract.reverse());
         });
    }


   function refreshPage(){ 
      window.location.reload(); 
   }

    const selected_emp = {};
    empList.map((val,key)=>{
      if(val.id === emp_id){
         selected_emp.label =  val.first_name+' '+val.last_name;
         selected_emp.value =  val.id;
      }
    });

   //  if(Object.keys(selected_emp).length == 0){
   //   const selected_emp = '';
   //  }
 
     console.log(employee);

   return (
      <>
         <div id="content-page" className="content-page">
         <div className="container-fluid">
               <div className="row">
                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">{id ? 'Manual Leave Apply' : 'Manual Leave Apply'}</h4>
                           </div>
                           

                        </div>
                        <div className="iq-card-body">
                              <form onSubmit={e => onSubmit(e)}>
                              <div className="row">

                                 <div className="col-md-4">
                                    <div className="form-group">
                                    <label>Leave Type <span className="required">*</span></label>
                                              <select className="form-control" name="leave_typ" required
                                                value={leave_typ}
                                                onChange={e => onInputChange(e)}>
                                                    <option value="">Select Leave Type</option>
                                                {List.map((val, key) => {       
                                                return (
                                                    <option key={key} value={val.id}>{val.name}</option>
                                                );
                                                })}                           
                                          </select>
                                    </div>
                                 </div>
                                     {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Employee <span className="required">*</span></label>
                                            <select className="form-control" name="emp_id" value={emp_id} onChange={e => onInputChange(e)} required>
                                                <option value="">Select Employee</option>
                                                {
                                                    empList.map((val,key)=>{
                                                        return(
                                                            <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div> */}

                                    { Object.keys(selected_emp).length !== 0 ?
                                     <div className="col-md-4">
                                     <label>Employee <span className="required">*</span></label>
                                        <Select
                                             options={empList.map(opt => ({ label: opt.first_name+' '+opt.last_name, value: opt.id }))}
                                             isMulti
                                             onChange={opt => setEmployee(opt)}
                                             value={selected_emp}
                                        />
                                       </div>
                                     :
                                     <div className="col-md-4">
                                     <label>Employee <span className="required">*</span></label>
                                        <Select
                                             options={empList.map(opt => ({ label: opt.first_name+' '+opt.last_name, value: opt.id }))}
                                             isMulti
                                             onChange={opt => setEmployee(opt)}
                                        />
                                       </div>
                                      }

                                    {/* <div className="col-md-4">
                                    <label>Employee <span className="required">*</span></label>
                                       <Select
                                            options={empList.map(opt => ({ label: opt.first_name+' '+opt.last_name, value: opt.id }))}
                                            isMulti
                                            onChange={opt => setEmployee(opt)}
                                            value={Object.keys(selected_emp).length != 0 ?selected_emp:''}
                                       />
                                      </div> */}

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Is Carryforward</label>
                                            <select className="form-control" name="is_carryforward" value={is_carryforward} onChange={e => onInputChange(e)}>
                                                <option value="">Select carryforward</option>
                                                <option value='1'>YES</option>
                                                <option value='0'>NO</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Type <span className="required">*</span></label>
                                            <select className="form-control" name="typ" value={typ} onChange={e => onInputChange(e)} required>
                                                <option value="">Select Type</option>
                                                <option value='credit'>Credit</option>
                                                <option value='debit'>Debit</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Leaves Count <span className="required">*</span></label>
                                        <input type="text" className="form-control" name="leave_count" 
                                        placeholder="Leaves Count" 
                                        value={leave_count}
                                        onChange={e => onInputChange(e)} required  />
                                    </div>
                                </div>

                                    <div className="col-md-6">
                                         <div className="form-group">    
                                              <label>Description <span className="required">*</span></label>
                                              <textarea className="form-control" required name="reason" value={reason} onChange={e=>onInputChange(e)} required=""></textarea>
                                          </div>
                                      </div>

                                     

                                    
                                
                                 <div className="col-md-12 mt-2">
                                    <button type="submit"  name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    {(() => {
                                       if (id>0) {
                                          return (
                                             <button type="button" className="btn" onClick={ refreshPage }> Return </button> 
                                          )
                                       } 
                                    })()}     
                                 </div>
                              </div>



                              </form>


                        </div>
                     </div>
                  </div>

                  <div className="col-md-12">
                        <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List of leaves</h4>
                           </div>
                           <div style={{ float: 'right' }}>
                              <Link style={{ marginTop: '5px',float: 'right',marginLeft: '5px' }} className="btn btn-info" to="/employee_upload_bulk">Upload Bulk</Link>
                              <CSVLink filename={"employee-info.csv"} style={{ marginTop: '5px', float: 'right', marginLeft: '5px' }} className="btn btn-warning" data={dwnEmpManualLeaveList}  >
                                    <i class="fa fa-download"></i>Employee Leave
                              </CSVLink>      
                           </div>
                        </div>
                     <div className="iq-card-body padLRTzero">
                    
                        <div className="table-responsive">
                           
                           <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                             <thead>
                                 <tr className="clr">
                                    <th>#</th>
                                    <th>Applied On</th>
                                    <th>Leave Type</th>
                                    <th>Days</th>
                                    {/* <th>Date</th> */}
                                    <th>Type</th>
                                    <th>Reason</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                 </tr>
                             </thead>
                             <tbody>

                             {leaveList.map((val, key) => {      

                                        if(val.status == 0){
                                            var status =<div className=" badge badge-pill badge-warning">Pending</div>;
                                        }
                                        if(val.status == 1){
                                            var status = <div className=" badge badge-pill badge-success">Approved</div>;
                                        }
                                        if(val.status == 2){
                                            var status = <div className=" badge badge-pill badge-danger">Cancelled</div>;
                                        }

                                       return (
                                          <tr key={key}>  
                                             <th scope="row">{key+1}</th>
                                             <td>{val.applied_on}</td>
                                             <td>{val.name}</td>
                                             <td>{val.leave_count}</td>
                                             {/* <td>{val.leave_start_date1	} to {val.leave_end_date1}</td> */}
                                             <td>{val.typ}</td>
                                             <td>{val.reason}</td>
                                             <td>
                                                 {status}

                                             {/* <div className=" badge badge-pill badge-success">Approved</div>
                                             <div className=" badge badge-pill badge-danger">Cancelled</div>
                                             <div className=" badge badge-pill badge-warning">Pending</div> */}
                                             </td>
                                             <td>
                                             <button onClick={() => {ondlt(val.id);}}
                                             className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>

                                             <button onClick={() => {getItem(val.id);}}
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                             </td>
                                          </tr> 
                                       );
                                    })}          
                                  
                             </tbody>
                           </table>
                        </div>
                       
                     </div>
                  </div>
                  </div>

               </div>

         </div>
         </div>
      </>
   )
}

export default Manual_leaves