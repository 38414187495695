import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic"; 

import App from "./App";
import * as serviceWorker from "./serviceWorker";


const options = {
  timeout: 3000, 
};


ReactDOM.render(
  <BrowserRouter>
    <Provider template={AlertTemplate} {...options}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
