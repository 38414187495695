import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';

function Employee_punchtime_report() {

    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id);

    var date = new Date();
    // const [from_date,setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [from_date,setFromDate] = useState(new Date());
    const [to_date,setToDate] = useState(new Date());
    const [emp_id,setempid] = useState(null);

    const onInputChange = e => {
        setempid(e.target.value);
    }


    useEffect(() => { empdata();punchtime(from_date,to_date,emp_id) }, []);
    const [empList, setEmpList] = useState([]);
    const [punchtimeList, setpunchtimeList] = useState([]);


    const empdata = () => {
        Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then((response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setEmpList(_xtract.reverse());
        });
      }

    const submit = () =>{

        let info = { company_id: Apiconnect.get_company_id(), from_date:format(from_date, 'yyyy-MM-dd') ,to_date:format(to_date, 'yyyy-MM-dd'),emp_id:emp_id };
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

          Apiconnect.postData(`employee_punchtime/getempdata`,inf_a).then((response) => {
          //  Axios.post(`http://localhost:3050/api/employee_punchtime/getempdata`,inf_a).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
             setpunchtimeList(_xtract.reverse());
          });
    }

    

      const punchtime = (from_date,to_date,emp_id) =>{

        let info = { company_id: Apiconnect.get_company_id(), from_date:format(from_date, 'yyyy-MM-dd') ,to_date:format(to_date, 'yyyy-MM-dd'),emp_id:emp_id };
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

        Apiconnect.postData(`employee_punchtime/getempdata`,inf_a).then((response) => {
      //      Axios.post(`http://localhost:3050/api/employee_punchtime/getempdata`,inf_a).then((response) => {
             let _xtract = Apiconnect.decrypt_obj(response.data.data);
             setpunchtimeList(_xtract.reverse());
          });
      }

     

    return (
        <>
           <div id="content-page" className="content-page">
              <div className="container-fluid">
  
  
                 <div className="row">
                    <div className="col-sm-12">
                       <div className="iq-card">
                          <div>
                             <h4 className="card-title hd">Employee Atteandance</h4>
                          </div>
                          <div className="iq-card-body">
  
                             <div className="row p_t_15">
  
                                <div className="col-md-3">
                                   From :  <br /><DatePicker
                                      selected={from_date}
                                      onChange={e => setFromDate(e)}
                                      className="form-control"
                                      dateFormat='yyyy-MM-dd'
                                      placeholderText="From Date"
                                      name="from_date"
                                      required=""
                                   />
                                </div>
                                <div className="col-md-3">
                                   TO :  <br /> <DatePicker
                                      selected={to_date}
                                      onChange={e => setToDate(e)}
                                      className="form-control"
                                      dateFormat='yyyy-MM-dd'
                                      placeholderText="To Date"
                                      name="to_date"
                                      required=""
                                   />
                                </div>
                                <div className="col-md-3">
                                   User : <br />
                                   <select className="form-control" name="emp_id" value={emp_id} onChange={e => onInputChange(e)}>
                                      <option value="">Select User</option>
                                      {empList.map((val, key) => {
                                         return (
                                            <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                         );
                                      })}
                                   </select>
                                </div>
                                <div className="col-md-2">
                                   <input type="button" className="btn btn-success" value="Submit" style={{ marginTop: "23px" }} onClick={e => submit(e)} />
                                </div>
  
                             </div>
                           
                             <div className="row" style={{marginTop:'25px'}} >
                                 
                             <div className="col-lg-6">
                           <div className="iq-card">
                              {/* <img src="../images/small/leave.jpg" className="img-fluid w-100 rounded" alt="#" />
                              <div className="iq-card-header d-flex justify-content-between">
                                 <div className="iq-header-title">
                                    <h4 className="card-title">Latest Atteandance</h4>
                                 </div>
                              </div> */}

                        <div class="iq-card-body" style={{padding: '10px'}}>


                           {punchtimeList.map((val, key) => {

                                 if(val.thumb == null){
                                    var img = '/images/user/1.jpg'+val.thumb;
                                 }else{
                                    var img = process.env.React_App_BASE_URL_API+'/uploads/thumb/'+val.thumb;
                                 }

                              return (
                                 <>
                                    <div class="media-support" style={{display: 'unset'}}>
                                       <div class="media-support-header mb-2">
                                          <div class="media-support-user-img mr-3">
                                             <img class="rounded-circle" style={{height: '50px',width: '50px'}} src={img} alt="" />
                                          </div>
                                          <div class="media-support-info mt-2" style={{lineHeight:'13px'}}>
                                             <h6 class="mb-0"><a href="#" class=""><strong>{val.first_name+' '+val.last_name}</strong></a></h6>
                                             <small>Punched At : {val.punch_tm}</small><br/>
                                             <small>{val.punch_dt}</small>
                                          </div>
                                       </div>
                                    </div>
                                    <hr class="mt-1 mb-1" style={{marginBottom:'0px !important',marginTop:'0px !important'}} />
                                 </>
                              );
                           })}
                        </div>

                     </div>
                  </div>
  
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
  
              </div>
           </div>
  
  
        </>
     )

}
export default Employee_punchtime_report