import React, {useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { Link } from 'react-router-dom'

const Company_list = () => {

    const alert = useAlert();
    let history = useHistory();

    useEffect(() => {getComppanyList();},[]);
    const [List,setCompanyList]=useState([]);

    const getComppanyList = ()=> {
      Apiconnect.getData("company/getall").then((response)=>{
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setCompanyList(_xtract.reverse());
      });
    }


    const deleteItem = (id) => {
      Apiconnect.getData(`company/employeeDataByCompany/${id}`).then((response)=>{
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         if(_xtract.length > 0){
            alert.success('Company Can\"t Delete');
         }else{
               Apiconnect.deleteData(`company/delete/${id}`).then((response) => {
          getComppanyList();
         alert.success(response.data.message);      
      });
         }

      });


   
 };


    return(
<>
<div id="content-page" className="content-page">
         <div className="container-fluid">
               <div className="row">
                  <div className="col-sm-12 col-lg-12 col-md-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List Of Company</h4>
                           </div>
                           <div style={{ float: 'right' }}>
                              <Link style={{ float: 'right', marginLeft: '5px' }} className="btn btn-success" to="/company" >Add New</Link>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                                 <thead className="">
                                    <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Name</th>
                                       <th scope="col" style={{textAlign:'center'}}>Logo</th>
                                       <th scope="col">Address</th>
                                       <th scope="col">Landmark</th>
                                       <th scope="col">Phone</th>
                                       <th scope="col">Email</th>
                                       <th scope="col">Website</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody className="">
                                   {
                                       List.map((val, key) => { 

                                          if(val.logo == null){
                                             var img = '/images/user/1.jpg'; 
                                          }else{
                                             var img = process.env.React_App_BASE_URL_API+'/uploads/logo/'+val.logo;
                                          }

                                            return(
                                             <tr key={key}>
                                             <th scope="row">{key+1}</th>
                                             <td>{val.company_name}</td>
                                             <td style={{width:'130px',textAlign:'center'}}>
                                             <div className="" style={{display:'grid'}}>
                                                <img src={img} alt="profile-img"  className="prof" />
                                                <Link to={`/company_upload_logo/${val.id}`} >Change Pic</Link>
                                             </div>
                                             </td>
                                             <td>{val.address_one}</td>
                                             <td>{val.landmark}</td>
                                             <td>{val.phone}</td>
                                             <td>{val.email}</td>
                                             <td>{val.website}</td>
                                               <td>
                                                <button onClick={() => {deleteItem(val.id);}} className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                             {/* <button onClick={() => {deleteItem(val.id);}} className="btn btn-warning mr-2" style={hide}><i className="fa fa-trash" aria-hidden="true"></i></button> */}
                                              <Link   to={`/company_edit/${val.id}`} 
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                             </td>
                                          </tr>  
                                            )
                                       })
                                   }                 
                                 </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
 
         </div>
         </div>
</>
    )
}

export default Company_list