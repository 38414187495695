import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import "react-datepicker/dist/react-datepicker.css";
import DateTimePicker from 'react-datetime-picker';

const Manual_attendance_master = () => {

    const localuser = JSON.parse(localStorage.getItem('user'));
    let empid = Apiconnect.decrypt(localuser.id);

    const alert = useAlert();
    let history = useHistory();
    const [info, setInfo] = useState([]);
    const { remark } = info;
    const onInputChange = (e) => {
        setInfo({ ...info, [e.target.name]: e.target.value });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
    }

    useEffect(() => { attendancedata(); }, []);
    const [list, setAttendanceList] = useState([]);

    const attendancedata = () => {
        Apiconnect.getData(`employee_punchtime/manual_attendance_repoterwise/${empid}`).then((response) => {
        //    let _xtract = Apiconnect.decrypt_obj(response.data.data);
        console.log(response.data.data);
           setAttendanceList(response.data.data.reverse());
        });
     }

     const attendanceApprove = e => {

        let info1 = { id: e, is_approved: 1};
        let info_enc = Apiconnect.encrypt_obj(info1);
        const inf_a = { enc: info_enc };
        Apiconnect.postData("employee_punchtime/manual_attendance_updatestatus/", inf_a).then((response) => {
           alert.success(response.data.message);
           attendancedata();
        });

     }

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Manual Attendance</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <div className="table-responsive">

                                        <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                            <thead>
                                                <tr className="clr" >
                                                    <th>#</th>
                                                    <th>Emp. Name</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Reason</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list.map((val,key)=>{
                                                        return(
                                                            <tr key={key}>
                                                            <th scope="row">{key + 1}</th>
                                                            <td>{val.first_name} {val.last_name}</td>
                                                            <td>{val.punch_date1}</td>
                                                            <td>{val.punch_time1}</td>
                                                            <td>{val.remark}</td>
                                                            <td><div className="btn badge badge-pill badge-success" onClick={e => attendanceApprove(val.id)}>Approve</div></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )

}

export default Manual_attendance_master