import React, { useState, useEffect } from 'react';
import Apiconnect from "../services/Apiconnect.js";
import './public.css';
import Loadder from "./webview_lodder";


function Webview_my_team(props) {
    
    let emp = props.match.params.id;

    useEffect(() => { team(); }, []);
    const [List, setList] = useState([]);

    const team = e => {
        Apiconnect.getData(`employee/getallempbyreportingofficer/${emp}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setList(_xtract.reverse());
        });

    }

    console.log(List);

    return (
        <>
           <div id="content-page" className="" style={{marginLeft:'unset !important',overflow: 'hidden',background: '#e2e2e2',padding: '20px 15px',minHeight: '100vh'}}>
        <div className="container-fluid leave_div">
                    <div className="row">
                        {/* <div className="col-lg-6"> */}
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Employee List</h4>
                                    </div>
                                </div>
                                <div style={{padding:'8px !important'}} className="iq-card-body" >
                                    <ul className="media-story m-0 p-0">
                                        <li className="d-flex mb-4 align-items-center active">
                                            <div className="stories-data ml-4">
                                                <h5>You have {List.length} members in your team...</h5>
                                            </div>
                                        </li>

                                        {List.map((val, key) => {

                                            if (val.thumb == null) {
                                                var img = '/images/user/1.jpg';
                                            } else {
                                                var img = process.env.React_App_BASE_URL_API+'/uploads/thumb/' + val.thumb;
                                            }

                                            return (
                                                <li key={key} className="d-flex mb-4 align-items-center active" style={{border: '2px solid',borderRadius: '10px',padding: '8px'}}>
                                                    <img src={img} style={{ width: '70px', height: '70px' }} alt="story-img" className="rounded-circle img-fluid" />
                                                    <div className="stories-data ml-4">
                                                        <h5>{val.first_name + ' ' + val.last_name}</h5>
                                                        <span className="mb-0">Code : {val.emp_code}</span><br />
                                                        <span className="mb-0">Email : {val.email}</span><a href={'mailto:'+val.email}><span className="fa fa-envelope-square" aria-hidden="true" style={{paddingLeft:'5px', paddingRight:'10px'}}></span></a><br />
                                                        <span className="mb-0">Phone : {val.phone}</span><a href={'tel:'+val.phone}><span className="fa fa-phone-square" aria-hidden="true" style={{paddingLeft:'5px', paddingRight:'10px'}}></span></a>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        {/* </div> */}

                    </div>
                </div>
            </div>
        </>
    )

}
export default Webview_my_team