import React from 'react';


function Team_member_attendance() {
    return (
<>
<div id="content-page" className="content-page">
             <div className="container-fluid">

                  
                <div className="row">
                     <div className="col-sm-12">
                        <div className="iq-card">
                          <div>
                          <h4 className="card-title hd">Team Member Attendance</h4>
                     </div>
                     <div className="iq-card-body">

                         <div className="row p_t_15">
                            <div className="col-md-2">
                            </div>
                      <div className="col-md-3">
                         Date : <input type="text" className="datepicker form-control" data-date-format="dd/mm/yyyy" name=""/>
                      </div>
                     
                    </div>  

                         <div className="row">

                             <div className="table-responsive">
                           
                           <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                             <thead>
                                 <tr className="clr" >
                                    <th><div className="checkbox">
                                      <input className="mr-2" id="all_check" type="checkbox"/></div>
                                   </th>
                                    <th>Emp. Name</th>
                                    <th>Emp. Code</th>
                                    <th>Department</th>
                                    <th>Location</th>
                                    <th>InTime</th>
                                    <th>OutTime</th>
                                    <th>Status</th>
                                    <th>Remarks</th>
                                    <th>Delete</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr>
                                    <td><div className="checkbox">
                                      <input className="mr-2 ck" type="checkbox"/></div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><input type="time" name=""/></td>
                                    <td><input type="time" name=""/></td>
                                    <td></td>
                                    <td><input type="text" name=""/></td>
                                    <td><button>Delete</button></td>
                                 </tr> 
                             </tbody>
                           </table>
                        </div>

                         </div>
                          <div className="row p_t_50">
                      <div className="col-md-12 m-t-20">
                         <button type="submit" className="btn btn-primary">Submit</button>
                      </div>
                    </div>   
                     </div>
                  </div>
                  </div>
                </div>

          </div>
        </div>     
       
          
</>
    )
}

export default Team_member_attendance
