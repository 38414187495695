import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../services/Apiconnect.js";
import './public.css';
import Loadder from "./webview_lodder";

function Webview_daily_punches(props) {
    const alert = useAlert();
    let history = useHistory();
    const [loader, setLoader] = useState(false);

    useEffect(() => { getdailypunches();}, []);
    const [dailypunchesList, setdailypunchesList] = useState([]);

    const getdailypunches = () => {
        setLoader(true);
        Apiconnect.getData(`employee_dailydata/getalldailypunches`).then((response) => {
            let _xtract = response.data.data;
            setdailypunchesList(_xtract);
            setLoader(false);
        });
    }

    return (
        <>
                <div id="content-page" className="" style={{ marginLeft: 'unset !important', overflow: 'hidden', background: '#e2e2e2', padding: '20px 15px', minHeight: '100vh' }}>
                <div className="">
                {
         loader ? <Loadder/>:
                    <>
                    <div className="holiday" style={{display:'unset !important'}}>
                        <div className="iq-card">
                            <div className="iq-card-header justify-content-between">
                                <div className="iq-header-title" style={{paddingTop:'5px'}}>
                                    <h3 className="card-title" style={{paddingTop: '5px'}}>Today's Punches</h3>
                                </div>
                            </div>
                            <div className="row"></div>
                            <div style={{padding:'5px !important'}} className="iq-card-body" >
                            <table className="table table-striped table-hover">
                                        <thead className="" >
                                            <tr style={{'textAlign':'center'}}>
                                                <th scope="col">#</th>
                                                <th scope="col" style={{width:'70px'}}>Profile</th>
                                                <th scope="col">Employee</th>
                                                <th scope="col">Punches</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {
                                               dailypunchesList.map((val,key)=>{

                                                if(val.thumb == null){
                                                    var img = '/images/user/1.jpg'; 
                                                 }else{
                                                    var img = process.env.React_App_BASE_URL_API+'/uploads/thumb/'+val.thumb;
                                                 }

                                                   return(
                                                       <tr style={{'textAlign':'center'}}>
                                                           <td scope="row">{key+1}</td>
                                                           <td>
                                                            <div className="" style={{display:'grid'}}>
                                                                <img src={img} alt="profile-img"  className="prof" />
                                                            </div>
                                                            </td>
                                                           <td>{val.name}</td>
                                                           <td>{val.punches}</td>
                                                       </tr>
                                                   )
                                               }) 
                                            }
                                        </tbody>
                                    </table>
                            </div>
                        </div>

                    </div>
                    </>
                    }
                </div>
            </div>

        </>
    )

}

export default Webview_daily_punches