import React, { useState, useEffect, Component } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import "./ems_pages.css";
import moment from 'moment'
import { format } from 'date-fns';
import * as html2canvas from 'html2canvas';
import jsPDF from 'jspdf'

function Salary_slip_view(props) {
    let history = useHistory();

    const local_user = JSON.parse(localStorage.getItem('user'));
    let emp_id = local_user.emp_id;
    function returnPage() {
        history.push("/ems/salary_slip");
    }
    let id = props.match.params.id;
    let months = Number(id) - 1;

    useEffect(() => { getsalaryMonthWise();getEmpInfo(); }, []);
    const [info, setInfo] = useState({
        name: '', department: '', emp_code: '', account_number: '', bank_name: '', pan: '',esi_number:'',epf_number:''
    });
    const { name, department, emp_code, account_number, bank_name, pan,epf_number,esi_number } = info;

    const getEmpInfo = () =>{
        Apiconnect.getData(`employee_monthly_salary/getempsalaryinfo/${emp_id}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            // console.log(_xtract);
            setInfo({
                name: _xtract[0].first_name + ' ' + _xtract[0].last_name, department: _xtract[0].department, emp_code: _xtract[0].emp_code,
                account_number: _xtract[0].account_number, bank_name: _xtract[0].bank_name, pan: _xtract[0].pan,esi_number:_xtract[0].esi_number,
                epf_number:_xtract[0].epf_number
            });
        });
    }

    const [salaryMonth, setSalaryMonthList] = useState([]);
    const [deduction_tot, setDeduction_tot] = useState(0);
    const [earning_tot, setEarning_tot] = useState(0);

    const getsalaryMonthWise = () => {
        const info = {};
        info.id = id;
        info.emp_id = emp_id;
        let info_enc =  Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };
        console.log(info);
        Apiconnect.postData(`employee_monthly_salary/getsalarymonthwise`,inf_a).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            if(_xtract.length <= 0){
            }else{
                setEarning_tot(_xtract[0].earning_tot);
                setDeduction_tot(_xtract[0].deduction_tot);
                console.log(_xtract);
                setSalaryMonthList(_xtract.reverse());
               
            }
           
        });
    };

    const mn = [
        'JAN', 'FEB', 'MAR', 'APR',
        'MAY', 'JUN', 'JUL', 'AUG',
        'SUP', 'OCT', 'NOV', 'DEC'
    ]

  
    
    const generatePDF = e => {

        var doc = new jsPDF('l', 'pt','a3');
      
        doc.html(document.getElementById('print'), {
        callback: function (doc) {
            doc.save(name+' payslip_'+mn[months]);
        },
        x:70,
        y: 10,
        });
    }

    var year = new Date().getFullYear();
    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="iq-card">
                                <div className="iq-card-body"  >
                                <div className="col-md-12 m-t-20" style={{paddingBottom:'30px'}}>
                                        <button  className="btn btn-success mr-2" onClick={e=>generatePDF(e)} style={{cursor: 'pointer',float: 'right'}}><i className="fa fa-download" aria-hidden="true" ></i> Download</button>
                                    </div>
                                    <div className="container mt-5 mb-5"id="print" style={{'backgroundColor': 'unset'}}>
                                        <div className="row" style={{ border: '1px solid black' }}>
                                            <div className="col-sm-12 col-lg-12" style={{ backgroundColor: '#c2d69b', marginBottom: '10px' }}>
                                                <b> {local_user.company_name}</b>
                                                <div className="text-center lh-1 mb-2">
                                                    <h6 className="fw-bold">Payslip</h6> <span className="fw-normal">Payment slip for the month of <b>{mn[months]} {year}</b></span>
                                                </div>
                                            </div>

                                            <div className="col-md-12">

                                                {/* <div className="d-flex justify-content-end"> <span>Working Branch:ROHINI</span> </div> */}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                        <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>EMP Name :</b> </span>{name} </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Bank Name :</b> </span> {bank_name} </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>ESI No. :</b> </span> {esi_number} </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>EMP Code :</b> </span> {emp_code} </div>
                                                            </div>
                                                           
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Bank A/C no. :</b> </span> {account_number} </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>PF No. :</b> </span> {epf_number} </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Employee Group :</b> </span> {department} </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Branch Name :</b> </span>  </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Payslip no :</b> </span>  </div>
                                                            </div>
                                                        </div>
                                                       
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6"> </div>
                                                        <div className="col-md-6"> </div>
                                                    </div>
                                                    <table className="mt-4 table table-bordered">
                                                        <thead className="bg-dark text-white">
                                                            <tr>
                                                                <th scope="col">Earnings</th>
                                                                <th scope="col">Amount</th>
                                                                <th scope="col">Deductions</th>
                                                                <th scope="col">Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td scope="row" colSpan="2" style={{ padding: '0px' }}>
                                                                    <table style={{ width: '100%' }}>
                                                                        {
                                                                            salaryMonth.map((val, key) => {
                                                                                if (val.typ == 'earning') {

                                                                                    return (
                                                                                        <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                                                                                            <th style={{ border: 'none' }}> {val.earning_name}</th>
                                                                                            <td style={{ textAlign: 'center', border: 'none' }}>{val.amount}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }

                                                                            })
                                                                        }
                                                                    </table>
                                                                </td>
                                                                <td colSpan="2" style={{ padding: '0px' }}>
                                                                    <table style={{ width: '100%' }}>
                                                                        {
                                                                            salaryMonth.map((val, key) => {
                                                                                if (val.typ == 'deduction') {
                                                                                    return (
                                                                                        <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                                                                                            <th style={{ border: 'none' }}> {val.deduction_name}</th>
                                                                                            <td style={{ textAlign: 'center', border: 'none' }}>{val.amount}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </table>
                                                                </td>

                                                            </tr>

                                                            <tr className="border-top">
                                                                <th scope="row">Total Earning</th>
                                                                <th>{earning_tot}</th>
                                                                <th>Total Deductions</th>
                                                                <th>{deduction_tot}</th>
                                                            </tr>

                                                            

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div style={{paddingTop: '10px',fontSize: '23px'}}><b>Net Amount : </b> {earning_tot-deduction_tot}</div>

                                                <div className="d-flex justify-content-end">
                                                    <div className="d-flex flex-column mt-2"> <span className="fw-bolder"></span> <span className="mt-4">Authorised Signatory</span> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 m-t-20">
                                        <button type="button" className="btn btn-info" onClick={returnPage}> Back </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Salary_slip_view