import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

const Short_leaves = () => {

    const alert = useAlert();
    let history = useHistory();
    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id);
    const [fromDate, setfromDate] = useState(new Date());
    const [toDate, sertoDate] = useState(new Date());

    const [info, setInfo] = useState({
        reason: "",start_time:'',end_time:'',
        id: null, emp_id: emp, status: 1,
    });
    const { reason,start_time, end_time,id } = info;
    const onInputChange = e => {
        setInfo({ ...info, [e.target.name]: e.target.value });
    };

    const onSubmit = async e => {
        e.preventDefault();
        var startTime = info.start_time;
        var endTime = info.end_time;
        var todayDate = moment(new Date()).format("MM-DD-YYYY"); //Instead of today date, We can pass whatever date        

        var startDate = new Date(`${todayDate} ${startTime}`);
        var endDate = new Date(`${todayDate } ${endTime}`);
        var timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
    
        var hh = Math.floor(timeDiff / 1000 / 60 / 60);   
        hh = ('0' + hh).slice(-2)
       
        timeDiff -= hh * 1000 * 60 * 60;
        var mm = Math.floor(timeDiff / 1000 / 60);
        mm = ('0' + mm).slice(-2)

        if((Number(hh) <= 1 && Number(mm) == 0) || (Number(hh) == 0 && Number(mm) <= 60)){
            console.log(Number(hh)+' '+Number(mm));
        }else{
            alert.success('Please select One Hours Interval');
            return false;
        }
        
    //    return false;
    info.date = format(fromDate, 'yyyy-MM-dd');
    info.date1 = format(fromDate, 'yyyy-MM');
    console.log(info);
        let info_enc =  Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };
        await Apiconnect.postData("leave_short/create", inf_a).then((response) => {
        //    history.push("/ems/leave"); 
           setInfo({ reason: "",start_time:'',end_time:'',
           id: null, emp_id: emp, status: 1});
           alert.success(response.data.message);  
           console.log(response.data);
        });

        
    }
    useEffect(()=>{getLeaveShort();},[]);
    const [leaveList,setleaveList]= useState([]);
    const getLeaveShort = () => {
        Apiconnect.getData(`leave_short/getall/${emp}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setleaveList(_xtract.reverse());      
         });
      }


    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">{id ? 'Short Leave Apply' : 'Short Leave Apply'}</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <form onSubmit={e => onSubmit(e)}>

                                    <div className="col-sm-12 ">
                                        <div className="form-group">

                                            <div className="col-sm-6">
                                                <label>Date*</label>
                                                <DatePicker
                                                    selected={fromDate}
                                                    onChange={e => setfromDate(e)}
                                                    className="form-control"
                                                    dateFormat='yyyy-MM-dd'
                                                    placeholderText="From Date"
                                                    name="from_dt"
                                                    required="required"
                                                />
                                            </div>
                                        </div>
                                        </div>

                                        <div className="col-sm-12 ">
                                            <div className="form-group" style={{ display: 'flex' }}>
                                                <div className="col-sm-3">
                                                    <label>Start Time*</label>
                                                    <input type="time" required className="form-control validate[required]" name="start_time"
                                                        value={start_time}
                                                        onChange={e => onInputChange(e)} />
                                                </div>

                                                <div className="col-sm-3">
                                                    <label>End Time*</label>
                                                    <input type="time" required className="form-control validate[required]" name="end_time"
                                                        value={end_time}
                                                        onChange={e => onInputChange(e)} />
                                                </div>
                                            </div>

                                            <div className="form-group">

                                                <div className="col-sm-6">
                                                    <label>Reason*</label>
                                                    <textarea className="form-control" required required name="reason" value={reason} onChange={e => onInputChange(e)} required=""></textarea>
                                                </div>
                                            </div>

                                            <div className="col-md-12 mt-2">
                                                <button type="submit" name="id" value='' className="btn btn-primary mr-2"> Submit </button>
                                            </div>
                                        </div>
                                    </form>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" >
                     <div className="col-md-12">
                        <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List of leaves Short</h4>
                           </div>
                        </div>
                     <div className="iq-card-body padLRTzero">
                    
                        <div className="table-responsive">
                           
                           <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                             <thead>
                                 <tr className="clr">
                                    <th>#</th>
                                    <th>Applied On</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Reason</th>
                                 </tr>
                             </thead>
                             <tbody>
                              {
                                  leaveList.map((val,key)=>{
                                      return(
                                        <tr className="" key={key}>
                                        <th>{key+1}</th>
                                        <th>{val.date}</th>
                                        <th>{val.start_time}</th>
                                        <th>{val.end_time}</th>
                                        <th>{val.reason}</th>
                                     </tr>
                                      )
                                  })
                              }
                           
                             </tbody>
                           </table>
                        </div>
                       
                       
                       

                     </div>
                  </div>
                  </div>
                </div>


                </div>
            </div>
        </>
    )
}

export default Short_leaves