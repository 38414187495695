import React from 'react';
import { useHistory } from "react-router-dom";
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom'; 
import routes from '../routes';
  
 
const local_user = JSON.parse(localStorage.getItem('user'));
let emp_typ = '';
if(local_user){
  emp_typ = local_user.emp_typ;
}
var str = window.location.href;
str = str.split("/");


////console.log(str[3]);



const TheContent = () => {

  let history = useHistory();


  /*
  if(emp_typ==1 && str[3]=='ems'){
    history.push("/dashboard/");            
  }

  if(emp_typ==2 && str[3]!='ems'){
    history.push("/ems/dashboard/");            
  }
*/



  return (
    <>
       
         
          <Switch>
            {routes.map((route, idx) => {
              return route.component && (
                <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (                        
                        <route.component {...props} />                        
                    )} />
              )
            })}
            <Redirect from="/" to="/dashboard" />
          </Switch>
        
       
    </>
  )
}

export default React.memo(TheContent)
