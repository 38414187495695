import React from 'react';

import Dashboard from './ems_component/pages/Dashboard';
import Dashboard2 from './ems_component/pages/Dashboard2';
import LeaveApply from './ems_component/pages/LeaveApply';
import Short_leaves from './ems_component/pages/Short_leaves';
import Daily_attendance from './ems_component/pages/Daily_attendance';
import Previous_attendance from './ems_component/pages/Previous_attendance';
import Reimbursement from './ems_component/pages/Reimbursement';
import Conveyance from './ems_component/pages/Conveyance';
import Leave_section from './ems_component/pages/Leave_section';
import Reject_attendance from './ems_component/pages/Reject_attendance';
import Team_member_attendance from './ems_component/pages/Team_member_attendance';
import Attendance_master_report from './ems_component/pages/Attendance_master_report';
import Manual_attendance_master from './ems_component/pages/Manual_attendance_master';
import Team_member_leaves from './ems_component/pages/Team_member_leaves';
import Team_member_short_leaves from './ems_component/pages/Team_member_short_leaves';
import Yearly_holidays from './ems_component/pages/Yearly_holidays';
import Salary_breakup from './ems_component/pages/Salary_breakup';
import Salary_slip_view from './ems_component/pages/Salary_slip_view';
import Salary_slip from './ems_component/pages/Salary_slip';
import EditProfile from './ems_component/pages/EditProfile';
import ResetPassword from './ems_component/pages/ResetPassword';
import Manual_attendance from './ems_component/pages/Manual_attendance';
import Employee_image_upload from './ems_component/pages/Employee_image_upload';
import My_team from './ems_component/pages/My_team';


import Profile from './ems_component/pages/Profile';

const routes = [
  { path: '/ems/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/ems/dashboard2', name: 'Dashboard', component: Dashboard2 },
  { path: '/ems/leave', name: 'Dashboard', component: LeaveApply },
  { path: '/ems/short_leave', name: 'Dashboard', component: Short_leaves },

  { path: '/ems/daily_attendance', name: 'Dashboard', component: Daily_attendance },
  { path: '/ems/previous_attendance', name: 'Dashboard', component: Previous_attendance },
  { path: '/ems/reimbursement', name: 'Dashboard', component: Reimbursement },
  { path: '/ems/conveyance', name: 'Dashboard', component: Conveyance },

  { path: '/ems/leave_section', name: 'Dashboard', component: Leave_section },
  { path: '/ems/reject_attendance', name: 'Dashboard', component: Reject_attendance },
  { path: '/ems/team_member_attendance', name: 'Dashboard', component: Team_member_attendance },
  { path: '/ems/attendance_master_report', name: 'Dashboard', component: Attendance_master_report },
{ path: '/ems/manual_attendance_master', name: 'Dashboard', component: Manual_attendance_master },

  { path: '/ems/team_member_short_leaves', name: 'Dashboard', component: Team_member_short_leaves },
  { path: '/ems/team_member_leaves', name: 'Dashboard', component: Team_member_leaves },
  { path: '/ems/yearly_holidays', name: 'Dashboard', component: Yearly_holidays },
  { path: '/ems/salary_breakup', name: 'Dashboard', component: Salary_breakup },
  { path: '/ems/salary_slip', name: 'Dashboard', component: Salary_slip },
  { path: '/ems/salary_slip_view/:id', name: 'Dashboard', component: Salary_slip_view },

  { path: '/ems/editProfile', name: 'Dashboard', component: EditProfile },
  { path: '/ems/resetPassword', name: 'Dashboard', component: ResetPassword },

  { path: '/ems/profile', name: 'Dashboard', component: Profile },
  { path: '/ems/manual_attendance', name: 'Dashboard', component: Manual_attendance },

  { path: '/ems/employee_upload_image', name: 'upload', component: Employee_image_upload },
  { path: '/ems/my_team', name: 'upload', component: My_team },
];

export default routes;
